import React from 'react';
import { css } from 'emotion';
import { effect } from 'helpers';

export default ({ color, h, w, b, shadow, className }) => {
  return (
    <>
      <div
        className={css`
          background-color: ${color};
          height: ${h};
          width: ${w};
          border-radius: ${b};
          ${shadow ? effect.boxShadowSm : null}
          ${className}
        `}
      ></div>
    </>
  );
};
