import React, { useEffect, useState, useCallback } from 'react';
import { css } from 'emotion';
import { Button, Form, TextInput, FormBox } from 'components';
import { media } from 'helpers';
export default ({
  handleVerify,
  verificationCode,
  setVerificationCode,
  handleResendCode,
  loading
}) => {
  const [resend, setResend] = useState(false);

  const resetResend = useCallback(() => {
    setTimeout(() => {
      setResend(true);
    }, 10000);
  }, []);

  useEffect(resetResend, [resetResend]);

  return (
    <Form
      handleSubmit={e => {
        handleVerify(e);
      }}
      className={css`
        height: 100%;
      `}
    >
      <FormBox>
        <TextInput
          name="Verification"
          value={verificationCode}
          onChange={e => {
            setVerificationCode(e.target.value);
          }}
          placeholder="Verification Code"
          required={true}
        />
      </FormBox>
      <Button
        boxShadow={true}
        buttonType="modal"
        onClick={e => {
          setResend(false);
          resetResend();
          handleResendCode(e);
        }}
        disabled={!resend || loading}
      >
        Resend Code
      </Button>
      <Button
        boxShadow={true}
        buttonType="modal"
        submit={true}
        disabled={!verificationCode || loading}
        className={css`
          margin-top: 10px;
          @media (max-width: ${media.screen.md}) {
            margin-top: 10px;
          }
        `}
      >
        Continue
      </Button>
    </Form>
  );
};
