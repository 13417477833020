import React from 'react';
import { css } from 'emotion';
import { colors, effect, fonts, media } from 'helpers';
import {
  GoChevronUp as ChevronUpIcon,
  GoChevronDown as ChevronDownIcon
} from 'react-icons/go';

export default function({
  text,
  field,
  sortByFilters,
  handleClick,
  className
}) {
  return (
    <div
      type="button"
      className={css`
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        background-color: ${colors.white};
        font-size: ${fonts.size.xs};
        font-family: ${fonts.fontFamily.secondary};
        border-radius: 12px;
        ${effect.boxShadowSm};
        box-shadow: 0px 3px 6px ${colors.black};
        padding: 0 15px;
        margin: 15px 0;
        color: ${colors.black};
        @media (max-width: ${media.screen.sm}) {
          height: 32px;
          padding: 0 8px;
          margin: 8px 0;
          font-size: ${fonts.size.xxs};
        }
        ${className};
      `}
    >
      <span
        className={css`
          margin-right: 6px;
          width: 100%;
        `}
      >
        {text}
      </span>
      <div
        className={css`
          display: flex;
        `}
      >
        <button
          className={css`
            appearance: none;
            border-radius: 12px;
            border: none;
            margin-right: 8px;

            background-color: ${sortByFilters.sortBy === field &&
            sortByFilters.sortDirection === 'asc'
              ? colors.yellow
              : colors.white};
            ${effect.boxShadowSm};
            &:hover {
              cursor: pointer;
            }
          `}
          type="button"
          onClick={() => handleClick(field, 'asc')}
        >
          <ChevronUpIcon
            size={25}
            className={css`
              @media (max-width: ${media.screen.sm}) {
                width: 18pm;
                height: 18px;
              }
            `}
          />
        </button>
        <button
          className={css`
            appearance: none;
            border-radius: 12px;
            border: none;
            background-color: ${sortByFilters.sortBy === field &&
            sortByFilters.sortDirection === 'desc'
              ? colors.yellow
              : colors.white};
            ${effect.boxShadowSm};
            &:hover {
              cursor: pointer;
            }
          `}
          type="button"
          onClick={() => handleClick(field, 'desc')}
        >
          <ChevronDownIcon
            size={25}
            className={css`
              @media (max-width: ${media.screen.sm}) {
                width: 18pm;
                height: 18px;
              }
            `}
          />
        </button>
      </div>
    </div>
  );
}
