import React, { useContext, useEffect, useState } from 'react';
import { ToastContext } from 'context/Toast.context';
import moment from 'moment';
import { Button, Link } from 'components';
import { addPledge, deletePledge, getPledges } from 'api/pledges.api';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const { userId } = props;
  const [pledges, setPledges] = useState([]);

  useEffect(() => {
    async function async() {
      try {
        const res = await getPledges({ userId: userId });
        setPledges(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage, userId]);

  async function remove(id) {
    const message =
      'Are you sure you want to delete this pledge? This action cannot be reversed.';
    if (!window.confirm(message)) return;

    try {
      await deletePledge({ id });
      setPledges(pledges => pledges.filter(p => p.id !== id));
      pushMessage.success('Pledge deleted.');
    } catch (e) {
      pushMessage.error(e.message);
    }
  }

  async function add() {
    try {
      const res = await addPledge({ userId });
      setPledges(pledges => pledges.concat(res));

      pushMessage.success('Pledge created.');
    } catch (e) {
      pushMessage.error(e.message);
    }
  }

  return (
    <div>
      <h2>Tokens:</h2>
      <Button onClick={add}>Add a Pledge</Button>
      {pledges.map(pledge => (
        <div
          key={pledge.id}
          style={{ border: 'solid 1px black', margin: '10px 0', padding: 5 }}
        >
          Item:{' '}
          {pledge.pledgeDriveId ? (
            <Link to={`/admin/pledge-drives/${pledge.pledgeDriveId}`}>
              {pledge.pledgeDriveName}
            </Link>
          ) : (
            'Not Assigned'
          )}
          <br />
          Created: {moment(pledge.createdAt).format('LL')}
          <br />
          Stripe ID: {pledge.stripePaymentId ? pledge.stripePaymentId : 'N/A'}
          <br />
          <Link
            onClick={() => {
              remove(pledge.id);
            }}
          >
            Delete
          </Link>
        </div>
      ))}
    </div>
  );
};
