import React, { useContext, useState } from 'react';
import { UserContext } from 'context/User.context';
import { css } from 'emotion';
import { LoginModal, LogoutModal, SignUpModal } from 'modals';
import { colors, effect, zIndex } from 'helpers';

import NavShortcut from './NavShortcut.component';
import NavList from './NavList.component';
import NavHamburger from './NavHamburger.component';

import CompletedDrivesIcon from 'assets/Icons/completed_drives.svg';
import PledgeDrivesIcon from 'assets/Icons/pledge_drives.svg';
import ProfileIcon from 'assets/Icons/profile.svg';

export default ({ hideNav = false }) => {
  const [navState, setNavState] = useState(1);
  const { user } = useContext(UserContext);
  const [loginModal, setLoginModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  function closeAllModals(callback) {
    setLoginModal(false);
    setSignUpModal(false);
    setLogoutModal(false);
    if (callback) callback();
  }

  function handleHamburger(newState) {
    setNavState(newState);
  }

  return (
    <>
      <div
        className={css`
          height: ${navState ? '65px' : '100%'};
          z-index: ${zIndex.layer13.nav};
          position: fixed;
          bottom: ${navState ? '12px' : '0'};
          left: ${navState ? '2.5%' : '0'};
          border-radius: ${navState ? '12px' : '0'};
          z-index: ${zIndex.layer13.nav};
          display: ${Boolean(
            hideNav || loginModal || signUpModal || logoutModal
          )
            ? 'none'
            : 'flex'};
          width: ${navState ? '95%' : '100%'};
          background-color: ${colors.yellow};
          transition: 0.4s;
          justify-content: space-between;
          ${effect.boxShadowSm}
        `}
      >
        {/* button section */}
        <div
          className={css`
            transition: 0.2s;
            bottom: ${navState ? (user ? '8px' : '5px') : '30px'};
            display: flex;
            justify-content: ${user ? 'space-between' : 'flex-start'};
          `}
        >
          {user ? (
            <>
              <NavShortcut
                linkUrl="my-account"
                text="Profile"
                icon={ProfileIcon}
              />
              <NavShortcut
                linkUrl="completed-drives"
                text="Completed Drives"
                icon={CompletedDrivesIcon}
              />
              <NavShortcut
                linkUrl="pledge-drives"
                text="Pledge Drives"
                icon={PledgeDrivesIcon}
              />
            </>
          ) : (
            <>
              <NavShortcut
                text="Login"
                clickFunction={() => {
                  handleHamburger(true);
                  closeAllModals(() => setLoginModal(true));
                }}
              />

              <NavShortcut
                text="Sign Up"
                clickFunction={() => {
                  handleHamburger(true);
                  closeAllModals(() => setSignUpModal(true));
                }}
              />
            </>
          )}

          {/* Hamburger */}

          <NavList navState={navState} />
        </div>
      </div>
      <NavHamburger
        navState={navState}
        clickFunction={() => {
          closeAllModals();
          handleHamburger(!navState);
        }}
        className={css`
          display: ${Boolean(
            hideNav || loginModal || signUpModal || logoutModal
          )
            ? 'none'
            : 'flex'};
        `}
      />
      <LoginModal isOpen={loginModal} close={() => setLoginModal(false)} />
      <SignUpModal isOpen={signUpModal} close={() => setSignUpModal(false)} />
      <LogoutModal isOpen={logoutModal} close={() => setLogoutModal(false)} />
    </>
  );
};
