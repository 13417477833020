import React, { useContext } from 'react';

import { UserContext } from 'context/User.context';

import Guest from './components/Guest.component';
import { Redirect } from 'react-router-dom';

export default () => {
  const { user } = useContext(UserContext);

  return user ? <Redirect to="/pledge-drives" /> : <Guest />;
};
