import React from 'react';
import { css } from 'emotion';
import { colors } from 'helpers';

export default ({ marginBottom, options = [], ...props }) => {
  return (
    <select
      {...props}
      className={css`
        ${props.className};
        ${marginBottom && `margin-bottom: ${marginBottom}px`};
        width: 100%;
        border: none;
        background-color: ${colors.grey[85]};
        outline: none;
        padding: 10px;
      `}
    >
      <option disabled hidden></option>
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          className={css`
            width: 50px;
          `}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};
