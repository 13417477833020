import React from 'react';
import { Button, H, P } from 'components';
export default ({ next, loading }) => {
  return (
    <>
      <H level="2" fontFamily="secondary">
        Ready to Subscribe?
      </H>
      <br />
      <br />
      <P>-- $5 /Month Subscription required to place pledges</P>
      <br />
      <br />
      <P>-- 3 Pledges a month</P> <br />
      <br />
      <P>-- Left over pledges are randomly-assigned at the end of the month.</P>
      <Button
        boxShadow={true}
        buttonType="modal"
        onClick={next}
        disabled={loading}
      >
        Let's get started!
      </Button>
    </>
  );
};
