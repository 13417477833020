import React from 'react';
import { Modal, Button, Animation, P } from 'components';
import { css } from 'emotion';
import ClimbMountain from 'assets/Lottie/ClimbMountain.json';
export default ({ isOpen, close }) => {
  return (
    <Modal isOpen={isOpen} close={close}>
      <Modal.Header>Subscribe</Modal.Header>
      <Modal.Content
        className={css`
          overflow: hidden;
        `}
      >
        {/* Body */}
        <div
          className={css`
            margin-top: 16px;
            margin-bottom: 28px;
          `}
        >
          <P size="sm">
            For Premium features such as search and sort, the charity directory,
            store and automatic pledge renewal…
          </P>
          <br />
          <P size="sm">
            Subscribe to PledgeLlama for $5.99 a month to help charities and win
            prizes.
          </P>
        </div>
        {/* Button and Image */}
        <div
          className={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          `}
        >
          <div
            className={css`
              flex: 2 2 0;
              margin-right: 34px;
            `}
          >
            <Button
              boxShadow={true}
              link={'/subscribe'}
              className={css`
                width: 100%;
                max-height: 42px;
                margin-right: 34px;
              `}
            >
              Subscribe
            </Button>
          </div>
          <div
            className={css`
              flex: 1 1 0;
            `}
          >
            <Animation
              lottieFile={ClimbMountain}
              className={css`
                transform: scale(1.5);
                @media (max-width: 416px) {
                  transform: scale(2);
                }
              `}
            />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
