function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatPhone(string) {
  var cleaned = ('' + string).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

function formatMoney(money) {
  let string = '$' + (Math.ceil(money * 100) / 100).toLocaleString();
  return !string.includes('.')
    ? string
    : string.split('.')[1].length === 1
    ? string + '0'
    : string;
}

function toTitleCase(string) {
  return string.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default {
  capitalize,
  formatPhone,
  formatMoney,
  toTitleCase
};
