import React, { useContext, useEffect, useState } from 'react';
import { css } from 'emotion';
import { numberFormatter } from 'helpers';
import { ToastContext } from 'context/Toast.context';
import { Button, Link } from 'components';
import { getPledgeDrives } from 'api/pledgeDrives.api';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const [pledgeDrives, setPledgeDrives] = useState([]);

  useEffect(() => {
    async function async() {
      try {
        const res = await getPledgeDrives({
          sortBy: 'name',
          withArchived: true
        });
        setPledgeDrives(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage]);

  return (
    <div>
      <Button
        boxShadow={true}
        link="/admin/pledge-drives/new"
        className={css`
          margin: 12px 0;
        `}
      >
        Create Pledge Drive
      </Button>
      {pledgeDrives.map(pledgedrive => (
        <div key={pledgedrive.id}>
          <Link to={`/admin/pledge-drives/${pledgedrive.id}`}>
            <h2>{pledgedrive.name}</h2>
          </Link>

          <p>
            Description: {pledgeDrives.description}
            <br />
            Retail:{' '}
            {numberFormatter.currencyFormatter.format(pledgedrive.retailValue)}
            <br />
            Charity: {pledgedrive.charityName}
            <br />
            Winner: {pledgedrive.winnerName}
            <br />
            Progress: {Math.floor(pledgedrive.progress * 100)}%<br />
          </p>
        </div>
      ))}
    </div>
  );
};
