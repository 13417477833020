import React, { useContext, useState, useEffect } from 'react';
import { css } from 'emotion';
import { zIndex } from 'helpers';
import { P, Animation, TextBubble } from 'components';
import Logo from 'assets/Lottie/Logo.json';
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';
import { PledgesContext } from 'context/Pledges.context';

export default () => {
  const { user } = useContext(UserContext);
  const { pledges } = useContext(PledgesContext);

  const { pushMessage } = useContext(ToastContext);

  const [pledgesAvailable, setPledgesAvailable] = useState(0);

  // get user pledge data
  useEffect(() => {
    async function async() {
      try {
        if (pledges?.length > 0) {
          setPledgesAvailable(pledges.filter(t => !t.pledgeDriveId).length);
        }
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage, user, pledges]);

  return (
    <div
      className={css`
        position: fixed;
        z-index: ${zIndex.layer12.showPledgeCount};
        bottom: 80px;
        left: 5px;
      `}
    >
      {/* wrapper */}
      <div
        className={css`
          display: flex;
          width: 170px;
        `}
      >
        <Animation lottieFile={Logo} className={css``} />
        {/* text bubble */}
        <TextBubble
          className={css`
            margin-top: 25px;
            animation: fadeIn 2s;
            @keyframes fadeIn {
              0% {
                opacity: 0;
              }
              60% {
                opacity: 0;
                transform: rotate(20deg) translateX(-25px) translateY(40px);
              }
              100% {
                opacity: 1;
                transform: rotate(0) translateX(0) translateY(0);
              }
            }
          `}
        >
          <P
            size="xxs"
            className={css`
              margin-top: -2px;
              margin-right: 15px;
              display: inline;
            `}
          >
            You have{' '}
            <strong>
              <u>{pledgesAvailable} </u>
            </strong>{' '}
            Pledges available
          </P>
        </TextBubble>
      </div>
    </div>
  );
};
