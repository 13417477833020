import React, { useEffect, useState } from 'react';
import { css } from 'emotion';
import ReactPixel from 'react-facebook-pixel';

import { Button, P } from 'components';
import { colors, zIndex } from 'helpers';

export default ({ children }) => {
  const [prompt, setPrompt] = useState(false);

  useEffect(() => {
    if (!window.localStorage.getItem('CookiesAccepted')) {
      window.localStorage.setItem('CookiesAccepted', '');
      setPrompt(true);
    }
    if (window.localStorage.getItem('CookiesAccepted') === 'Accepted') {
      const advancedMatching = null;
      const pixelOptions = { autoConfig: true, debug: false };
      ReactPixel.init(process.env.FB_PIXEL, advancedMatching, pixelOptions);
      ReactPixel.grantConsent();
      ReactPixel.pageView();
    }
  }, []);

  function AcceptCookies() {
    const advancedMatching = null;
    const pixelOptions = { autoConfig: true, debug: false };
    ReactPixel.init(process.env.FB_PIXEL, advancedMatching, pixelOptions);
    ReactPixel.grantConsent();
    ReactPixel.pageView();

    setPrompt(false);
    window.localStorage.setItem('CookiesAccepted', 'Accepted');
  }

  function DeclineCookies() {
    setPrompt(false);
    window.localStorage.setItem('CookiesAccepted', 'Declined');
  }

  return (
    <div>
      {children}
      <div
        className={css`
          position: fixed;
          bottom: 0;
          z-index: ${zIndex.layer101.acceptCookies};
          padding: 12px;
          width: 100%;
          background-color: ${colors.white};
          display: ${prompt ? 'flex' : 'none'};
          align-items: center;
          justify-content: space-between;

          border-top: solid ${colors.black} 3px;
          button {
            margin-left: 12px;
          }
        `}
      >
        <P size="xs" weight="black">
          Accept Cookies to help us better serve you, <br /> and the charities
          we support.
        </P>
        <div
          className={css`
            display: flex;
          `}
        >
          <Button
            onClick={() => {
              AcceptCookies();
            }}
          >
            {' '}
            Accept{' '}
          </Button>
          <Button
            onClick={() => {
              DeclineCookies();
            }}
          >
            {' '}
            Decline{' '}
          </Button>
        </div>
      </div>
    </div>
  );
};
