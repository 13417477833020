import React from 'react';
import { css } from 'emotion';
import { H } from 'components';
import { colors, media } from 'helpers';

export default ({ alignText = 'center', backButton, children, className }) => {
  return (
    <div
      className={css`
        width: 100%;
        text-align: center;
        padding: ${alignText === 'left' ? '10px 24px 10px 0' : '20px 24px'};
        background-color: ${colors.purple};
        @media (max-width: ${media.screen.sm}) {
          padding: ${alignText === 'left' ? '5px 12px 5px 0' : '10px 12px'};
        }
        ${className};
      `}
    >
      {backButton ? (
        children
      ) : (
        <H
          level="2"
          color="yellow"
          fontFamily="title"
          textShadow={true}
          className={css`
            font-size: 28px;
            text-align: ${alignText};
            white-space: nowrap;
          `}
        >
          {children}
        </H>
      )}
    </div>
  );
};
