import React, { useContext, useState, useEffect } from 'react';
import { Nav, BackButton, PledgesAvailable, IOSInstall } from 'components';

import { css } from 'emotion';
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';
import { useLocation } from 'react-router-dom';
import { SignUpModal, SubscribeReminderModal, YouWonModal } from 'modals';
import { getPledgeDrives } from 'api/pledgeDrives.api';

export default ({
  showPledgesAvailable = false,
  backTitle,
  nav = true,
  footer = false,
  back = true,
  ...props
}) => {
  const { user } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const { pathname } = useLocation();

  const [reminderModal, setReminderModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [youWonModal, setYouWonModal] = useState(false);

  const [completedDrives, setCompletedDrives] = useState([]);

  //check if pledge drive is won
  useEffect(() => {
    if (!pathname.includes('pledge-drives')) {
      return;
    }
    async function fetchPledgeDrives() {
      try {
        const res = await getPledgeDrives({ active: false, userId: user.id });

        const completedDrives = res.filter(
          drive =>
            drive.winnerId !== null &&
            drive.winnerId === user.id &&
            drive.itemAcceptedAt === null
        );

        if (Array.isArray(completedDrives)) {
          setCompletedDrives(completedDrives);
        }
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    //fetch pledge drives
    if (user) fetchPledgeDrives();
  }, [pushMessage, user, pathname]);

  /* Open modals */
  useEffect(() => {
    if (
      user &&
      user.isUnsubscribed &&
      !pathname.includes('account') &&
      !pathname.includes('subscribe')
    ) {
      setReminderModal(true);
    }

    if (completedDrives.length > 0) {
      setYouWonModal(true);
    }

    if (
      user &&
      !user.isUnsubscribed &&
      Boolean(!user.phoneVerified || !user.subscriptionActive)
    ) {
      setSignUpModal(true);
    }
  }, [user, completedDrives, pathname]);

  return (
    <div
      className={css`
        margin-bottom: ${showPledgesAvailable ? '205px' : nav ? '80px' : 0};
        ${props.className}
      `}
    >
      {user ? <IOSInstall /> : null}

      <SubscribeReminderModal
        isOpen={reminderModal}
        close={() => setReminderModal(false)}
      />

      <YouWonModal
        isOpen={youWonModal}
        close={() => setYouWonModal(false)}
        pledgeDrive={completedDrives?.length > 0 && completedDrives[0]}
      />

      <SignUpModal
        startAt={!user?.phoneVerified ? 2 : 4}
        isOpen={signUpModal}
        close={() => setSignUpModal(false)}
      />

      <div
        className={css`
          max-width: 100%;
          width: 100vw;
          margin: auto;
        `}
      >
        {!back ? null : <BackButton backTitle={backTitle} />}
        {props.children}
        {/* pledge count */}
        {showPledgesAvailable ? <PledgesAvailable /> : null}
        {nav ? (
          <Nav hideNav={Boolean(reminderModal || signUpModal || youWonModal)} />
        ) : null}
      </div>
    </div>
  );
};
