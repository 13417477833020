import React, { useContext } from 'react';
import { Button, ModalInfo, H } from 'components';
import { css } from 'emotion';

import { FaShareSquare } from 'react-icons/fa';
import { ToastContext } from 'context/Toast.context';

export default ({ link, isOpen, close }) => {
  const { pushMessage } = useContext(ToastContext);

  function handleShare() {
    navigator.clipboard.writeText(link);
    pushMessage.success('Copied to clipboard!');
  }
  return (
    <ModalInfo align="center" isOpen={isOpen} close={close}>
      <H level="2" color="white" size="sm">
        Copy to Clipboard
      </H>
      <div
        className={css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 10px;
        `}
      >
        <div
          onClick={handleShare}
          className={css`
            margin-right: 8px;
            height: 35px;
            width: 100%;
            background-color: lightgrey;
            padding: 8px;
            text-align: center;
            border-radius: 12px;
            white-space: nowrap;
            overflow-x: scroll;
          `}
        >
          {link}
        </div>
        <Button size="sm" onClick={handleShare}>
          <FaShareSquare />
        </Button>
      </div>
    </ModalInfo>
  );
};
