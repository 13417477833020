import http from 'helpers/http.helper';

export const getCharities = ({
  search,
  charityCategoryIds,
  resultsPerPage,
  withArchived
} = {}) => {
  return http().get('/charities', {
    params: {
      search,
      charityCategoryIds,
      resultsPerPage: resultsPerPage > 2 ? resultsPerPage : 2,
      withArchived
    }
  });
};

export const getCharity = ({ charityId }) => {
  return http().get(`/charities/${charityId}`);
};

export const createCharity = ({ charityInfo }) => {
  return http().post('/charities', charityInfo);
};

export const updateCharity = ({ charityId, charityInfo }) => {
  return http().put(`/charities/${charityId}`, charityInfo);
};

export const updateLogo = ({ charityId, data }) => {
  return http().put(`/charities/${charityId}/logo`, data);
};

export const updateCover = ({ charityId, data }) => {
  return http().put(`/charities/${charityId}/cover`, data);
};
