import React from 'react';
import { css } from 'emotion';
import { H, P } from 'components';

import { media } from 'helpers';
export default () => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: -5px;
      `}
    >
      <H
        fontFamily="title"
        weight="heavy"
        className={css`
          font-size: 46px;
          margin-top: 40px;
          text-align: center;
          @media ${media.layout.landscapeDesktop} {
            margin-top: 100px;
            font-size: 60px;
          }
        `}
      >
        Support Charities!
      </H>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 90%;
          max-width: 600px;
        `}
      >
        <P
          size="xs"
          fontFamily="primary"
          className={css`
            margin-top: 25px;
            text-align: center;
          `}
        >
          Subscribe for $5.99 a month to place pledges on pledge drives from
          your favorite charities.
          <br />
          <br />
          Making the world a better place just got easier, and even more fun
          <span role="img" aria-label="coolface">
            {' '}
            😎
          </span>
        </P>
      </div>
      <H
        fontFamily="title"
        weight="heavy"
        className={css`
          font-size: 46px;
          margin-top: 40px;
        `}
      >
        Win Big!
      </H>
    </div>
  );
};
