export default {
  size: {
    xl: '38px',
    lg: '30px',
    md: '21px',
    sm: '16px',
    xs: '14px',
    xxs: '12px'
  },
  fontFamily: {
    primary: 'Proxima Nova, Arial, sans-serif',
    secondary: 'Neuzeit Grotesk, Arial, sans-serif',
    title: 'Crafting Lesson, Arial, sans-serif'
  },
  fontWeight: {
    thin: 200,
    normal: 400,
    bold: 600,
    black: 800
  },
  lineHeight: {
    md: '1em'
  }
};
