import React, { useState, useCallback, useContext } from 'react';
import { css } from 'emotion';
import { Button, Form, FormBox, InputLabel } from 'components';
import { setSubscriptionActive } from 'api/users.api';
import { colors, media } from 'helpers';
import { UserContext } from 'context/User.context';

const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);

const elementOptions = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: 'Roboto, sans-serif'
    }
  }
};

const elements = stripe.elements({
  fonts: [
    { cssSrc: 'https://fonts.googleapis.com/css?family=Roboto&display=swap' }
  ]
});

const cardNumberElement = elements.create('cardNumber', elementOptions);
const cardExpiryElement = elements.create('cardExpiry', elementOptions);
const cardCvcElement = elements.create('cardCvc', elementOptions);

export default ({ setUserInfo, setError, next }) => {
  const [zip, setZip] = useState('');
  const [loading, setLoading] = useState(false);
  const fieldWrapperStyles = css`
    background-color: ${colors.grey[85]};
    padding: 10px;
    margin-bottom: 8px;
    outline: none;
    border: none;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  `;

  const updatePaymentInfo = async e => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const stripeResult = await stripe.createToken(cardNumberElement, {
        address_zip: zip,
        address_country: 'US'
      });

      if (stripeResult.error) throw stripeResult.error;

      const updatedUser = await setSubscriptionActive(
        true,
        stripeResult.token.id
      );

      setUserInfo(updatedUser);
      next();
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const cardNumberRef = useCallback(node => {
    if (!node) return;
    cardNumberElement.mount(node);
  }, []);

  const cardExpiryRef = useCallback(node => {
    if (!node) return;
    cardExpiryElement.mount(node);
  }, []);

  const cardCvcRef = useCallback(node => {
    if (!node) return;
    cardCvcElement.mount(node);
  }, []);

  const { setUser } = useContext(UserContext);

  const logOutFunction = () => {
    setUser(null);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('IOSInstall');
  };

  return (
    <Form
      handleSubmit={updatePaymentInfo}
      className={css`
        height: 100%;
      `}
    >
      <FormBox>
        <InputLabel>Card Number</InputLabel>
        <div ref={cardNumberRef} className={fieldWrapperStyles} />
        <div
          className={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            className={css`
              width: calc(50% - 5px);
            `}
          >
            <InputLabel>Expiration</InputLabel>
            <div ref={cardExpiryRef} className={fieldWrapperStyles} />
          </div>
          <div
            className={css`
              width: calc(50% - 5px);
            `}
          >
            <InputLabel>CVC</InputLabel>
            <div ref={cardCvcRef} className={fieldWrapperStyles} />
          </div>
        </div>

        <InputLabel>Zip Code</InputLabel>
        <input
          className={fieldWrapperStyles}
          name="zip"
          type="text"
          value={zip}
          onChange={e => setZip(e.target.value)}
          placeholder="Zip"
          required={true}
          maxLength="5"
        />
      </FormBox>
      <Button
        boxShadow={true}
        buttonType="modal"
        submit={true}
        disabled={loading}
      >
        Subscribe
      </Button>
      <Button
        buttonType="modal"
        onClick={logOutFunction}
        boxShadow={true}
        className={css`
          margin-top: 10px;
          margin-bottom: 10px;
          @media (max-width: ${media.screen.md}) {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        `}
      >
        Log Out
      </Button>
    </Form>
  );
};
