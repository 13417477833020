import React, { useContext } from 'react';
import { css } from 'emotion';
import { fonts, media } from 'helpers';
import { ModalInfo } from 'components';
import { UserContext } from 'context/User.context';

import { Button } from 'components';

export default ({ isOpen, close }) => {
  const { setUser } = useContext(UserContext);

  const logOutFunction = () => {
    setUser(null);
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('IOSInstall');
  };
  return (
    <ModalInfo isOpen={isOpen} close={close}>
      <h2
        className={css`
          color: white;
          text-align: center;
          font-family: ${fonts.fontFamily.secondary};
          font-weight: ${fonts.fontWeight.bold};
          padding-top: 20px;
          margin: auto;
        `}
      >
        Are you sure you want to log out?
      </h2>
      <Button
        buttonType="modal"
        onClick={logOutFunction}
        boxShadow={true}
        className={css`
          margin-bottom: 10px;

          @media (max-width: ${media.screen.md}) {
            margin-bottom: 10px;
          }
        `}
      >
        Log Out
      </Button>
      <Button
        buttonType="modal"
        onClick={close}
        boxShadow={true}
        className={css`
          margin-top: 10px;
          margin-bottom: 10px;
          @media (max-width: ${media.screen.md}) {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        `}
      >
        Close
      </Button>
    </ModalInfo>
  );
};
