import React, { useState, useMemo } from 'react';
import { css } from 'emotion';
import { colors } from 'helpers';
import { MdClose } from 'react-icons/md';
import { zIndex } from 'helpers';

export const ToastContext = React.createContext();

export default props => {
  const [messages, setMessages] = useState([]);

  const pushMessage = useMemo(() => {
    const messagePusher = error => data => {
      const message = { id: Date.now(), data, error };
      setMessages(messages => messages.concat(message));
      setTimeout(() => removeMessage(message.id), 8000);
    };
    return {
      error: messagePusher(true),
      success: messagePusher(false)
    };
  }, []);

  const removeMessage = id => {
    setMessages(messages => messages.filter(message => message.id !== id));
  };

  return (
    <ToastContext.Provider value={{ pushMessage }}>
      {props.children}
      <div
        className={css`
          position: fixed;
          bottom: 0;
          right: 0;
          left: 0;
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          padding: 0 5px;
          pointer-events: none;
          z-index: ${zIndex.layer103.toastMessage};
        `}
      >
        {messages.map(message => (
          <div
            key={message.id}
            className={css`
              background-color: ${message.error
                ? colors.redErrorLight
                : colors.greenSuccessLight};
              border-top: solid 3px
                ${message.error ? colors.redError : colors.greenSuccess};
              width: 100%;
              max-width: 500px;
              box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 1px 5px 0px rgba(0, 0, 0, 0.12);
              padding: 15px 10px;
              margin-bottom: 5px;
              position: relative;
              pointer-events: auto;
            `}
          >
            {message.data}
            <MdClose
              onClick={() => removeMessage(message.id)}
              className={css`
                color: ${colors.grey[50]};
                cursor: pointer;
                position: absolute;
                top: 5px;
                right: 5px;
              `}
            />
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};
