import http from 'helpers/http.helper';

export const getPledges = ({ userId } = {}) => {
  return http().get('/pledges', { params: { userId } });
};

export const placePledge = ({ nextAvailablePledge, pledgeDriveId }) => {
  return http().put(`/pledges/${nextAvailablePledge}`, { pledgeDriveId });
};

export const addPledge = ({ userId }) => {
  return http().post('/pledges', { userId: userId });
};

export const deletePledge = ({ id }) => {
  return http().delete(`/pledges/${id}`);
};

export const getPledgeStats = () => {
  return http().get(`/pledges/stats`);
};
