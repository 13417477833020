import React from 'react';
import { css } from 'emotion';
import { string } from 'helpers';

import { LayoutContainer, P } from 'components';

export default function InfoSection({ charityName, moneyRaised }) {
  return (
    <>
      <LayoutContainer
        height="100px"
        color="green"
        className={css`
          margin-top: 10px;
        `}
      >
        {Boolean(charityName && moneyRaised) && (
          <P color="white">
            {string.formatMoney(moneyRaised)} raised for{' '}
            {string.capitalize(charityName)}
          </P>
        )}
      </LayoutContainer>
    </>
  );
}
