import { css } from 'emotion';
import React from 'react';
import { colors, fonts, media, zIndex } from 'helpers';

export default ({ black, children }) => {
  return (
    <h2
      className={css`
        color: white;
        text-align: center;
        background-color: ${black ? colors.black : colors.purple};
        font-family: ${fonts.fontFamily.primary};
        font-size: ${fonts.size.xl};
        font-weight: ${fonts.fontWeight.black};
        padding: 17px 0 17px 0;
        border-radius: 12px;
        margin-bottom: -12px;
        z-index: ${zIndex.layer2.modalHeader};
        position: relative;

        @media (max-width: ${media.screen.sm}) {
          font-size: ${fonts.size.md};
        }
      `}
    >
      {children}
    </h2>
  );
};
