import React from 'react';
import { css } from 'emotion';

export default ({
  handleSubmit = e => e.preventDefault(),
  children,
  ...props
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className={css`
        width: 100%;
        ${props.className}
      `}
    >
      {children}
    </form>
  );
};
