import React, { useState, useContext } from 'react';
import { css } from 'emotion';
import {
  Button,
  FormBox,
  Message,
  Form,
  TextInput,
  H,
  Animation,
  Link
} from 'components';
import { ToastContext } from 'context/Toast.context';
import LeftPoint from 'assets/Lottie/LeftPoint.json';
import { media } from 'helpers';
import { forgotPassword } from 'api/users.api';
export default props => {
  const { pushMessage } = useContext(ToastContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await forgotPassword({ email });
      pushMessage.success(
        "Password reset email sent. Check your spam folder if you can't find it."
      );
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={css`
        position: relative;
        margin-left: 56px;
        margin-right: 56px;
        height: 100vh;
      `}
    >
      <div
        className={css`
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        `}
      >
        {error && <Message error>{error}</Message>}
        <H
          color="black"
          fontFamily="title"
          textShadow={true}
          size="xl"
          className={css`
            text-align: center;
            margin: auto;
            margin-bottom: 50px;
          `}
        >
          Get Password Reset Email
        </H>
        <Form
          handleSubmit={e => submit(e)}
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <FormBox>
            <TextInput
              name="Email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              placeholder="Please enter your E-mail"
              required={true}
            />
          </FormBox>
          <Button
            boxShadow={true}
            submit={true}
            disabled={loading}
            buttonType="modal"
            className={css`
              :first-of-type {
                margin-bottom: 20px;
              }
            `}
          >
            Reset Password
          </Button>
          <Link
            className={css`
              margin-bottom: 40px;
              @media (max-width: ${media.screen.md}) {
                margin-bottom: 8px;
              }
            `}
            to="/"
          >
            ← Back
          </Link>
        </Form>
        <div
          className={css`
            position: relative;
            width: calc(100% - 272px);
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 20px;

            @media (max-width: ${media.screen.lg}) {
              margin: 20px 0 0 0;
              width: 100%;
            }
          `}
        >
          <Animation
            lottieFile={LeftPoint}
            className={css`
              height: 50%;
              width: 50%;
              margin: auto;
              max-height: 180px;
            `}
          />
        </div>
      </div>
    </div>
  );
};
