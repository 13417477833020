import React, { Children, useState } from 'react';
import { css } from 'emotion';
import { GoTriangleLeft, GoTriangleRight } from 'react-icons/go';
import { PaginationDots } from 'components';
import { colors, effect } from 'helpers';
import { Swiper } from './components/Swiper.component';
import { zIndex } from 'helpers';

export default ({ children, ...props }) => {
  const [sliderIndex, setSliderIndex] = useState(0);
  const maxIndex = Children.count(children) - 1;

  function toggleSliderNext(dir) {
    if (dir === 'left') {
      setSliderIndex(prevIndex => prevIndex - 1);
      if (sliderIndex === 0) {
        setSliderIndex(maxIndex);
      }
    }
    if (dir === 'right') {
      setSliderIndex(prevIndex => prevIndex + 1);
      if (sliderIndex === maxIndex) {
        setSliderIndex(0);
      }
    }
  }

  //wraps slider items
  const SliderWrapper = ({ children }) => {
    const slideItems = React.Children.map(children, child => (
      <div
        className={css`
          white-space: normal;
          width: 100vw;
        `}
      >
        {child}
      </div>
    ));

    return (
      <div
        className={css`
          display: flex;
          flex-direction: row;
        `}
      >
        {slideItems}
      </div>
    );
  };

  function toggleSliderNextWButton(dir) {
    if (dir === 'left') {
      setSliderIndex(prevIndex => prevIndex - 1);
      if (sliderIndex === 0) {
        setSliderIndex(maxIndex);
      }
    }
    if (dir === 'right') {
      setSliderIndex(prevIndex => prevIndex + 1);
      if (sliderIndex === maxIndex) {
        setSliderIndex(0);
      }
    }
  }
  return (
    <Swiper onSwipeX={toggleSliderNext} threshold={50}>
      <div
        className={css`
          width: 100%;
          position: relative;
          overflow: hidden;
        `}
      >
        {/* slider buttons */}
        <div
          className={css`
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: ${zIndex.layer3.sliderButtons};
            display: flex;
            justify-content: space-between;
            display: flex;
            @media (pointer: coarse) {
              display: none;
            }
          `}
        >
          <div
            onClick={() => toggleSliderNextWButton('left')}
            className={css`
              align-self: flex-start;
              display: flex;
              justify-content: center;
              border-radius: 4px;
              align-items: center;
              background-color: ${colors.yellow};
              width: 40px;
              height: 60px;
              ${effect.boxShadowSm}
              z-index: ${zIndex.layer4.sliderButton};
              &:hover {
                scale: 1.1;
              }
            `}
          >
            <GoTriangleLeft
              size={30}
              className={css`
                color: ${colors.black};
              `}
            />
          </div>
          <div
            onClick={() => toggleSliderNextWButton('right')}
            className={css`
              align-self: flex-end;
              display: flex;
              justify-content: center;
              border-radius: 4px;
              align-items: center;
              background-color: ${colors.yellow};
              width: 40px;
              height: 60px;
              ${effect.boxShadowSm}
              z-index: ${zIndex.layer4.sliderButton};
              &:hover {
                scale: 1.1;
              }
            `}
          >
            <GoTriangleRight
              size={30}
              className={css`
                color: ${colors.black};
              `}
            />
          </div>
        </div>

        {/* components wrapper  */}
        <div
          className={css`
            z-index: ${zIndex.layer3.sliderComponentsWrapper};
            overflow: visible;
            white-space: nowrap;
            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: flex-start;
            flex-direction: row;
            transition: 0.35s;
            transform: translateX(${`-${sliderIndex * 100}%`});
          `}
        >
          <SliderWrapper>{children}</SliderWrapper>
        </div>
        <PaginationDots dotTotal={maxIndex} activeIndex={sliderIndex} />
      </div>
    </Swiper>
  );
};
