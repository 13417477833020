import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { css } from 'emotion';

import { Search, Charity, Layout, List, Button } from 'components';
import { ToastContext } from 'context/Toast.context';

import { getCharities } from 'api/charities.api';
import { useLocation } from 'react-router-dom';
import { path } from 'helpers';

export default () => {
  const ITEMS_ON_LOAD = 2;
  const [perPage, setPerPage] = useState(0);
  const [showMore, setShowMore] = useState(true);
  const [displayCharities, setDisplayCharities] = useState([]); // data that is displayed by search/sort

  const { pushMessage } = useContext(ToastContext);
  const listInnerRef = useRef(null);
  const { search } = useLocation();

  // use url search string to get Charities

  const fetchCharities = useCallback(() => {
    async function callBack() {
      function parsePath() {
        const params = {};
        params.query = path.parseQuery(search);
        const filters = path.parseParams(search);

        if (filters?.charityCategoryId)
          params.charityCategoryId = filters['charityCategoryId'];

        return params;
      }
      const params = parsePath();
      try {
        const res = await getCharities({
          search: params.query,
          charityCategoryIds: params.charityCategoryId,
          resultsPerPage: showMore ? perPage + ITEMS_ON_LOAD : perPage
        });
        if (res.length > displayCharities.length) {
          setPerPage(perPage + ITEMS_ON_LOAD);
          setDisplayCharities(res);
          setShowMore(true);
        } else {
          setShowMore(false);
        }
        setDisplayCharities(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }

    callBack();
  }, [search, displayCharities, perPage, pushMessage, showMore]);

  // use url search string to get Pledge Drives
  useEffect(fetchCharities, [search]);

  function onScroll() {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        fetchCharities();
      }
    }
  }

  return (
    <Layout
      backTitle="Charities"
      className={css`
        margin-bottom: 58px;
      `}
    >
      <div>
        <Search
          data={displayCharities}
          setData={setDisplayCharities}
          filterListType="charities"
        />
      </div>
      <List
        onScroll={onScroll}
        ref={listInnerRef}
        itemLength={displayCharities?.length}
      >
        {displayCharities?.map(charity => (
          <Charity key={charity.id} charity={charity} />
        ))}
      </List>
      {!!showMore && (
        <Button
          onClick={fetchCharities}
          className={css`
            margin: auto;
            margin-top: 10px;
          `}
        >
          Show More
        </Button>
      )}
    </Layout>
  );
};
