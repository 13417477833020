import React, { useState, useEffect, useContext } from 'react';
import { ToastContext } from 'context/Toast.context';
import { getPledgeDrives } from 'api/pledgeDrives.api';

import { Layout, Title, P } from 'components';
import CompletedDrive from './CompletedDrive.component';
import { css } from 'emotion';
import { colors } from 'helpers';

export default () => {
  const [completedPledgeDrives, setCompletedPledgeDrives] = useState([]);
  const { pushMessage } = useContext(ToastContext);

  //get pledgedrive
  useEffect(() => {
    async function fetchPledgeDrives() {
      try {
        const res = await getPledgeDrives({ active: false });

        const completedDrives = res.filter(drive => drive.winnerId !== null);

        if (Array.isArray(completedDrives)) {
          setCompletedPledgeDrives(completedDrives);
        }
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    //fetch pledge drives
    fetchPledgeDrives();
  }, [pushMessage]);

  return (
    <Layout back={false}>
      <Title
        alignText="left"
        className={css`
          position: fixed;
          z-index: 2;
          top: 0;
          border-bottom: 4px solid ${colors.white};
        `}
      >
        PledgeLlama
        <P
          size="sm"
          color="yellow"
          weight="thin"
          className={css`
            margin: 0;
          `}
        >
          BETA
        </P>
      </Title>

      <div
        className={css`
          margin-top: 55px;
          width: 100%;
          flex-wrap: wrap;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        `}
      >
        {completedPledgeDrives.length > 0 ? (
          completedPledgeDrives?.map(winner => (
            <CompletedDrive key={winner.id} info={winner} />
          ))
        ) : (
          <div
            className={css`
              height: 400px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: ${colors.purple};
            `}
          >
            <P color="white" weight="black">
              No drives completed so far.
            </P>
          </div>
        )}
      </div>
    </Layout>
  );
};
