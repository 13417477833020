import React, { useContext, useEffect, useState } from 'react';

//api
import { getPledgeDriveCategories } from 'api/pledgeDriveCategories.api';

//context
import { ToastContext } from 'context/Toast.context';

//dom
import { useRouteMatch } from 'react-router-dom';

//style
import PledgeDriveImagesComponent from '../components/PledgeDriveImages.component';
import {
  Button,
  Input,
  Label,
  Message,
  Select,
  TextArea,
  CheckBoxInput
} from 'components';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { css } from 'emotion';
import { getCharities } from 'api/charities.api';
import { updatePledgeDrive, getPledgeDrive } from 'api/pledgeDrives.api';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const match = useRouteMatch();
  const pledgeDriveId = match.params.id;
  const [loading, setLoading] = useState(false);

  const [pledgeDrive, setPledgeDrive] = useState(null);
  const [charities, setCharities] = useState([]);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [charityId, setCharityId] = useState('');
  const [pledgeDriveCategories, setPledgeDriveCategories] = useState([]);
  const [pledgeDriveCategoryId, setPledgeDriveCategoryId] = useState('');
  const [featured, setFeatured] = useState(false);

  const [retailValue, setRetailValue] = useState('');
  const [pledgeValue, setPledgeValue] = useState('');
  const [trackingCode, setTrackingCode] = useState('');
  const [sentToWinnerAt, setSentToWinnerAt] = useState('');
  const [archived, setArchived] = useState(false);

  useEffect(() => {
    if (!pledgeDrive) return;
    setName(pledgeDrive.name);
    setDescription(pledgeDrive.description || '');
    setCharityId(pledgeDrive.charityId);
    setRetailValue(pledgeDrive.retailValue);
    setTrackingCode(pledgeDrive.trackingCode || '');
    setFeatured(pledgeDrive.featured);
    setPledgeDriveCategoryId(pledgeDrive.pledgeDriveCategoryId);
    setSentToWinnerAt(
      pledgeDrive.sentToWinnerAt
        ? moment(pledgeDrive.sentToWinnerAt).format('YYYY-MM-DD')
        : ''
    );
    setArchived(pledgeDrive.archived);
  }, [pledgeDrive]);

  //charities
  useEffect(() => {
    async function async() {
      try {
        const res = await getCharities();
        setCharities(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage]);

  //pledge drive
  useEffect(() => {
    async function async() {
      try {
        const res = await getPledgeDrive({ pledgeDriveId });
        setPledgeDrive(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage, pledgeDriveId]);

  //get pledge drive categories list
  useEffect(() => {
    const setStat = async e => {
      try {
        const res = await getPledgeDriveCategories();
        setPledgeDriveCategories(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    };
    setStat();
  }, [pushMessage]);

  //update pledge drive
  const update = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await updatePledgeDrive({
        pledgeDriveId,
        data: {
          name,
          description,
          charityId,
          retailValue,
          sentToWinnerAt: sentToWinnerAt ? moment(sentToWinnerAt) : null,
          trackingCode,
          pledgeDriveCategoryId,
          featured,
          archived
        }
      });
      setPledgeDrive(res);
      pushMessage.success('Pledge Drive updated.');
    } catch (e) {
      pushMessage.error(e.message);
    }

    setLoading(false);
  };

  if (!pledgeDrive) return <div>Pledge Drive Not Found.</div>;

  return (
    <div>
      <form onSubmit={update}>
        <Label>Name</Label>
        <Input
          marginBottom={8}
          onChange={e => setName(e.target.value)}
          value={name}
          required
        />
        <Label>Description</Label>
        <TextArea
          marginBottom={8}
          onChange={e => setDescription(e.target.value)}
          value={description}
        />

        <Label>Charity</Label>
        <Select
          marginBottom={8}
          value={charityId}
          onChange={e => setCharityId(e.target.value)}
          options={charities.map(charity => ({
            value: charity.id,
            label: charity.name
          }))}
          required
        />
        <Label>Pledge Drive Category</Label>
        <Select
          marginBottom={8}
          value={pledgeDriveCategoryId}
          onChange={e => setPledgeDriveCategoryId(e.target.value)}
          options={pledgeDriveCategories?.map(pledgeDriveCategory => ({
            value: pledgeDriveCategory.id,
            label: pledgeDriveCategory.name
          }))}
          required
        />
        <Label>Retail Value</Label>
        <NumberFormat
          customInput={Input}
          marginBottom={8}
          onValueChange={({ value }) => {
            setRetailValue(value);
            setPledgeValue(Math.ceil(value / 0.67));
          }}
          value={retailValue}
          required
          thousandSeparator
          prefix="$"
          decimalScale={2}
        />
        <Label>Pledge Value</Label>
        <Message error={false}>
          <strong>{pledgeValue || pledgeDrive?.pledgeValue || 0}</strong>
        </Message>
        <Label>Sent to Winner</Label>
        <Input
          type="date"
          marginBottom={8}
          onChange={e => setSentToWinnerAt(e.target.value)}
          value={sentToWinnerAt}
        />
        <Label>Tracking Code</Label>
        <Input
          marginBottom={8}
          onChange={e => setTrackingCode(e.target.value)}
          value={trackingCode}
        />
        <Label>Do you want to feature this on the homepage?</Label>
        <CheckBoxInput
          name="isFeatured"
          type="checkbox"
          checked={featured}
          onChange={e => {
            setFeatured(e.target.checked);
          }}
        />
        <Label>Archived</Label>
        <Select
          marginBottom={8}
          value={archived}
          onChange={e => setArchived(e.target.value)}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
        />

        <Button
          submit={true}
          disabled={loading}
          className={css`
            margin-bottom: 10px;
          `}
        >
          Save Changes
        </Button>
      </form>
      <PledgeDriveImagesComponent
        pledgeDrive={pledgeDrive}
        setPledgeDrive={setPledgeDrive}
      />
    </div>
  );
};
