import React from 'react';
import { css } from 'emotion';

export default ({ ...props }) => {
  return (
    <input
      className={css`
        width: 22px;
        height: 22px;
        margin-right: 18px;
        vertical-align: text-bottom;
        ${props.className}
      `}
      type="checkbox"
      {...props}
    />
  );
};
