import React from 'react';
import { css } from 'emotion';
import { P } from 'components';
export default ({ label, value, className }) => {
  return (
    <>
      <P
        size="xxs"
        className={css`
          flex: 1 1 auto;
          ${className}
        `}
      >
        <strong>{label}</strong>
        <br />
        {value}
      </P>
    </>
  );
};
