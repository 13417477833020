import React, { useContext, useEffect, useState } from 'react';
import { ToastContext } from 'context/Toast.context';
import { getPledgeStats } from 'api/pledges.api';
import { P } from 'components';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const [stats, setStats] = useState(0);

  useEffect(() => {
    async function async() {
      try {
        const res = await getPledgeStats();
        setStats(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage]);

  return (
    <div>
      <P>Unassigned Pledges: {stats?.unassignedPledges}</P>
      <br />
      <P>Remaining Pledges: {stats?.remainingPledges}</P>
    </div>
  );
};
