import axios from 'axios';

export default () => {
  const token = window.localStorage.getItem('token');
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: token ? `BEARER ${token}` : ''
    }
  });

  instance.interceptors.response.use(
    response => response.data,
    error => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return Promise.reject(error.response.data);
      } else {
        return Promise.reject({
          message:
            'Oops! Looks like a server error. Contact support if the problem persists.'
        });
      }
    }
  );

  return instance;
};
