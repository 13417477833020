import React, { useContext, useState } from 'react';
import { http } from 'helpers';
import { css } from 'emotion';

import { Input, Label, CheckBoxInput, Button, P } from 'components';

import { ToastContext } from 'context/Toast.context';

export const Category = ({ categoryName, category, onUpdate }) => {
  const { pushMessage } = useContext(ToastContext);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(category.name);
  const [archived, setArchived] = useState(category.archived);

  const update = e => {
    e.preventDefault();

    http()
      .put(`/${categoryName}/${category.id}`, {
        name,
        archived
      })
      .then(res => {
        onUpdate(res);
        setEditing(false);
        pushMessage.success('Category edited.');
      })
      .catch(err => pushMessage.error(err.message));
  };

  if (editing) {
    return (
      <form
        onSubmit={update}
        className={css`
          border: solid black 1px;
          border-radius: 5px;
          padding: 5px;
          margin: 4px;
          display: flex;
          align-items: center;
        `}
      >
        <Input
          placeholder={category.name}
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <Label>Archived</Label>
        <CheckBoxInput
          checked={archived}
          onChange={e => {
            setArchived(e.target.checked);
          }}
        />
        <Button submit={true}>Save</Button>
        <Button onClick={() => setEditing(false)}>Cancel</Button>
      </form>
    );
  }

  return (
    <div
      key={category.id}
      className={css`
        display: flex;
        align-items: center;
        margin: 4px;
        padding: 4px;
        border: solid black 1px;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
      `}
    >
      <div
        className={css`
          margin: 6px;
        `}
      >
        <P>{category.name}</P>
        <P size="sm">Archived : {category.archived.toString()}</P>
      </div>
      <Button onClick={() => setEditing(true)}>Edit</Button>
    </div>
  );
};
