import React, { useState, useContext } from 'react';

//api
import { sendCode, verifyPhone } from 'api/users.api';

//context
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';

//style
import { css } from 'emotion';
import { P, Button, Link, Logo, Form, FormBox, TextInput, H } from 'components';

//api
import { useHistory } from 'react-router-dom';

export default props => {
  const { setUser, user } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const history = useHistory();

  const [codeSent, setCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGetCode = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await sendCode();
      pushMessage.success(res.message);
      setCodeSent(true);
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyPhone = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await verifyPhone(verificationCode);
      setUser(res);
      pushMessage.success('Phone Verified');
      setCodeSent(false);
      history.push('/edit-account');
    } catch (e) {
      setCodeSent(false);
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <Logo
        className={css`
          width: 150px;
        `}
      />
      <div
        className={css`
          width: 80%;
          margin: 12px;
        `}
      >
        {codeSent ? (
          <Form
            className={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <FormBox>
              <TextInput
                name="Verification Code"
                placeholder="Verification Code"
                required
                value={verificationCode}
                onChange={e => setVerificationCode(e.target.value)}
                type="text"
              />
            </FormBox>
            <Button
              onClick={handleVerifyPhone}
              disabled={loading}
              className={css`
                margin-top: 12px;
              `}
            >
              Verify Phone
            </Button>
            <Link
              className={css`
                text-align: center;
                display: block;
                margin-top: 12px;
              `}
              to="/edit-account"
            >
              Change phone number
            </Link>
            <Link
              className={css`
                text-align: center;
                display: block;
                margin-top: 12px;
              `}
              to="/"
            >
              Go Home
            </Link>
          </Form>
        ) : (
          <div
            className={css`
              text-align: left;
            `}
          >
            <H
              fontFamily="secondary"
              weight="heavy"
              className={css`
                margin-top: 12px;
              `}
            >
              Your phone is unverified!
            </H>
            <div
              className={css`
                margin-top: 12px;
              `}
            >
              <P size="sm">
                We can’t process your subscription until we verify your phone
                number.
              </P>
              <P size="sm" weight="bold">
                Please verify your phone number. We will send a verification
                code to your phone number ending in{' '}
                <u>{user?.phone?.slice(-4)}</u>.
              </P>
            </div>
            <Button
              submit
              disabled={loading}
              onClick={handleGetCode}
              className={css`
                margin-top: 12px;
              `}
            >
              Get Code
            </Button>
            <Link to="/my-account">
              <div
                className={css`
                  margin-top: 24px;
                  text-align: left;
                `}
              >
                Change phone number
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
