import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import logo from './logo.png';

export default ({ className, ...props }) => {
  return (
    <Link to="/">
      <img
        src={logo}
        alt="PledgeLlama Logo"
        className={css`
          display: block;
          width: 200px;
          max-width: 100%;
          margin: 20px auto 30px auto;
          ${className}
        `}
      />
    </Link>
  );
};
