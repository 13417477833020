import React, { useContext, useEffect, useState } from 'react';
import { ToastContext } from 'context/Toast.context';
import { useRouteMatch } from 'react-router-dom';
import { Button, Input, Label, Select } from 'components';
import NumberFormat from 'react-number-format';
import UserPledgesComponent from '../components/UserPledges.component';
import { css } from 'emotion';
import { getUser, updateUser } from 'api/users.api';
export default props => {
  const { pushMessage } = useContext(ToastContext);
  const match = useRouteMatch();
  const userId = match.params.id;
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState(null);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailNotifications, setEmailNotifications] = useState('');
  const [subscriptionActive, setSubscriptionActive] = useState('');
  const [disabled, setDisabled] = useState('');
  const [isAdmin, setIsAdmin] = useState('');

  useEffect(() => {
    if (!user) return;
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setPhone(user.phone);
    setEmailNotifications(user.emailNotifications);
    setSubscriptionActive(user.subscriptionActive);
    setDisabled(user.disabled);
    setIsAdmin(user.isAdmin);
  }, [user]);

  useEffect(() => {
    async function async() {
      try {
        const res = await getUser({ userId });
        setUser(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage, userId]);

  //update user
  const update = e => {
    e.preventDefault();
    setLoading(true);
    if (
      isAdmin &&
      !user.isAdmin &&
      !window.confirm(
        'Are you sure you would like to make this user an administrator?'
      )
    ) {
      setIsAdmin(false);
      return;
    }

    try {
      const res = updateUser({
        userId,
        userInfo: {
          firstName,
          lastName,
          email,
          phone,
          emailNotifications,
          subscriptionActive,
          disabled,
          isAdmin
        }
      });
      setUser(res);
      pushMessage.success('User updated.');
    } catch (e) {
      pushMessage.error(e.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={update}>
        <Label>First Name</Label>
        <Input
          marginBottom={8}
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          required
        />
        <Label>Last Name</Label>
        <Input
          marginBottom={8}
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          required
        />
        <Label>Mobile Number</Label>
        <NumberFormat
          customInput={Input}
          placeholder="(xxx)xxx-xxxx"
          type="tel"
          marginBottom={8}
          onChange={e => setPhone(e.target.value)}
          value={phone}
          required
          format="(###)###-####"
          mask="_"
        />
        <Label>Email</Label>
        <Input
          type="email"
          marginBottom={8}
          onChange={e => setEmail(e.target.value)}
          value={email}
          required
        />
        <Label>Email Notifications</Label>
        <Select
          marginBottom={8}
          value={emailNotifications}
          onChange={e => setEmailNotifications(e.target.value)}
          options={[
            { value: true, label: 'On' },
            { value: false, label: 'Off' }
          ]}
        />
        <Label>Subscription Active</Label>
        <Select
          marginBottom={8}
          value={subscriptionActive}
          onChange={e => setSubscriptionActive(e.target.value)}
          options={[
            { value: true, label: 'Active' },
            { value: false, label: 'Inactive' }
          ]}
        />
        <Label>Disabled</Label>
        <Select
          marginBottom={8}
          value={disabled}
          onChange={e => setDisabled(e.target.value)}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
        />
        <Label>Admin</Label>
        <Select
          marginBottom={8}
          value={isAdmin}
          onChange={e => setIsAdmin(e.target.value)}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
        />

        <Button
          submit={true}
          disabled={loading}
          className={css`
            margin-bottom: 10px;
          `}
        >
          Save Changes
        </Button>
      </form>

      <UserPledgesComponent userId={userId} />
    </div>
  );
};
