import React from 'react';
import { css } from 'emotion';
import { colors } from 'helpers';

export default ({ marginBottom, ...props }) => {
  return (
    <textarea
      {...props}
      className={css`
        ${props.className};
        ${marginBottom && `margin-bottom: ${marginBottom}px`};
        width: 100%;
        border: none;
        background-color: ${colors.grey[85]};
        outline: none;
        padding: 10px;
      `}
    />
  );
};
