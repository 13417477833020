import React, { useState, useContext, useCallback } from 'react';

//context
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';

//api
import { setSubscriptionActive } from 'api/users.api';

//style
import {
  Layout,
  Button,
  Form,
  FormBox,
  TextInput,
  H,
  P,
  Link
} from 'components';
import { css } from 'emotion';
import { colors } from 'helpers';
import { useHistory } from 'react-router-dom';

//stripe
const stripe = window.Stripe(process.env.REACT_APP_STRIPE_API_KEY);

const elementOptions = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: 'Roboto, sans-serif'
    }
  }
};

const elements = stripe.elements({
  fonts: [
    { cssSrc: 'https://fonts.googleapis.com/css?family=Roboto&display=swap' }
  ]
});

const cardNumberElement = elements.create('cardNumber', elementOptions);
const cardExpiryElement = elements.create('cardExpiry', elementOptions);
const cardCvcElement = elements.create('cardCvc', elementOptions);

export default () => {
  //dom
  const history = useHistory();

  //context
  const { user, setUser } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);

  //form state
  const [zip, setZip] = useState('');
  const [loading, setLoading] = useState(false);

  //stripe elements
  const cardNumberRef = useCallback(node => {
    if (!node) return;
    cardNumberElement.mount(node);
  }, []);

  const cardExpiryRef = useCallback(node => {
    if (!node) return;
    cardExpiryElement.mount(node);
  }, []);

  const cardCvcRef = useCallback(node => {
    if (!node) return;
    cardCvcElement.mount(node);
  }, []);

  //submit function
  const subscribe = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const stripeResult = await stripe.createToken(cardNumberElement, {
        address_zip: zip,
        address_country: 'US'
      });

      if (stripeResult.error) throw stripeResult.error;

      const updatedUser = await setSubscriptionActive(
        true,
        stripeResult.token.id
      );

      setUser(updatedUser);
      pushMessage.success('Payment info changed.');
      history.push('/edit-account');
    } catch (e) {
      pushMessage.error('Payment Info Not Changed');
    }
    setLoading(false);
  };

  return (
    <Layout backTitle={'Subscribe'} nav={false}>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 83px);
        `}
      >
        <H
          fontFamily="secondary"
          weight="heavy"
          className={css`
            text-align: center;
            margin: 10px 0 10px 0;
          `}
        >
          Subscribe
        </H>
        {user.phoneVerified === false ? (
          <P
            size="sm"
            className={css`
              text-align: center;
              margin: 12px 0 12px 0;
            `}
          >
            You have to verify your{' '}
            <Link to="/phone-verification">phone number</Link> before you can
            subscribe
          </P>
        ) : (
          <Form
            className={css`
              width: 80%;
            `}
          >
            <FormBox
              className={css`
                background-color: ${colors.white};
              `}
            >
              <div
                ref={cardNumberRef}
                className={css`
                  border-bottom: 1px solid ${colors.black};
                `}
              />
              <div
                className={css`
                  display: flex;
                  margin-top: 48px;
                `}
              >
                <div
                  ref={cardExpiryRef}
                  className={css`
                    width: 35%;
                    margin-right: 5%;
                    margin-bottom: 0;
                    border-bottom: 1px solid ${colors.black};
                  `}
                />
                <TextInput
                  name="zip code"
                  type="text"
                  placeholder="ZIP Code"
                  required={true}
                  value={zip}
                  onChange={e => {
                    setZip(e.target.value);
                  }}
                  className={css`
                    width: 35%;
                    margin-right: 5%;
                    margin-bottom: 0;
                    border-bottom: 1px solid ${colors.black};
                  `}
                />
                <div
                  ref={cardCvcRef}
                  className={css`
                    width: 20%;
                    border-bottom: 1px solid ${colors.black};
                  `}
                />
              </div>
            </FormBox>
            <div
              className={css`
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <Button
                onClick={subscribe}
                disabled={loading}
                boxShadow={true}
                className={css`
                  margin-top: 24px;
                  max-height: 42px;
                  width: 100px;
                `}
              >
                Subscribe
              </Button>
            </div>
          </Form>
        )}
      </div>
    </Layout>
  );
};
