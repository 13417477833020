import React from 'react';
import { css } from 'emotion';
import { Animation } from 'components';
import Logo from 'assets/Lottie/Logo.json';

export default () => {
  return (
    <Animation
      lottieFile={Logo}
      className={css`
        max-width: 250px;
        margin: 0 auto;
      `}
    />
  );
};
