import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { css } from 'emotion';
import { zIndex } from 'helpers';
import { ModalContext } from 'context/Modal.context';
import ModalContent from './components/ModalContent.component';
import ModalHeader from './components/ModalHeader.component';

const Modal = ({ isOpen, close, children, className }) => {
  const modalNode = useContext(ModalContext);

  useEffect(() => {
    const closeOnEsc = e => {
      if (e.key === 'Escape' && close) {
        close();
      }
    };
    window.addEventListener('keydown', closeOnEsc);
    return () => window.removeEventListener('keydown', closeOnEsc);
  }, [close]);

  if (!modalNode) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={css`
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        z-index: ${zIndex.layer99.modalOuter};
        left: 0;
        top: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: ${isOpen ? 'flex' : 'none'};
      `}
      onClick={close} // click to close
    >
      <div
        className={css`
          background-color: transparent;
          display: flex;
          flex-direction: column;
          border-radius: 12px;
          z-index: ${zIndex.layer100.modalInner};
          position: relative;
          max-width: 90%;
          max-height: 90%;
          overflow: hidden;
          ${className};
        `}
        onClick={e => e.stopPropagation()} // disable click to close on inner
      >
        <div>{children}</div>
      </div>
    </div>,
    modalNode
  );
};

Modal.Content = ModalContent;
Modal.Header = ModalHeader;

export default Modal;
