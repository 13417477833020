export default {
  screen: {
    lg: '800px',
    md: '560px',
    sm: '380px',
    xs: '320px'
  },
  layout: {
    landscapeDesktop:
      'only screen and (min-width: 800px) and (orientation : landscape)',
    portraitDesktop:
      'only screen and (min-height: 1280px) and (orientation : portrait)',
    landscapeMobile:
      'only screen and (max-height: 800px) and (orientation : landscape)',
    portraitMobile:
      'only screen and (max-height: 1280px) and (orientation : portrait)'
  }
};
