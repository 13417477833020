import React, { useContext } from 'react';
import { UserContext } from 'context/User.context';
import { css } from 'emotion';
import { colors } from 'helpers';
import { BrowserRouter } from 'react-router-dom';
import MainNavLink from './MainNavLink.component';

export default ({ navState }) => {
  const { user } = useContext(UserContext);

  return (
    <>
      <BrowserRouter />
      <div
        className={css`
          position: fixed;
          margin-right: 20px;
          margin-top: 140px;
          width: 80%;
          border-radius: 0 12px 12px 0;
          border: ${colors.black} solid 2px;
          opacity: ${navState ? '0' : '1'};
          transition: 0.5s;
          transition-delay: ${navState ? '-0.2s' : '0.2s'};
          border-left: none;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        `}
      >
        {!user && (
          <MainNavLink color="black" to="/">
            Home
          </MainNavLink>
        )}
        <MainNavLink color="black" to="/about">
          About
        </MainNavLink>
        {!user ? null : (
          <MainNavLink color="black" to="/charities">
            Charities
          </MainNavLink>
        )}

        <MainNavLink color="black" to="/privacy-policy">
          Privacy Policy
        </MainNavLink>
        <MainNavLink color="black" to="/tos">
          Terms of Service
        </MainNavLink>
        <MainNavLink color="black" to="/contest-rules">
          Contest Rules
        </MainNavLink>
        <MainNavLink color="black" to="/feedback">
          Feedback
        </MainNavLink>
        <MainNavLink color="black" to="/shop" target="_blank">
          Shop
        </MainNavLink>
      </div>
    </>
  );
};
