import React from 'react';

//style
import { css } from 'emotion';
import { colors } from 'helpers';

//components
import { Animation, P } from 'components';

export default function OnPledgeAnimation({ showAnimation, file }) {
  return (
    <div
      className={css`
        position: absolute;
        bottom: 20px;
        pointer-events: none;
        opacity: ${showAnimation ? '1' : '0'};
        scale: 0.75;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${showAnimation
          ? 'translate(-70px,10px)'
          : 'translate(10px)'};
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) 700ms;
      `}
    >
      <Animation lottieFile={file} speed={2} loop={true} />
      <div
        className={css`
          background-color: ${colors.yellow};
          border-radius: 12px;
          transform: translateX(-50px);
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;
          :before {
            content: '';
            width: 0px;
            height: 0px;
            border-bottom-left-radius: 12px;
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 16px solid ${colors.yellow};
            border-bottom: 12px solid ${colors.yellow};
            left: -18px;
            top: 0;
          }
        `}
      >
        <P
          color="black"
          fontFamily="secondary"
          className={css`
            width: 100px;
            display: flex;
            margin-top: 5px;
            line-height: normal;
            margin-left: 40px;
            font-weight: 600;
          `}
        >
          Pledge Made!
        </P>
      </div>
    </div>
  );
}
