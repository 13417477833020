import React from 'react';
import { css } from 'emotion';
import { media, fonts, colors } from 'helpers';
import { P } from 'components';
export default ({ before, stat, after, br = true, className }) => {
  return (
    <>
      <P
        className={css`
          @media (max-width: ${media.screen.sm}) {
            font-size: ${fonts.size.xs};
          }
          @media (max-width: ${media.screen.xs}) {
            font-size: ${fonts.size.xxs};
          }
          ${className}
        `}
      >
        {before}{' '}
        <strong
          className={css`
            color: ${colors.green};
          `}
        >
          {stat}
        </strong>{' '}
        {after}
      </P>
      {!!br && <br />}
    </>
  );
};
