import React, { useState, useContext } from 'react';
import { css } from 'emotion';
import {
  Link,
  Modal,
  Message,
  Button,
  TextInput,
  Form,
  FormBox
} from 'components';
import { UserContext } from 'context/User.context';

import { logIn } from 'api/users.api';
import { media } from 'helpers';

export default ({ isOpen, close }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState('');

  async function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await logIn({ email, password });
      window.localStorage.setItem('token', res.token);
      setUser(res);
      close();
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  }
  return (
    <Modal isOpen={isOpen} close={close}>
      <Modal.Header>Welcome Back!</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error}</Message>}
        <Form handleSubmit={e => handleLogin(e)}>
          <FormBox>
            <TextInput
              name="email"
              type="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              placeholder="E-Mail"
              required={true}
            />
            <TextInput
              name="password"
              type="password"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              required={true}
            />
          </FormBox>
          <Button
            boxShadow={true}
            buttonType="modal"
            submit={true}
            disabled={loading}
            className={css`
              margin-top: 30px;
              &:first-of-type {
                margin-bottom: 24px;
              }
            `}
          >
            Continue
          </Button>
        </Form>
        <Link
          className={css`
            margin-bottom: 40px;
            @media (max-width: ${media.screen.md}) {
              margin-bottom: 8px;
            }
          `}
          to="/forgot-password"
        >
          Forget Password?
        </Link>
      </Modal.Content>
    </Modal>
  );
};
