import React from 'react';
import { css } from 'emotion';
import { Button } from 'components';
import { colors, media } from 'helpers';

import { useLocation } from 'react-router-dom';

export default ({ clickFunction, linkUrl, text, icon }) => {
  const { pathname } = useLocation();

  return (
    <Button
      size="xs"
      className={css`
        font-size: 10px;
        margin-top: 12px;
        margin-left: 18px;
        height: 40px;
        padding: ${icon != null ? '0' : ''};
        border: ${icon != null ? '' : `${colors.black} solid 2px;`};
        color: ${pathname.includes(linkUrl)
          ? `${colors.purple}`
          : `${colors.black}`};
        display: flex;
        flex-direction: column;
        font-weight: 300;
        font-style: italic;
        white-space: nowrap;
        @media (max-width: ${media.screen.sm}) {
          margin-left: 10px;
        }
      `}
      link={linkUrl ? `/${linkUrl}` : null}
      onClick={clickFunction}
    >
      {Boolean(icon) && (
        <div
          className={css`
            height: 30px;
            width: 30px;
            background-color: ${pathname.includes(linkUrl)
              ? colors.purple
              : colors.black};
            mask: url(${icon}) center no-repeat;
          `}
        />
      )}
      {text}
    </Button>
  );
};
