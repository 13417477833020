import React, { useState } from 'react';
import { css } from 'emotion';
import { colors } from 'helpers';
import { Button, P, H } from 'components';
import ExternalLinkModal from 'modals/ExternalLink/ExternalLink.modal';

export default function CopySection({
  description,
  tagline,
  websiteUrl,
  donationUrl
}) {
  const [externalLinkModal, setExternalLinkModal] = useState(false);
  const [externalLinkUrl, setExternalLinkUrl] = useState(false);

  function externalLinkClick(link) {
    setExternalLinkModal(!externalLinkModal);
    setExternalLinkUrl(link);
  }

  return (
    <>
      <div
        className={css`
          height: 100px;
          background-color: ${colors.green};
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          className={css`
            width: 100%;
            display: flex;
            align-content: center;
            justify-content: space-evenly;
          `}
        >
          <Button
            onClick={() => {
              externalLinkClick(websiteUrl);
            }}
          >
            Check Out Their Site
          </Button>

          <Button
            onClick={() => {
              externalLinkClick(donationUrl);
            }}
          >
            Donate Directly
          </Button>
        </div>
      </div>
      {/* Copy section */}
      <div
        className={css`
          display: flex;
          align-items: center;
          min-height: 100px;
          background-color: ${colors.yellow};
        `}
      >
        {/* Tagline section */}
        <H
          weight="black"
          size="md"
          className={css`
            margin-left: 40px;
          `}
        >
          {tagline ? tagline : 'More information coming soon'}
        </H>
      </div>
      {/* Description */}
      <div
        className={css`
          min-height: 300px;
          display: flex;
        `}
      >
        <P
          className={css`
            margin-top: 20px;
            margin-left: 40px;
          `}
        >
          {description ? description : 'More info coming soon'}
        </P>
      </div>
      <ExternalLinkModal
        isOpen={externalLinkModal}
        close={() => setExternalLinkModal(false)}
        link={externalLinkUrl}
      />
    </>
  );
}
