import React, { useState } from 'react';

export const PledgesContext = React.createContext();

export default props => {
  const [pledges, setPledges] = useState(null);

  return (
    <PledgesContext.Provider value={{ pledges, setPledges }}>
      {props.children}
    </PledgesContext.Provider>
  );
};
