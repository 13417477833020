import React, { useState, useEffect, useContext } from 'react';
import FilterList from '../FilterList.component';
import { ToastContext } from 'context/Toast.context';
import { getCharityCategories } from 'api/charityCategories.api';

export default function FilterListCharities({ filters, handleFilter }) {
  const [charityCategoryOpen, setCharityCategoryOpen] = useState(false);
  const [charityCategories, setCharityCategories] = useState([]);
  const { pushMessage } = useContext(ToastContext);

  useEffect(() => {
    async function asyncFunc() {
      try {
        const res = await getCharityCategories();
        setCharityCategories(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    asyncFunc();
  }, [pushMessage]);

  return (
    <>
      <FilterList
        text="Charity Category"
        field="charityCategoryId"
        open={charityCategoryOpen}
        handleClick={() => setCharityCategoryOpen(!charityCategoryOpen)}
        items={charityCategories}
        filters={filters}
        handleFilter={handleFilter}
      />
    </>
  );
}
