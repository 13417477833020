import React from 'react';
import { css } from 'emotion';
import { effect, colors } from 'helpers';

export default ({ dotTotal, activeIndex }) => {
  dotTotal += 1;

  return (
    <div
      className={css`
        display: flex;
        justify-content: flex-end;
        transform: translateY(-280px);
        margin-right: 10px;
      `}
    >
      {Array(dotTotal)
        .fill(null)
        .map((value, i) => (
          <div
            key={i + 1}
            className={css`
              margin-left: 5px;
              margin-right: 5px;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              background-color: ${i === activeIndex
                ? colors.yellow
                : colors.white};
              display: inline-block;
              ${i === activeIndex ? effect.boxShadowSm : ''}
            `}
          />
        ))}
    </div>
  );
};
