import React, { useState, useContext } from 'react';
import { css } from 'emotion';
import { Modal, Message, Button, TextInput, Form, FormBox } from 'components';
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';
import { changeUserPassword } from 'api/users.api';

export default ({ isOpen, close }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState('');
  const { pushMessage } = useContext(ToastContext);

  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (password !== password2) {
      setError('Passwords do not match!');
    } else {
      try {
        const res = await changeUserPassword({ currentPassword, password });
        setUser(res);
        window.localStorage.setItem('token', res.token);
        pushMessage.success('Password updated successfully.');
        close();
        resetFields();
      } catch (e) {
        setError(e.message);
      }
    }
    setLoading(false);
  };

  function resetFields() {
    setError('');
    setCurrentPassword('');
    setPassword('');
    setPassword2('');
  }

  return (
    <Modal
      isOpen={isOpen}
      close={() => {
        resetFields();
        close();
      }}
    >
      <Modal.Header>New Password</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error}</Message>}
        <Form
          handleSubmit={e => {
            submit(e);
          }}
        >
          <FormBox>
            <TextInput
              name="current"
              type="password"
              value={currentPassword}
              onChange={e => {
                setCurrentPassword(e.target.value);
              }}
              placeholder="Current Password"
              required={true}
            />
            <TextInput
              name="password"
              type="password"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              placeholder="New Password"
              required={true}
            />
            <TextInput
              name="password2"
              type="password"
              value={password2}
              onChange={e => {
                setPassword2(e.target.value);
              }}
              placeholder="Confirm New Password"
              required={true}
            />
          </FormBox>
          <Button
            boxShadow={true}
            buttonType="modal"
            submit={true}
            disabled={loading}
            className={css`
              margin-top: 30px;
              margin-bottom: 24px;
            `}
          >
            Change Password
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  );
};
