import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from 'react';

import { useLocation } from 'react-router-dom';
import { effect, colors, path } from 'helpers';

import {
  Search,
  PledgeDrive,
  Layout,
  List,
  Title,
  Button,
  P,
  Animation
} from 'components';

import { ToastContext } from 'context/Toast.context';

import { getPledgeDrives } from 'api/pledgeDrives.api';
import { css } from 'emotion';
import LoungingLlama from 'assets/UI/Stills-lounging.svg';
import Meditating from 'assets/Lottie/Meditating.json';

export default () => {
  const ITEMS_ON_LOAD = 10;
  const { pushMessage } = useContext(ToastContext);
  const [displayPledgeDrives, setDisplayPledgeDrives] = useState([]); // data that is displayed by search/sort
  const [perPage, setPerPage] = useState(0);
  const [showMore, setShowMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [prevParams, setPrevParams] = useState({ query: '' });

  const { search } = useLocation();
  const observer = useRef(null);

  const fetchPledgeDrives = useCallback(() => {
    async function callBack() {
      function parsePath() {
        const params = {};
        params.query = path.parseQuery(search);
        const filters = path.parseParams(search);
        if (filters?.pledgeDriveCategoryId)
          params.pledgeDriveCategoryId = filters['pledgeDriveCategoryId'];
        if (filters?.charityCategoryId)
          params.charityCategoryId = filters['charityCategoryId'];
        if (filters?.userId) params.userId = filters['userId'];
        if (filters?.sortBy) params.sortBy = filters['sortBy'];
        if (filters?.sortDirection)
          params.sortDirection = filters['sortDirection'];

        return params;
      }

      const params = parsePath();
      // if params have changed, reset perPage
      if (JSON.stringify(params) !== JSON.stringify(prevParams)) {
        setShowMore(true);
        setPerPage(0);
      }
      setLoading(true);
      try {
        const res = await getPledgeDrives({
          search: params.query,
          pledgeDriveCategoryIds: params.pledgeDriveCategoryId,
          charityCategoryIds: params.charityCategoryId,
          userId: params.userId,
          sortBy: params.sortBy,
          sortDirection: params.sortDirection,
          resultsPerPage:
            showMore || !perPage || perPage === 0
              ? perPage + ITEMS_ON_LOAD
              : perPage
        });
        if (res.length > displayPledgeDrives.length) {
          setPerPage(perPage + ITEMS_ON_LOAD);
          setShowMore(true);
        } else {
          setShowMore(false);
        }
        setDisplayPledgeDrives(res);
        setPrevParams(params);
      } catch (e) {
        pushMessage.error(e.message);
      } finally {
        setLoading(false);
      }
    }
    callBack();
  }, [search, displayPledgeDrives, perPage, pushMessage, showMore, prevParams]);

  // use url search string to get Pledge Drives
  useEffect(fetchPledgeDrives, [search]);

  const lastElemRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && showMore && !loading) {
          fetchPledgeDrives();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, showMore, fetchPledgeDrives]
  );

  return (
    <Layout showPledgesAvailable={true} back={false}>
      <Title
        alignText="left"
        className={css`
          border-bottom: 4px solid ${colors.white};
        `}
      >
        PledgeLlama
        <P
          size="sm"
          color="yellow"
          weight="thin"
          className={css`
            margin: 0;
          `}
        >
          BETA
        </P>
      </Title>

      <img
        src={LoungingLlama}
        alt="Lounging Pledge Llama"
        className={css`
          position: absolute;
          right: 10px;
          height: 50px;
          margin-top: -55px;
          ${effect.boxShadowSm}
        `}
      />
      <Search filterListType="pledgeDrives" sortBy={true} perPage={perPage} />
      <List
        itemLength={displayPledgeDrives?.length}
        className={css`
          overflow: hidden;
          background-color: ${colors.black};
        `}
      >
        {Boolean(displayPledgeDrives?.length > 0) &&
          displayPledgeDrives.map((pledgeDrive, index) => {
            if (displayPledgeDrives.length === index + 1) {
              return (
                <PledgeDrive
                  key={pledgeDrive.id}
                  pledgeDrive={pledgeDrive}
                  ref={lastElemRef}
                />
              );
            } else {
              return (
                <PledgeDrive
                  key={pledgeDrive.id}
                  pledgeDrive={pledgeDrive}
                  className={css`
                    margin-bottom: 10px;
                  `}
                />
              );
            }
          })}
      </List>
      {!!showMore ? (
        <Button
          onClick={fetchPledgeDrives}
          className={css`
            margin: auto;
            margin-top: 10px;
          `}
        >
          Show More
        </Button>
      ) : (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 10px 5px;
          `}
        >
          <Animation
            lottieFile={Meditating}
            className={css`
              width: 100px;
              height: 100px;
              margin-top: 10px;
            `}
          />
          <div
            className={css`
              text-align: center;
              background-color: rgba(159, 64, 144, 0.9);
              padding: 10px;
              border-radius: 20px;
              margin-top: 10px;
            `}
          >
            <P color="white">That's all for now!</P>
            <P size="xs" color="white">
              Check back later for more Pledge Drives!
            </P>
          </div>
        </div>
      )}
    </Layout>
  );
};
