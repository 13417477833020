import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Layout, H } from 'components';
import { LoginModal } from 'modals';
import { css } from 'emotion';
import { ToastContext } from 'context/Toast.context';
import { getPledgeDrives } from 'api/pledgeDrives.api';
import { getCharities } from 'api/charities.api';

import IntroTitle from './Guest/IntroTitle.component';
import IntroBody from './Guest/IntroBody.component';
import GuestPreview from './Guest/GuestPreview.component';

import Wave1 from 'assets/global/Wave1.svg';
import Wave2 from 'assets/global/Wave2.svg';
import Wave3 from 'assets/global/Wave3.svg';

import { media } from 'helpers';

export default () => {
  const [loginModal, setLoginModal] = useState(false);
  const [pledgeDrives, setPledgeDrives] = useState([]);
  const [charities, setCharities] = useState([]);
  const { pushMessage } = useContext(ToastContext);

  const handleGetFeaturedPledgeDrives = useCallback(() => {
    async function asyncFunc() {
      try {
        const res = await getPledgeDrives({ featured: true });
        setPledgeDrives(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    asyncFunc();
  }, [pushMessage]);

  const handleGetCharities = useCallback(() => {
    async function charities() {
      try {
        const res = await getCharities();
        setCharities(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    charities();
  }, [pushMessage]);

  useEffect(handleGetFeaturedPledgeDrives, [handleGetFeaturedPledgeDrives]);
  useEffect(handleGetCharities, [handleGetCharities]);

  return (
    <div
      className={css`
        overflow: hidden;
      `}
    >
      <Layout back={false} footer={true}>
        <LoginModal isOpen={loginModal} close={() => setLoginModal(false)} />

        <div
          className={css`
            background: url(${Wave1});
            background-size: cover;
            background-position: bottom;
            height: 100%;
          `}
        >
          <IntroBody />
          <IntroTitle />
        </div>

        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: url(${Wave2});
            background-size: cover;
          `}
        >
          <H
            color="white"
            fontFamily="title"
            weight="heavy"
            className={css`
              text-align: center;
              font-size: 36px;
              margin-top: 65px;
            `}
          >
            Featured Prizes!
          </H>

          {Boolean(pledgeDrives?.length > 0) && (
            <div
              className={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-top: 36px;
                @media (min-width: ${media.screen.lg}) {
                  flex-direction: row;
                  flex-wrap: wrap;
                  justify-content: center;
                }
              `}
            >
              {pledgeDrives.map(pledgeDrive => (
                <GuestPreview
                  key={pledgeDrive.id}
                  name={pledgeDrive.name}
                  image={pledgeDrive.imageUrls[0]}
                />
              ))}
            </div>
          )}

          <H
            color="purple"
            fontFamily="title"
            weight="heavy"
            className={css`
              margin-top: 180px;
              text-align: center;
              font-size: 36px;
            `}
          >
            Featured Charities!
          </H>

          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              margin-top: 36px;
              @media (min-width: ${media.screen.lg}) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
              }
            `}
          >
            {charities.map(charity => (
              <GuestPreview
                key={charity.id}
                name={charity.name}
                image={charity.logoUrl}
                className={css`
                  background-size: contain;
                `}
              />
            ))}
            <div
              className={css`
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: url(${Wave3});
                height: 500px;
                width: 100%;
                margin-bottom: -90px;
                background-size: cover;
                background-position: bottom;
              `}
            ></div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
