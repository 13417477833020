import React from 'react';
import { css } from 'emotion';
import { Link } from 'react-router-dom';
import { fonts, colors } from 'helpers';

export default props => {
  const styles = css`
    text-align: center;
    color: ${colors.green};
    font-family: ${fonts.fontFamily.secondary};
    font-weight: ${fonts.fontWeight.bold};
    text-decoration: underline;
    cursor: pointer;
    ${props.className};
  `;

  if (!props.to) {
    return (
      <span {...props} className={styles}>
        {props.children}
      </span>
    );
  }

  return (
    <Link {...props} className={styles}>
      {props.children}
    </Link>
  );
};
