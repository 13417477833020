import React from 'react';
import { css } from 'emotion';

import { Layout, H, P, Link } from 'components';

export default props => {
  return (
    <Layout backTitle="Privacy Policy">
      <div
        className={css`
          padding: 48px 24px;
        `}
      >
        <H
          level="2"
          size="sm"
          weight="bold"
          fontFamily="secondary"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          Last modified: November 8, 2022
        </H>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Introduction
          </P>
          IceBoxx, LLC ("Company" or "We") respect your privacy and are
          committed to protecting it through our compliance with this policy.
          This policy describes the types of information we may collect from you
          or that you may provide when you visit the website iceboxx.com or
          <Link to="/"> pledgellama.com </Link>(our "Website") and our practices
          for collecting, using, maintaining, protecting, and disclosing that
          information. This policy applies to information we collect:
          <br />
          <br />
          <strong>-</strong> On this Website.
          <br />
          <br />
          <strong>-</strong> In email, text, and other electronic messages
          between you and this Website.
          <br />
          <br />
          <strong>-</strong> It does not apply to information collected by:
          <br />
          <br />
          <strong>-</strong> Us offline or through any other means, including on
          any other website operated by Company or any third party (including
          our affiliates and subsidiaries); or
          <br />
          <br />
          <strong>-</strong> Any third party (including our affiliates and
          subsidiaries), including through any application or content (including
          advertising) that may link to or be accessible from or through the
          Website.
          <br />
          <br />
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time (see Changes
          to Our Privacy Policy). Your continued use of this Website after we
          make changes is deemed to be acceptance of those changes, so please
          check the policy periodically for updates.
          <br />
          <br />
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Children Under the Age of 18
          </P>
          Our Website is not intended for children under 18 years of age. No one
          under age 18 may provide any information to or on the Website. We do
          not knowingly collect personal information from children under18. If
          you are under 18, do not use or provide any information on this
          Website or through any of its features, register on the Website, make
          any purchases through the Website, or provide any information about
          yourself to us, including your name, address, telephone number, email
          address, or any screen name or user name you may use. If we learn we
          have collected or received personal information from a child under 18
          without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a child under 18, please contact us at support@pledgellama.com
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Information We Collect About You and How We Collect It
          </P>
          We collect several types of information from and about users of our
          Website, including information:
          <br />
          <br />
          <strong>-</strong> By which you may be personally identified, such as
          name, postal address, email address, telephone number, date of birth,
          or any other identifier by which you may be contacted online or
          offline ("personal information");
          <br />
          <br />
          <strong>-</strong> That is about you but individually does not
          identify you; and/or
          <br />
          <br />
          <strong>-</strong> About your internet connection, the equipment you
          use to access our Website, and usage details.
          <br />
          <br />
          <i>We collect this information:</i>
          <br />
          <strong>-</strong> Directly from you when you provide it to us.
          <br />
          <br />
          <strong>-</strong> Automatically as you navigate through the site.
          Information collected automatically may include usage details, IP
          addresses, and information collected through cookies, web beacons, and
          other tracking technologies.
          <br />
          <br />
          <i>Information You Provide to Us</i>
          <br />
          The information we collect on or through our Website may include:
          <br />
          <br />
          <strong>-</strong> Information that you provide by filling in forms on
          our Website. This includes information provided at the time of
          registering to use our Website, subscribing to our service, posting
          material, or requesting further services. We may also ask you for
          information when you enter a contest or promotion sponsored by us, and
          when you report a problem with our Website.
          <br />
          <br />
          <strong>-</strong> Records and copies of your correspondence
          (including email addresses), if you contact us.
          <br />
          <br />
          <strong>-</strong> Your responses to surveys that we might ask you to
          complete for research purposes.
          <br />
          <br />
          <strong>-</strong> Details of transactions you carry out through our
          Website and of the fulfillment of your orders. You may be required to
          provide financial information before placing an order through our
          Website.
          <br />
          <br />
          <strong>-</strong> Your search queries on the Website.
          <br />
          <br />
          <i>
            Information We Collect Through Automatic Data Collection
            Technologies
          </i>
          <br />
          <br />
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
          <br />
          <br />
          <strong>-</strong> Details of your visits to our Website, including
          traffic data, location data, logs, and other communication data and
          the resources that you access and use on the Website.
          <br />
          <br />
          <strong>-</strong> Information about your computer and internet
          connection, including your IP address, operating system, and browser
          type.
          <br />
          <br />
          The information we collect automatically may include personal
          information, but we may maintain it or associate it with personal
          information we collect in other ways or receive from third parties. It
          helps us to improve our Website and to deliver a better and more
          personalized service, including by enabling us to:
          <br />
          <br />
          <strong>-</strong> Estimate our audience size and usage patterns.
          <br />
          <br />
          <strong>-</strong> Store information about your preferences, allowing
          us to customize our Website according to your individual interests.
          <br />
          <br />
          <strong>-</strong> Speed up your searches.
          <br />
          <br />
          <strong>-</strong> Recognize you when you return to our Website.
          <br />
          <br />
          The technologies we use for this automatic data collection may
          include:
          <br />
          <br />
          <strong>-</strong> Cookies (or browser cookies). A cookie is a small
          file placed on the hard drive of your computer. You may refuse to
          accept browser cookies by activating the appropriate setting on your
          browser. However, if you select this setting you may be unable to
          access certain parts of our Website. Unless you have adjusted your
          browser setting so that it will refuse cookies, our system will issue
          cookies when you direct your browser to our Website.
          <br />
          <br />
          <strong>-</strong> Web Beacons. Pages of our Website [and our emails]
          may contain small electronic files known as web beacons (also referred
          to as clear gifs, pixel tags, and single-pixel gifs) that permit the
          Company, for example, to count users who have visited those pages or
          [opened an email] and for other related website statistics (for
          example, recording the popularity of certain website content and
          verifying system and server integrity).
          <br />
          <br />
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Information We Collect About You and How We Collect It
          </P>
          Some content or applications, including advertisements, on the Website
          are served by third-parties, including advertisers, ad networks and
          servers, content providers, and application providers. These third
          parties may use cookies alone or in conjunction with web beacons or
          other tracking technologies to collect information about you when you
          use our website. The information they collect may be associated with
          your personal information or they may collect information, including
          personal information, about your online activities over time and
          across different websites and other online services. They may use this
          information to provide you with interest-based (behavioral)
          advertising or other targeted content. We do not control these third
          parties' tracking technologies or how they may be used. If you have
          any questions about an advertisement or other targeted content, you
          should contact the responsible provider directly. For information
          about how you can opt out of receiving targeted advertising from many
          providers, see Choices About How We Use and Disclose Your
          Information.]
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            How We Use Your Information
          </P>
          We use information that we collect about you or that you provide to
          us, including any personal information:
          <br />
          <br />
          <strong>-</strong> To present our Website and its contents to you.
          <br />
          <br />
          <strong>-</strong> To provide you with information, products, or
          services that you request from us.
          <br />
          <br />
          <strong>-</strong> To fulfill any other purpose for which you provide
          it.
          <br />
          <br />
          <strong>-</strong> To provide you with notices about your account or
          subscription, including expiration and renewal notices.
          <br />
          <br />
          <strong>-</strong> To carry out our obligations and enforce our rights
          arising from any contracts entered into between you and us, including
          for billing and collection.
          <br />
          <br />
          <strong>-</strong> To notify you about changes to our Website or any
          products or services we offer or provide though it.
          <br />
          <br />
          <strong>-</strong> To allow you to participate in interactive features
          on our Website.
          <br />
          <br />
          <strong>-</strong> In any other way we may describe when you provide
          the information.
          <br />
          <br />
          <strong>-</strong> For any other purpose with your consent.
          <br />
          <br />
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers' target audiences. Even
          though we do not disclose your personal information for these purposes
          without your consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria.
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Disclosure of Your Information
          </P>
          We may disclose aggregated information about our users, and
          information that does not identify any individual, without
          restriction. We may disclose personal information that we collect or
          you provide as described in this privacy policy:
          <br />
          <br />
          <strong>-</strong> To our subsidiaries and affiliates.
          <br />
          <br />
          <strong>-</strong> To contractors, service providers, and other third
          parties we use to support our business.
          <br />
          <br />
          <strong>-</strong> To a buyer or other successor in the event of a
          merger, divestiture, restructuring, reorganization, dissolution, or
          other sale or transfer of some or all of IceBoxx, LLC's assets,
          whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which personal information held by IceBoxx, LLC
          about our Website users is among the assets transferred.
          <br />
          <br />
          <strong>-</strong> To fulfill the purpose for which you provide it.
          <br />
          <br />
          <strong>-</strong> For any other purpose disclosed by us when you
          provide the information.
          <br />
          <br />
          <strong>-</strong> With your consent.
          <br />
          <br />
          We may also disclose your personal information:
          <br />
          <br />
          <strong>-</strong> To comply with any court order, law, or legal
          process, including to respond to any government or regulatory request.
          <br />
          <br />
          <strong>-</strong> To enforce or apply our terms of or terms of sale
          or service and other agreements, including for billing and collection
          purposes.
          <br />
          <br />
          <strong>-</strong> If we believe disclosure is necessary or
          appropriate to protect the rights, property, or safety of IceBoxx,
          LLC, our customers, or others.
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Choices About How We Use and Disclose Your Information
          </P>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
          <br />
          <br />
          <strong>-</strong> Promotional Offers from the Company. If you do not
          wish to have your contact information used by the Company to promote
          our own or third parties' products or services, you can opt-out by
          logging into the Website and adjusting your user preferences in your
          account profile by checking or unchecking the relevant boxes or by
          sending us an email stating your request to support@pledgellama.com.
          If we have sent you a promotional email, you may send us a return
          email asking to be omitted from future email distributions. This opt
          out does not apply to information provided to the Company as a result
          of a product purchase, warranty registration, product service
          experience or other transactions.
          <br />
          <br />
          We do not control third parties' collection or use of your information
          to serve interest-based advertising. However these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative ("NAI") on the NAI's
          website.
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Accessing and Correcting Your Information
          </P>
          You can review and change your personal information by logging into
          the Website and visiting your account profile page.
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Your State Privacy Rights
          </P>
          Colorado, Connecticut, Virginia, and Utah each provide their state
          residents with rights to:
          <br />
          <br />
          <strong>-</strong> Confirm whether we process their personal
          information.
          <br />
          <br />
          <strong>-</strong> Access and delete certain personal information.
          <br />
          <br />
          <strong>-</strong> Data portability.
          <br />
          <br />
          <strong>-</strong> Opt-out of personal data processing for targeted
          advertising and sales.
          <br />
          <br />
          Colorado, Connecticut, and Virginia also provide their state residents
          with rights to:
          <br />
          <br />
          <strong>-</strong> Correct inaccuracies in their personal information,
          taking into account the information's nature processing purpose.
          <br />
          <br />
          <strong>-</strong> Opt-out of profiling in furtherance of decisions
          that produce legal or similarly significant effects.
          <br />
          <br />
          To exercise any of these rights please email support@pledgellama.com.
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Data Security
          </P>
          <br />
          <br />
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure. The safety and security of your
          information also depends on you. Where we have given you (or where you
          have chosen) a password for access to certain parts of our Website,
          you are responsible for keeping this password confidential. We ask you
          not to share your password with anyone.
          <br />
          <br />
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Changes to Our Privacy Policy
          </P>
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat our users'
          personal information, we will notify you through a notice on the
          Website home page. The date the privacy policy was last revised is
          identified at the top of the page. You are responsible for ensuring we
          have an up-to-date active and deliverable email address for you, and
          for periodically visiting our Website and this privacy policy to check
          for any changes.
          <P
            size="sm"
            weight="black"
            color="purple"
            className={css`
              margin: 12px 0 12px 0;
            `}
          >
            Contact Information
          </P>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at: support@pledgellama.com.
        </P>
      </div>
    </Layout>
  );
};
