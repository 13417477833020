import React from 'react';
import { css } from 'emotion';
import { colors, fonts } from 'helpers';

import { Button, Modal } from 'components';

export default ({ isOpen, close, link }) => {
  return (
    <Modal isOpen={isOpen} close={close}>
      <div
        className={css`
          background-color: ${colors.purple};
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 12px;
          margin-top: 18px;
          margin-bottom: 17px;
          width: calc(100vw - 76px);
          min-height: 231px;
          max-width: 560px;
          padding: 10px;
          a {
            width: 100%;
            text-decoration: none;
          }
        `}
      >
        <h2
          className={css`
            color: white;
            text-align: center;
            font-family: ${fonts.fontFamily.secondary};
            font-weight: ${fonts.fontWeight.bold};
            padding-left: 70px;
            padding-right: 70px;
          `}
        >
          You are entering a site that is not associated with PledgeLlama
        </h2>
        <a href={link}>
          <Button buttonType="modal" boxShadow={true}>
            OK
          </Button>
        </a>
        <Button buttonType="modal" boxShadow={true} onClick={close}>
          Go Back
        </Button>
      </div>
    </Modal>
  );
};
