import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from 'context/User.context';
import { ProtectedRoute } from 'components';
export default ({ ...props }) => {
  const { user } = useContext(UserContext);

  return Boolean(user && user.isAdmin) ? (
    <ProtectedRoute {...props} />
  ) : (
    <Redirect to="/" />
  );
};
