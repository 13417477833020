import http from 'helpers/http.helper';

/* /users */

export const getUsers = () => {
  return http().get('/users');
};

export const signUp = ({ userData }) => {
  return http().post('/users', {
    firstName: userData.firstName,
    lastName: userData.lastName,
    phone: userData.phone.replace(/\D/g, ''),
    email: userData.email,
    password: userData.password,
    isUnsubscribed: userData.isUnsubscribed,
    TOSVersion: 'v0',
    contestRulesVersion: 'v0'
  });
};

export const resetPassword = ({ password, resetToken }) => {
  return http().post('/users/reset-password', {
    resetToken,
    password
  });
};

/* users/userId */

export const getUser = ({ userId }) => {
  return http().get(`/users/${userId}`);
};

export const updateUser = ({ userId, userInfo }) => {
  return http().put(`/users/${userId}`, userInfo);
};

/* /users/me */

export const logIn = ({ email, password }) => {
  return http().get('/users/me', {
    headers: {
      Authorization: 'BASIC ' + btoa(`${email}:${password}`)
    }
  });
};

export const getUserInfo = () => {
  return http().get('/users/me');
};

export const editUserInfo = data => {
  return http().put('/users/me', data);
};

export const editUserProfile = data => {
  return http().put('/users/me/profile', data);
};

export const changeUserPassword = ({ currentPassword, password }) =>
  http().put('/users/me/profile', { currentPassword, password });

export const forgotPassword = ({ email }) => {
  http().post('/users/forgot-password', {
    email
  });
};

export const setSubscriptionActive = (setActive, cardToken) => {
  return cardToken
    ? http().put('/users/me', {
        subscriptionActive: setActive,
        cardToken: cardToken,
        isUnsubscribed: false
      })
    : http().put('/users/me', {
        subscriptionActive: setActive,
        isUnsubscribed: false
      });
};

export const sendCode = () => {
  return http().post('/users/me/phone-verification');
};

export const verifyPhone = verificationCode => {
  return http().put('/users/me/phone-verification', { verificationCode });
};
