import React from 'react';
import { css } from 'emotion';

import { effect, colors } from 'helpers';
import { H, P } from 'components';

import CoverImg from 'assets/intro/llama-bg.png';

export default function CoverAndStats({
  coverUrl,
  logoUrl,
  name,
  completedDrivesTotal
}) {
  return (
    <>
      {/* cover image */}
      {coverUrl ? (
        <img
          src={coverUrl}
          alt={`${name} cover`}
          className={css`
            width: 100%;
            height: 280px;
            object-fit: cover;
          `}
        />
      ) : (
        <div>
          <img
            src={CoverImg}
            alt="Charity Cover"
            className={css`
              width: 100%;
              height: 280px;
              object-fit: cover;
            `}
          />
          <div
            className={css`
              position: absolute;
              padding-top: 20px;
              padding-bottom: 20px;
              margin-left: 20px;
              border-radius: 12px 0 0 12px;
              margin-top: -140px;
              background-color: ${colors.white};
            `}
          >
            <H
              weight="black"
              className={css`
                ${effect.boxShadowSm}
              `}
            >
              {name}
            </H>
          </div>
        </div>
      )}

      {/* info section */}
      <div
        className={css`
          height: 100px;
          width: 100%;
          margin-top: -3px;
          background-color: ${colors.white};
          display: flex;
          align-items: center;
          ${effect.boxShadowSm}
        `}
      >
        <div
          className={css`
            width: 30%;
            height: 100%;
            background-color: ${colors.white};
            border-radius: 0 5px 5px 0;
            ${effect.boxShadowSm}
          `}
        >
          <img
            src={logoUrl}
            alt={name}
            height="100%"
            className={css`
              width: 100%;
              object-fit: contain;
              padding: 6px;
              border-radius: 0 5px 5px 0;
            `}
          />
        </div>
        <div
          className={css`
            width: 70%;
          `}
        >
          <P size="sm">Pledge drives completed {completedDrivesTotal}</P>
        </div>
      </div>
    </>
  );
}
