import React, { useState, useEffect, useContext } from 'react';
import { css } from 'emotion';
import { colors, effect, fonts, zIndex } from 'helpers';
import FilterByModal from './modals/FilterBy.modal';
import SortByModal from './modals/SortBy.modal';
import { useHistory, useLocation } from 'react-router-dom';
import { path } from 'helpers';
import { SubscribeReminderModal } from 'modals';

import { UserContext } from 'context/User.context';

import {
  MdFilterList as FilterIcon,
  MdSearch as SearchIcon,
  MdSort as SortAscIcon
} from 'react-icons/md';

export default ({ buttons, filterListType, sortBy, subtitle }) => {
  const history = useHistory();
  const [modalFilterOpen, setModalFilterOpen] = useState(false);
  const [modalSortOpen, setModalSortOpen] = useState(false);

  const [filters, setFilters] = useState({});
  const [sortByFilters, setSortByFilters] = useState({});
  const [searchString, setSearchString] = useState('');
  const [subscribeReminderOpen, setSubscribeReminderOpen] = useState(false);
  const { search, pathname } = useLocation();
  const { user } = useContext(UserContext);

  function toggleFilterItem(key, value) {
    const newFilters = { ...filters };
    // if item already exists in filter key, remove it
    if (newFilters[key] && newFilters[key].includes(value)) {
      newFilters[key] = newFilters[key].filter(i => i !== value);
      // delete key if length is zero
      if (newFilters[key].length < 1) delete newFilters[key];
      // if item does not exist in key, add it
    } else
      newFilters[key] = newFilters[key] ? [...newFilters[key], value] : [value];
    setFilters(newFilters);
  }

  function handleSortByFilter(key, direction) {
    const newFilters = {};
    if (
      sortByFilters.sortBy !== key ||
      sortByFilters.sortDirection !== direction
    ) {
      newFilters.sortBy = key;
      newFilters.sortDirection = direction;
    }
    setSortByFilters(newFilters);
  }

  function resetFilters() {
    setFilters([]);
  }

  function resetSortByFilters() {
    setSortByFilters([]);
  }

  async function handleSearch() {
    if (user?.subscriptionActive) {
      const string = searchString.replace(/\\/g, '');
      const filterMap = path.writeParams(filters);
      const sortMap = path.writeParams(sortByFilters);
      history.push({
        pathname: pathname,
        search: `${string}${filterMap}${sortMap}`
      });
    } else {
      setSubscribeReminderOpen(true);
    }
  }

  // autofill filters from url
  useEffect(() => {
    const query = path.parseQuery(search);
    const newFilters = {};
    Object.entries(path.parseParams(search)).forEach(entry => {
      if (entry[0] !== 'sortBy' && entry[0] !== 'sortDirection')
        newFilters[entry[0]] = entry[1].split(',');
    });
    const newSortByFilters = {};
    Object.entries(path.parseParams(search)).forEach(entry => {
      if (entry[0] === 'sortBy' || entry[0] === 'sortDirection')
        newSortByFilters[entry[0]] = entry[1];
    });

    setSearchString(query);
    setFilters(newFilters);
    setSortByFilters(newSortByFilters);
  }, [search]);

  return (
    <div
      className={css`
        position: sticky;
        background-color: ${colors.purple};
        top: 0;
        z-index: ${zIndex.layer1.search};
        padding: 10px 20px;
        border-bottom: 4px solid ${colors.white};
      `}
    >
      {/* buttons */}
      {!!buttons && (
        <div
          className={css`
            display: flex;
            flex: 1 1 auto;
            max-width: 80%;
            margin-bottom: 25px;
          `}
        >
          {buttons}
        </div>
      )}
      {/* searchBar */}
      <div
        className={css`
          display: flex;
          align-items: center;
        `}
      >
        {/* filter */}
        {filterListType && (
          <>
            <button
              type="button"
              className={css`
                min-width: 46px;
                min-height: 30px;
                appearance: none;
                background-color: ${Object.keys(filters).length > 0
                  ? colors.white
                  : colors.purple};
                color: ${Object.keys(filters).length > 0
                  ? colors.purple
                  : colors.white};
                border: ${Object.keys(filters).length > 0
                  ? `1px solid ${colors.purple}`
                  : `1px solid ${colors.white}`};
                border-radius: 8px;
                margin-right: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: ${fonts.fontFamily.secondary};
                font-size: ${fonts.size.xs};
                &:hover {
                  cursor: pointer;
                  opacity: 0.8;
                }
              `}
              onClick={() =>
                user?.subscriptionActive
                  ? setModalFilterOpen(true)
                  : setSubscribeReminderOpen(true)
              }
            >
              <FilterIcon
                size={20}
                className={css`
                  color: ${Object.keys(filters).length > 0
                    ? colors.purple
                    : colors.white};
                `}
              />
            </button>
            <FilterByModal
              type={filterListType}
              isOpen={modalFilterOpen}
              close={() => {
                setModalFilterOpen(false);
                handleSearch();
              }}
              resetFilters={resetFilters}
              filters={filters}
              handleFilter={toggleFilterItem}
            />
          </>
        )}

        {/* sort */}
        {sortBy && (
          <>
            <button
              type="button"
              className={css`
                min-width: 46px;
                min-height: 30px;
                appearance: none;
                background-color: ${Object.keys(sortByFilters).length > 0
                  ? colors.white
                  : colors.purple};
                color: ${Object.keys(sortByFilters).length > 0
                  ? colors.purple
                  : colors.white};
                border: ${Object.keys(sortByFilters).length > 0
                  ? `1px solid ${colors.purple}`
                  : `1px solid ${colors.white}`};
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 16px;
                font-family: ${fonts.fontFamily.secondary};
                font-size: ${fonts.size.xs};
                &:hover {
                  cursor: pointer;
                  opacity: 0.8;
                }
              `}
              onClick={() =>
                user?.subscriptionActive
                  ? setModalSortOpen(true)
                  : setSubscribeReminderOpen(true)
              }
            >
              <SortAscIcon
                size={20}
                className={css`
                  color: ${Object.keys(sortByFilters).length > 0
                    ? colors.purple
                    : colors.white};
                `}
              />
            </button>
            <SortByModal
              isOpen={modalSortOpen}
              close={() => {
                setModalSortOpen(false);
                handleSearch();
              }}
              resetSortByFilters={resetSortByFilters}
              sortByFilters={sortByFilters}
              handleSortByFilter={handleSortByFilter}
            />
          </>
        )}
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSearch();
          }}
          className={css`
            width: 100%;
          `}
        >
          {subtitle && (
            <small
              className={css`
                color: white;
                font-family: ${fonts.fontFamily.secondary};
                margin-left: 12px;
              `}
            >
              {subtitle}
            </small>
          )}
          <input
            type="search"
            value={searchString}
            onChange={e => {
              setSearchString(e.target.value);
            }}
            className={css`
              appearance: none;
              border-radius: 12px;
              ${effect.boxShadowLg};
              padding: 8px;
              font-family: ${fonts.fontFamily.secondary};
              font-size: 18px;
              width: 100%;
              border: none;
              position: relative;

              &::-webkit-search-decoration,
              &::-webkit-search-cancel-button,
              &::-webkit-search-results-button,
              &::-webkit-search-results-decoration {
                -webkit-appearance: none;
              }
            `}
          />

          <button
            type="submit"
            className={css`
              background: transparent;
              border: none;
              border-left: 1px solid ${colors.purple};
              outline: none;
              position: absolute;
              right: 22px;
              top: 50%;
              transform: translateY(-50%);

              &:hover {
                cursor: pointer;
              }
            `}
          >
            <SearchIcon
              size={22}
              color={colors.purple}
              opacity={searchString ? 1 : 0.5}
              className={css`
                transform: translateY(2px);

                &:hover {
                  opacity: 0.8;
                }
              `}
            />
          </button>
        </form>
      </div>
      <SubscribeReminderModal
        isOpen={subscribeReminderOpen}
        close={() => {
          setSubscribeReminderOpen(false);
        }}
      />
    </div>
  );
};
