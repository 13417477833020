import React, { useState, useEffect, useContext } from 'react';

//context
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';

//api
import { getPledgeDrives } from 'api/pledgeDrives.api';
import { getPledges } from 'api/pledges.api';
import { setSubscriptionActive } from 'api/users.api';

//style
import { Layout, Button, P, H } from 'components';
import { css } from 'emotion';
import { string } from 'helpers';
import moment from 'moment';

import { useHistory } from 'react-router-dom';

export default () => {
  //dom
  const history = useHistory();

  //context
  const { user, setUser } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);

  //stats
  const [pledgeCount, setPledgeCount] = useState('');
  const [activePledgeCount, setActivePledgeCount] = useState('');
  const [moneyRaised, setMoneyRaised] = useState('');
  const [charityCount, setCharityCount] = useState('');
  const [renewalDate, setRenewalDate] = useState('');

  //loading
  const [loading, setLoading] = useState(false);

  //set stats
  //Pledge Count
  useEffect(() => {
    async function getStat() {
      try {
        const res = await getPledges({ userId: user.id });
        setPledgeCount(res.length);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    getStat();
  }, [pushMessage, user]);

  //Active Pledge Count
  useEffect(() => {
    async function getStat() {
      try {
        const stat = await getPledgeDrives({
          withArchived: true,
          userId: user.id
        });
        setActivePledgeCount(stat.length);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }

    getStat();
  }, [user, pushMessage]);

  //set money raised
  useEffect(() => {
    const stat = pledgeCount * 0.67;
    setMoneyRaised(stat);
  }, [pledgeCount]);

  //Active Pledge Count
  useEffect(() => {
    async function getStat() {
      try {
        const pledgeDrives = await getPledgeDrives({
          withArchived: true,
          userId: user.id
        });
        const charityTotal = [
          ...new Set(pledgeDrives.map(drive => drive.charityId))
        ];

        setCharityCount(charityTotal.length);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }

    getStat();
  }, [user, pushMessage]);

  //renewal date
  useEffect(() => {
    setRenewalDate(
      moment(user.lastPaymentAt)
        .add(1, 'month')
        .format('DD-MM-YYYY')
    );
  }, [user]);

  //function
  async function deActivate() {
    setLoading(true);
    try {
      const res = await setSubscriptionActive(false);

      setUser(res);
      pushMessage.success('Subscription canceled successfully.');

      history.push('/feedback');
    } catch (e) {
      pushMessage.error(e);
    }
    setLoading(false);
  }

  return (
    <Layout backTitle="Unsubscribe">
      <div
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 83px);
        `}
      >
        <H
          fontFamily="secondary"
          weight="heavy"
          className={css`
            text-align: center;
            margin-bottom: 24px;
          `}
        >
          Sorry to see yah go!
        </H>
        <div
          className={css`
            height: 80px;
            flex-direction: column;
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            text-align: center;
          `}
        >
          <P size="xs">
            To date you have made{' '}
            <strong>{pledgeCount ? pledgeCount : ' no '}</strong>{' '}
            {pledgeCount === 1 ? 'pledge' : 'pledges'}
          </P>

          <P size="xs">
            You have{' '}
            <strong>{activePledgeCount ? activePledgeCount : ' no '}</strong>{' '}
            active {activePledgeCount > 0 ? ' pledge drive' : ' pledge drives'}
          </P>
          <P size="xs">
            You have helped raise{' '}
            <strong>
              {moneyRaised ? string.formatMoney(moneyRaised) : 'no'}
            </strong>{' '}
            for <strong>{charityCount}</strong>{' '}
            {charityCount > 0 ? ' charity' : ' charities'}
          </P>
          <P size="xs">
            Your subscription renews on{' '}
            <strong>
              {renewalDate ? renewalDate + ' ' : ' date undefined '}
            </strong>
          </P>
        </div>
        {/* Button and Image */}

        <Button
          boxShadow={true}
          disabled={loading}
          onClick={deActivate}
          className={css`
            margin-top: 12px;
            max-height: 42px;
          `}
        >
          Unsubscribe?
        </Button>
      </div>
    </Layout>
  );
};
