import React from 'react';
import { css } from 'emotion';
import { colors, media } from 'helpers';

import FilterButtonComponent from './FilterButton.component';
import FilterListItemComponent from './FilterListItem.component';
export default function({
  text,
  field,
  handleClick,
  open,
  items,
  filters,
  handleFilter
}) {
  return (
    <>
      <FilterButtonComponent
        text={text}
        open={open}
        handleClick={() => handleClick(!open)}
      />
      <ul
        className={css`
          display: ${open ? 'initial' : 'none'};
          list-style: none;
          margin-block-start: 0;
          margin-block-end: 0;
          padding-inline-start: 0;
          padding-left: 15px;
          padding-right: 15px;
          box-shadow: 0px 3px 6px 0px ${colors.black};
          padding-top: 55px;
          margin-top: -55px;
          z-index: 0;
          padding-bottom: 8px;
          border-radius: 12px;

          @media (max-width: ${media.screen.sm}) {
            padding-top: 32px;
            margin-top: -32px;
          }
        `}
      >
        {items?.length > 0 &&
          items.map(item => (
            <FilterListItemComponent
              key={item.id}
              item={item}
              field={field}
              filters={filters}
              handleFilter={handleFilter}
            />
          ))}
      </ul>
    </>
  );
}
