import React, { useState, useContext } from 'react';
import { fonts, colors, media } from 'helpers';
import {
  Button,
  Layout,
  Form,
  FormBox,
  TextInput,
  CheckBoxInput,
  InputLabel,
  Avatar,
  Message,
  TextBubble,
  Animation,
  P
} from 'components';
import LeftPoint from 'assets/Lottie/LeftPoint.json';

import { UserContext } from 'context/User.context';
import { css } from 'emotion';
import { ToastContext } from 'context/Toast.context';
import { ChangePasswordModal } from 'modals';
import { editUserInfo } from 'api/users.api';
import NumberFormat from 'react-number-format';

export default props => {
  // context
  const { user, setUser } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  //data
  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [emailNotifications, setEmailNotifications] = useState(
    user?.emailNotifications || false
  );

  //modals
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);

  const updateProfile = async e => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const res = await editUserInfo({
        firstName,
        lastName,
        phone: phone.replace(/\D/g, ''),
        email,
        emailNotifications
      });
      setUser(res);
      pushMessage.success('Profile updated successfully.');
    } catch (e) {
      setError(e.message);
    }

    setLoading(false);
  };

  return (
    <Layout backTitle="Manage Profile">
      <div
        className={css`
          margin: 24px 60px 0 60px;
          @media (max-width: ${media.screen.sm}) {
            margin: 12px 10px 0 10px;
          }
        `}
      >
        <Form
          handleSubmit={e => {
            updateProfile(e);
          }}
          className={css`
            height: 100%;
          `}
        >
          <FormBox
            className={css`
              margin-bottom: 20px;
            `}
          >
            <Avatar
              src={user.profilePictureUrl}
              editButton={true}
              minWidth={'170px'}
              minHeight={'170px'}
              className={css`
                margin: auto;
                margin-bottom: 18px;
              `}
            />
            <div
              className={css`
                display: flex;
                align-items: center;
                margin-bottom: 43px;
              `}
            >
              <CheckBoxInput
                name="checkBoxEmail"
                type="checkbox"
                checked={emailNotifications}
                required={false}
                onChange={e => {
                  setEmailNotifications(e.target.checked);
                }}
              />
              <InputLabel
                htmlFor="checkBoxEmail"
                className={css`
                  font-size: ${fonts.size.xs};
                  font-weight: ${fonts.fontWeight.bold};
                  color: ${colors.black};
                `}
              >
                Receive E-mail Notificications
              </InputLabel>
            </div>
            <TextInput
              name="firstName"
              value={firstName}
              onChange={e => {
                setFirstName(e.target.value);
              }}
              placeholder="Please enter a first name..."
              required={true}
            />

            <TextInput
              name="lastName"
              value={lastName}
              onChange={e => {
                setLastName(e.target.value);
              }}
              placeholder="Please enter a last name..."
              required={true}
            />

            <TextInput
              name="email"
              type="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
              placeholder="Please enter an email..."
              required={true}
            />
            <NumberFormat
              name="Phone"
              customInput={TextInput}
              placeholder="(xxx) xxx-xxxx"
              type="tel"
              marginBottom={8}
              onChange={e => setPhone(e.target.value)}
              value={phone}
              required
              format="(###) ###-####"
              mask="_"
            />
          </FormBox>
          {/* Buttons Row 1*/}
          <Button
            submit={true}
            disabled={loading}
            className={css`
              margin-top: 55px;
              margin-bottom: 12px;
              width: 100%;
            `}
          >
            Save
          </Button>

          {/* Buttons row 2 */}
          <Button
            link="payment-info"
            className={css`
              margin-bottom: 12px;
              width: 100%;
            `}
          >
            Edit Payment Method
          </Button>
          {/* Buttons Row 3*/}
          <div
            className={css`
              width: 100%;
              display: flex;
            `}
          >
            <div
              className={css`
                width: 50%;
                margin-right: 12px;
              `}
            >
              <Button
                onClick={() => setPasswordModalOpen(true)}
                className={css`
                  height: 100%;
                  width: 100%;
                  margin: 0;
                  color: ${colors.white};
                  background-color: #ea7612;
                `}
              >
                Reset Password
              </Button>
            </div>
            <div
              className={css`
                width: 50%;
              `}
            >
              {user.subscriptionActive ? (
                <Button
                  link="/unsubscribe"
                  className={css`
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    color: ${colors.white};
                    background-color: #ea7612;
                  `}
                >
                  Deactivate Subscription
                </Button>
              ) : (
                <Button
                  link="/subscribe"
                  className={css`
                    height: 100%;
                    width: 100%;
                    margin: 0;
                  `}
                >
                  Activate Subscription
                </Button>
              )}
            </div>
          </div>
        </Form>
      </div>

      {error && <Message error>{error}</Message>}
      {/* Footer */}
      <div
        className={css`
          overflow: hidden;
          padding-top: 85px;
        `}
      >
        <div
          className={css`
            position: relative;
            background-color: ${colors.purple};
            height: 88px;
          `}
        >
          <div
            className={css`
              position: absolute;
              bottom: -45px;
              left: -45px;
              transform: rotate(45deg);
              width: 182px;
            `}
          >
            <Animation lottieFile={LeftPoint} />
          </div>
          <div
            className={css`
              width: 105px;
              height: 42px;
            `}
          >
            <TextBubble
              className={css`
                transform: translate(130px, 10px);
              `}
            >
              <P size="xxs" weight="bold">
                Lookin' good Llama!
              </P>
            </TextBubble>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        isOpen={passwordModalOpen}
        close={() => {
          setPasswordModalOpen(false);
        }}
      />
    </Layout>
  );
};
