import React, { useContext, useEffect, useState } from 'react';
import { ToastContext } from 'context/Toast.context';
import { Button, Link } from 'components';
import { getCharities } from 'api/charities.api';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const [charities, setCharities] = useState([]);

  useEffect(() => {
    async function async() {
      try {
        const res = await getCharities({ withArchived: true });
        setCharities(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage]);

  return (
    <div>
      <Button link="/admin/charities/new">Create Charity</Button>
      {charities.map(charity => (
        <div key={charity.id}>
          <Link to={`/admin/charities/${charity.id}`}>
            <h2>{charity.name}</h2>
          </Link>
          <p>
            Description: {charity.description}
            <br />
            Website: {charity.websiteUrl}
          </p>
        </div>
      ))}
    </div>
  );
};
