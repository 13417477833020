import React, { useRef, useState, useContext } from 'react';
import { Message, Button } from 'components';
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';

import { css } from 'emotion';
import { colors } from 'helpers';
import defaultProfile from './profile.png';
import { editUserProfile } from 'api/users.api';

export default ({ src, editButton, minWidth, minHeight, ...props }) => {
  const fileInputRef = useRef();
  const [base64, setBase64] = useState(null);
  const { setUser } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);

  const readFile = e => {
    if (!e.target.files || !e.target.files[0]) return;

    const reader = new FileReader();
    reader.onloadend = () => setBase64(reader.result);
    reader.readAsDataURL(e.target.files[0]);

    setProfilePicture(e.target.files[0]);
  };

  const updateProfilePicture = async () => {
    setError('');
    setLoading(true);

    const data = new FormData();
    data.append('profile', profilePicture);

    try {
      const res = await editUserProfile(data);
      setProfilePicture(null);
      setUser(res);
      pushMessage.success('Profile picture updated successfully.');
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  return (
    <div
      {...props}
      className={css`
        ${props.className};

        position: relative;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
      `}
    >
      <div
        className={css`
          width: 100%;
          height: 100%;
          min-width: ${minWidth};
          min-height: ${minWidth};
          background-image: url(${base64 || src || defaultProfile});
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        `}
      >
        <input
          type="file"
          ref={fileInputRef}
          className={css`
            display: none;
          `}
          accept="image/png, image/gif, image/jpeg, image/webp"
          onChange={readFile}
        />
      </div>
      {editButton && (
        <Button
          size="lg"
          disabled={loading}
          onClick={() =>
            editButton &&
            (profilePicture
              ? updateProfilePicture()
              : fileInputRef.current.click())
          }
          className={css`
            color: ${profilePicture ? colors.white : colors.black};
            background-color: ${profilePicture ? colors.green : colors.yellow};
            margin: auto;
            margin-top: 10px;
          `}
        >
          {profilePicture ? 'Save Photo' : 'Change Photo'}
        </Button>
      )}
      {error && <Message error>{error}</Message>}
    </div>
  );
};
