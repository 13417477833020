import http from 'helpers/http.helper';

export const getPledgeDrives = ({
  active = true,
  search,
  charityId,
  pledgeDriveCategoryIds,
  charityCategoryIds,
  sortBy = 'progress',
  sortDirection = 'desc',
  withArchived = false,
  featured = false,
  userId,
  resultsPerPage = 10
} = {}) => {
  return http().get('/pledge-drives', {
    params: {
      active,
      search,
      pledgeDriveCategoryIds,
      charityId,
      charityCategoryIds,
      sortBy,
      sortDirection,
      withArchived,
      userId,
      featured,
      resultsPerPage
    }
  });
};

export const getPledgeDrive = ({ pledgeDriveId }) => {
  return http().get(`/pledge-drives/${pledgeDriveId}`);
};

export const createPledgeDrive = ({ pledgeDriveInfo }) => {
  return http().post('/pledge-drives', pledgeDriveInfo);
};

export const updatePledgeDrive = ({ pledgeDriveId, data }) => {
  return http().put(`/pledge-drives/${pledgeDriveId}`, data);
};

export const uploadPledgeDrivePhoto = ({ pledgeDrive, data }) => {
  return http().post(`/pledge-drives/${pledgeDrive.id}/images`, data);
};

export const updatePledgeDrivePhotos = ({ pledgeDrive, updated }) => {
  return http().put(`/pledge-drives/${pledgeDrive.id}`, { imageUrls: updated });
};

export const acceptPrize = ({ id }) => {
  return http().post(`/pledge-drives/${id}/accept`, {
    pledgeDriveId: id
  });
};
