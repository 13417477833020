import React from 'react';
import { css } from 'emotion';
import WinnerSection from './components/WinnerSection.component';
import InfoSection from './components/InfoSection.component';
import CharitySection from './components/CharitySection.component';
import MediaSection from './components/MediaSection.component';
import { colors, media } from 'helpers';

export default function CompletedDrive({ info }, ...props) {
  const moneyRaised = info.retailValue * 0.33;

  return (
    <div
      className={css`
        width: 100%;
        @media ${media.layout.landscapeDesktop} {
          width: 45%;
          margin: 20px 20px;
          border: solid 2px ${colors.black};
          border-radius: 12px;
        }
      `}
    >
      <WinnerSection
        itemAcceptedAt={info.itemAcceptedAt}
        pledgeDriveName={info.name}
        winnerImage={info.winnerProfilePictureUrl}
        winnerName={info.winnerName}
        pledgeDriveId={info.id}
        winnerId={info.winnerId}
      />
      <MediaSection images={info.imageUrls} />
      <CharitySection id={info.charityId} charityName={info.charityName} />
      <InfoSection charityName={info.charityName} moneyRaised={moneyRaised} />
    </div>
  );
}
