import React from 'react';

import { css } from 'emotion';
import { colors, effect } from 'helpers';

export default ({ children, className }) => {
  return (
    <div
      className={css`
        background-color: ${colors.yellow};
        height: 50px;
        padding: 5px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        ${className} :before {
          content: '';
          height: 10px;
          width: 60px;
          border-bottom-left-radius: 12px;
          border-left: 20px solid ${colors.yellow};
          transform: translate(-12px, -5px);
        }

        ${effect.boxShadowSm}
      `}
    >
      {children}
    </div>
  );
};
