import React from 'react';
import { css } from 'emotion';
import { colors, fonts, media, effect } from 'helpers';
import { GoChevronRight as ChevronRightIcon } from 'react-icons/go';

export default function({ text, open, handleClick, className }) {
  return (
    <button
      type="button"
      className={css`
        appearance: none;
        border: none;
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        background-color: ${colors.white};
        font-size: ${fonts.size.xs};
        font-family: ${fonts.fontFamily.secondary};
        border-radius: 12px;
        ${effect.boxShadowSm};
        box-shadow: 0px 3px 6px ${colors.black};
        padding: 0 15px;
        margin: 15px 0;
        z-index: 1;
        @media (max-width: ${media.screen.sm}) {
          height: 32px;

          padding: 0 8px;
          margin: 8px 0;
          font-size: ${fonts.size.xxs};
        }
        &:hover {
          cursor: pointer;
        }
        ${className};
      `}
      onClick={handleClick}
    >
      <span>{text}</span>
      <ChevronRightIcon
        size={25}
        className={css`
          transform: ${open && 'rotate(90deg)'};
        `}
      />
    </button>
  );
}
