import React from 'react';
import { css } from 'emotion';
import { colors, fonts, string } from 'helpers';

export default function({ item, field, filters, handleFilter }) {
  return (
    <li
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: ${fonts.size.xs};
        font-family: ${fonts.fontFamily.secondary};
        border-bottom: 1px solid ${colors.black};
        margin-top: 26px;
        padding-bottom: 8px;
        padding-left: 8px;
        padding-right: 8px;
      `}
    >
      <span
        className={css`
          margin-right: 6px;
          width: 100%;
        `}
      >
        {string.capitalize(item.name)}
      </span>
      <input
        type="checkbox"
        name={item.name}
        onChange={() => handleFilter(field, item.id)}
        checked={filters[field] ? filters[field].includes(item.id) : false}
        className={css`
          width: 15px;
          height: 15px;
        `}
      />
    </li>
  );
}
