import React, { useContext, useState, useEffect, forwardRef } from 'react';
import { css } from 'emotion';
import { colors, media, string } from 'helpers';
import { H, CompletionBar } from 'components';
import PledgeDriveButtonComponent from './components/PledgeDriveButton.component';
import InfoBoxComponent from './components/InfoBox.component';

import { getPledges, placePledge } from 'api/pledges.api';

import { ToastContext } from 'context/Toast.context';
import { PledgesContext } from 'context/Pledges.context';
import { UserContext } from 'context/User.context';

import Dancing from 'assets/Lottie/Dancing.json';

import { GoArrowRight } from 'react-icons/go';
import OnPledgeAnimation from './components/OnPledgeAnimation.component';
import { getPledgeDrive } from 'api/pledgeDrives.api';

export default forwardRef(({ pledgeDrive, onUsePledge, ...props }, ref) => {
  const [pledgeDriveData, setPledgeDriveData] = useState(pledgeDrive);
  const completion = pledgeDriveData.progress * 100;
  const { pledges, setPledges } = useContext(PledgesContext);
  const { user } = useContext(UserContext);

  const { pushMessage } = useContext(ToastContext);
  const [showAnimation, setShowAnimation] = useState(false);

  const backgroundImg = pledgeDriveData?.imageUrls[0];

  useEffect(() => {
    if (showAnimation === true) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  async function handleUsePledge() {
    async function updatePledges() {
      try {
        const pledges = await getPledges({ userId: user?.id });
        setPledges(pledges);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    // if passed in as a prop, use the function in the prop
    if (onUsePledge) {
      onUsePledge();
    } else {
      try {
        const nextAvailablePledge = pledges.find(t => !t.pledgeDriveId);
        if (!nextAvailablePledge) throw new Error('No pledges available.');
        await placePledge({
          nextAvailablePledge: nextAvailablePledge.id,
          pledgeDriveId: pledgeDriveData.id
        });
        setShowAnimation(true);
        updatePledges();
        fetchPledgeDrive();
      } catch (e) {
        setShowAnimation(false);
        pushMessage.error(e.message);
      }
    }
  }

  async function fetchPledgeDrive() {
    try {
      const res = await getPledgeDrive({ pledgeDriveId: pledgeDriveData.id });
      setPledgeDriveData(res);
    } catch (e) {
      pushMessage.error(e.message);
    }
  }

  return (
    <div
      ref={ref}
      {...props}
      className={css`
        width: 100%;
        height: 292px;
        @media ${media.layout.landscapeDesktop &&
            media.layout.landscapeMobile} {
          width: 48%;
          margin: 12px;
          border-radius: 12px;
        }
        ${props.className}
      `}
    >
      {!!backgroundImg && (
        <div
          className={css`
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.8) 0%,
                rgba(0, 0, 0, 0.5) 5%,
                rgba(255, 255, 255, 0) 30%,
                rgba(255, 255, 255, 0) 60%,
                rgba(0, 0, 0, 0.5) 95%,
                rgba(0, 0, 0, 0.8) 100%
              ),
              url(${backgroundImg});
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            width: 100%;
            height: 292px;
            position: relative;
            padding: 7px 10px;
          `}
        >
          {/* Title */}
          <div
            className={css`
              position: absolute;
              top: 10px;
              left: 0;
              width: 100%;
            `}
          >
            <H
              level="3"
              size="sm"
              color="white"
              fontFamily="secondary"
              weight="bold"
            >
              {pledgeDriveData?.name}
            </H>
            {!!pledgeDriveData?.charityLogo && (
              <img
                src={pledgeDriveData?.charityLogo}
                alt={`${pledgeDriveData?.charityName}`}
                className={css`
                  max-width: 100px;
                  max-height: 100px;
                  margin-left: 10px;
                  opacity: 0.8;
                `}
              />
            )}
          </div>
          {/* Info */}
          <div
            className={css`
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 100%;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                @media (max-width: ${media.screen.sm}) {
                  justify-content: start;
                  flex-direction: column-reverse;
                }
              `}
            >
              {/* Info Badges */}
              {user?.subscriptionActive ? (
                <div
                  className={css`
                    display: flex;
                    margin-bottom: 6px;
                    margin-left: 6px;
                  `}
                >
                  <InfoBoxComponent
                    className={css`
                      margin-right: 4px;
                    `}
                  >
                    {`${string.formatMoney(
                      pledgeDriveData?.pledgeCount * 0.67
                    )} / ${
                      string
                        .formatMoney(pledgeDriveData?.pledgeValue * 0.67)
                        .split('.')[0]
                    } donated to charity!`}
                  </InfoBoxComponent>
                </div>
              ) : null}
              {/* Buttons */}
              <div
                className={css`
                  display: flex;
                  margin-bottom: 6px;
                  margin-right: 6px;
                  @media (max-width: ${media.screen.sm}) {
                    margin-left: 6px;
                  }
                `}
              >
                <PledgeDriveButtonComponent
                  link={`pledge-drives/${pledgeDriveData.id}`}
                  className={css`
                    margin-right: 6px;
                  `}
                >
                  Go
                  <GoArrowRight
                    size={14}
                    className={css`
                      margin-left: 2px;
                      vertical-align: middle;
                      color: ${colors.white};
                    `}
                  />
                </PledgeDriveButtonComponent>
                <PledgeDriveButtonComponent onClick={handleUsePledge}>
                  Make A Pledge
                </PledgeDriveButtonComponent>
              </div>
            </div>
            <CompletionBar completion={completion} />
          </div>
          <OnPledgeAnimation showAnimation={showAnimation} file={Dancing} />
        </div>
      )}
    </div>
  );
});
