import { css } from 'emotion';
import { Link } from 'react-router-dom';

import React from 'react';
import { fonts, colors } from 'helpers';

export default ({ children, to, color, ...props }) => {
  return (
    <Link
      {...props}
      to={to}
      className={css`
        display: flex;
        justify-content: flex-start;
        text-decoration: none;
        font-family: ${fonts.fontFamily.secondary};
        font-weight: ${fonts.fontWeight.black};
        font-size: 28px;
        color: ${color ? `${colors[color]}` : 'white'};
        padding: 16px;
        padding-left: 34px;
        :hover {
          scale: 1.01;
        }

        @media screen and (max-height: 680px) {
          font-size: 18px;
          color: ${color ? `${colors[color]}` : 'white'};
          padding: 12px;
          padding-left: 24px;
        }
      `}
    >
      {children}
    </Link>
  );
};
