import React from 'react';
import { css } from 'emotion';
import { colors, fonts, media } from 'helpers';
import { P } from 'components';
export default ({
  handleClick,
  color = `${colors.yellow}`,
  text,
  children
}) => {
  return (
    <div
      onClick={handleClick}
      className={css`
        flex: 1 1 0;
        background-color: ${color};
        display: flex;
        align-items: center;

        border-radius: 12px;
        margin-right: 26px;
        &:last-of-type {
          margin-right: 0;
        }
        @media (min-width: ${media.screen.lg}) {
          justify-content: center;
        }
        @media (max-width: ${media.screen.md}) {
          margin-right: 10px;
        }
        @media (max-width: ${media.screen.sm}) {
          margin-right: 5px;
        }
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      `}
    >
      {text ? (
        <P
          weight="bold"
          className={css`
            text-align: center;
            @media (max-width: ${media.screen.lg}) {
              font-size: ${fonts.size.md};
            }
            @media (max-width: ${media.screen.md}) {
              font-size: ${fonts.size.sm};
            }
            @media (max-width: ${media.screen.sm}) {
              padding: 10px;
              font-size: ${fonts.size.xs};
            }
          `}
        >
          {text}
        </P>
      ) : (
        children
      )}
    </div>
  );
};
