import React, { useContext, useEffect, useState } from 'react';

//api
import {
  getPledgeDriveCategories,
  createPledgeDriveCategory
} from 'api/pledgeDriveCategories.api';

//context
import { ToastContext } from 'context/Toast.context';

//components
import { Button, Input, Label, P } from 'components';
import { Category } from '../components/Category.component';

//helper
import { css } from 'emotion';

export default props => {
  const { pushMessage } = useContext(ToastContext);

  const [pledgeDriveCategories, setPledgeDriveCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [loading, setLoading] = useState(false);

  //get pledge drive categories list
  useEffect(() => {
    const setCategories = async e => {
      try {
        const res = await getPledgeDriveCategories({ withArchived: true });
        setPledgeDriveCategories(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    };
    setCategories();
  }, [pushMessage]);

  //create pledge drive category
  const create = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await createPledgeDriveCategory({
        name: newCategoryName
      });
      setPledgeDriveCategories(prev => [...prev, res]);
      pushMessage.success('Category Created');
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const update = updated => {
    setPledgeDriveCategories(
      pledgeDriveCategories.map(category =>
        category.id === updated.id ? updated : category
      )
    );
  };

  return (
    <div>
      <P
        className={css`
          margin: 20px;
        `}
      >
        Be careful, Pledge Drive Categories added here will show in the search
        filter.
      </P>

      <form onSubmit={create}>
        <Label>New Category</Label>
        <Input
          marginBottom={8}
          onChange={e => setNewCategoryName(e.target.value)}
          value={newCategoryName}
          required
        />
        <Button submit={true} disabled={loading}>
          Create
        </Button>
      </form>

      {pledgeDriveCategories?.map(category => (
        <Category
          categoryName="pledge-drive-categories"
          category={category}
          onUpdate={update}
          key={category.id}
        />
      ))}
    </div>
  );
};
