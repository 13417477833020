import React, { useState, useEffect, useContext } from 'react';
//api
import { getCharityCategories } from 'api/charityCategories.api';

//context
import { ToastContext } from 'context/Toast.context';

import { Button, Input, Label, Message, TextArea, Select } from 'components';
import { useHistory } from 'react-router-dom';
import { css } from 'emotion';
import { createCharity } from 'api/charities.api';
export default props => {
  //dom
  const history = useHistory();
  //context
  const [error, setError] = useState('');
  const { pushMessage } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tagline, setTagline] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [donationUrl, setDonationUrl] = useState('');
  const [charityCategories, setCharityCategories] = useState([]);
  const [charityCategoryId, setCharityCategoryId] = useState('');

  const submit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await createCharity({
        charityInfo: {
          name,
          description,
          tagline,
          websiteUrl,
          charityCategoryId
        }
      });
      history.push(`/admin/charities/${res.id}`);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };
  //get charity categories list
  useEffect(() => {
    const setStat = async e => {
      try {
        const res = await getCharityCategories();
        setCharityCategories(res);
      } catch (e) {}
    };
    setStat();
  }, [pushMessage]);
  return (
    <form onSubmit={submit}>
      {error && <Message error>{error}</Message>}
      <Label>Name</Label>
      <Input
        marginBottom={8}
        onChange={e => setName(e.target.value)}
        value={name}
        required
      />
      <Label>Description</Label>
      <TextArea
        marginBottom={8}
        onChange={e => setDescription(e.target.value)}
        value={description}
      />
      <Label>Tagline</Label>
      <TextArea
        marginBottom={8}
        onChange={e => setTagline(e.target.value)}
        value={tagline}
      />
      <Label>Website URL</Label>
      <Input
        marginBottom={8}
        onChange={e => setWebsiteUrl(e.target.value)}
        value={websiteUrl}
      />
      <Label>DonationUrl URL</Label>
      <Input
        marginBottom={8}
        onChange={e => setDonationUrl(e.target.value)}
        value={donationUrl}
      />
      <Label>Charity Category</Label>
      <Select
        marginBottom={8}
        value={charityCategoryId}
        onChange={e => setCharityCategoryId(e.target.value)}
        options={charityCategories.map(charityCategory => ({
          value: charityCategory.id,
          label: charityCategory.name
        }))}
        required
      />

      <Button
        submit={true}
        disabled={loading}
        className={css`
          margin-bottom: 10px;
        `}
      >
        Create Charity
      </Button>
    </form>
  );
};
