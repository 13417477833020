import React from 'react';
import { css } from 'emotion';
import { Animation, Button } from 'components';
import NotFoundAnimation from 'assets/Lottie/404Sorry.json';

export default () => {
  return (
    <div
      className={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <Animation
        autoplay={true}
        loop={true}
        lottieFile={NotFoundAnimation}
        className={css`
          margin-bottom: 24px;
          max-height: 80%;
          max-width: 80%;
        `}
      />

      <Button size="lg" link="/">
        Home
      </Button>
    </div>
  );
};
