import React from 'react';
import { css } from 'emotion';
import Step1 from 'assets/PWA/01_share_button.jpg';
import Step2 from 'assets/PWA/02_add_to_home.jpg';
import Step3 from 'assets/PWA/03_save_pledgellama.jpg';
import Step4 from 'assets/PWA/04_pledgellama_added.jpg';

import { Layout, H, FormBox, Link } from 'components';

import PWAStepComponent from './components/PWAStep.component';

export default props => {
  return (
    <Layout backTitle="How to Install PWA on iOS" nav={false}>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          max-width: 90%;
          margin: 20px auto;
        `}
      >
        <FormBox>
          <H level="2">
            Installing PledgeLlama as a PWA is easy, and will allow you to use
            Pledgellama just like any other app from the app store!
          </H>
          <br />
          <br />
          <PWAStepComponent
            stepNo={'1'}
            imgSrc={Step1}
            imgAlt="Hit the share button"
            text={
              'A PWA can only be installed on iOS using the Safari web browser. Navigate to PledgeLlama.com in the Safari browser and hit the "Share" button on the bottom navigation bar'
            }
          />
          <PWAStepComponent
            stepNo={'2'}
            imgSrc={Step2}
            imgAlt={'Select "Add to Home" button'}
            text={'Select "Add to Home" button'}
          />
          <PWAStepComponent
            stepNo={'3'}
            imgSrc={Step3}
            imgAlt={'Press Add'}
            text={'Save PledgeLlama to your Home Screen by pressing "Add"'}
          />
          <PWAStepComponent
            stepNo={'4'}
            imgSrc={Step4}
            imgAlt={'Press Add'}
            text={
              'After adding PledgeLlama to your home screen, you can use PledgeLlama as a regular iOS app, without having to navigate to the site in your browser!'
            }
          />
        </FormBox>
      </div>
      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
        `}
      >
        <Link
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Back to Top ^
        </Link>
      </div>
    </Layout>
  );
};
