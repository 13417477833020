import React, { useState, useEffect, useContext } from 'react';
import FilterList from '../FilterList.component';
import { ToastContext } from 'context/Toast.context';
import { UserContext } from 'context/User.context';

import { getCharityCategories } from 'api/charityCategories.api';
import { getPledgeDriveCategories } from 'api/pledgeDriveCategories.api';

export default function FilterListPledgedrives({ filters, handleFilter }) {
  const [charityCategoryOpen, setCharityCategoryOpen] = useState(false);
  const [pledgeDriveCategoryOpen, setPledgeDriveCategoryOpen] = useState(false);
  const [charityCategories, setCharityCategories] = useState([]);
  const [pledgeDriveCategories, setPledgeDriveCategories] = useState([]);
  const [userPledgeDrivesOpen, setUserPledgeDrivesOpen] = useState(false);

  const { pushMessage } = useContext(ToastContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function asyncFunc() {
      try {
        const res = await getCharityCategories();
        setCharityCategories(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    asyncFunc();
  }, [pushMessage]);

  useEffect(() => {
    async function asyncFunc() {
      try {
        const res = await getPledgeDriveCategories();
        setPledgeDriveCategories(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    asyncFunc();
  }, [pushMessage]);

  return (
    <>
      <FilterList
        text="Charity Category"
        field="charityCategoryId"
        open={charityCategoryOpen}
        handleClick={() => setCharityCategoryOpen(!charityCategoryOpen)}
        items={charityCategories}
        filters={filters}
        handleFilter={handleFilter}
      />
      <FilterList
        text="Pledge Drive Category"
        field="pledgeDriveCategoryId"
        open={pledgeDriveCategoryOpen}
        handleClick={() => setPledgeDriveCategoryOpen(!pledgeDriveCategoryOpen)}
        items={pledgeDriveCategories}
        filters={filters}
        handleFilter={handleFilter}
      />

      <FilterList
        text="My Pledge Drives"
        field="userId"
        open={userPledgeDrivesOpen}
        handleClick={() => setUserPledgeDrivesOpen(!userPledgeDrivesOpen)}
        items={[{ name: 'Include only my Pledge Drives', id: user.id }]}
        filters={filters}
        handleFilter={handleFilter}
      />
    </>
  );
}
