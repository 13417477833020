import React, { useEffect, useState, useContext } from 'react';

import { Layout } from 'components';

import { Link, useParams } from 'react-router-dom';

import { getPledgeDrive } from 'api/pledgeDrives.api';
import { UserContext } from 'context/User.context';

import { ToastContext } from 'context/Toast.context';

import { css } from 'emotion';
import { P, H } from 'components';
import { colors, effect, fonts, media, string } from 'helpers';
import { ImageShuffle } from 'components';
import InfoSquareComponent from './components/InfoSquare.component';
import { getPledges, placePledge } from 'api/pledges.api';
import { PledgesContext } from 'context/Pledges.context';

import Dancing from 'assets/Lottie/Dancing.json';
import OnPledgeAnimation from './components/OnPledgeAnimation.component';
import { LoginModal } from 'modals';
import ModalShareComponent from './components/ModalShare.component';
import ClickableAreaComponent from './components/ClickableArea.component';

import { FaShareSquare } from 'react-icons/fa';

export default () => {
  const { user } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const { id } = useParams();

  const [pledgeDrive, setPledgeDrive] = useState();
  const [userPledgeCount, setUserPledgeCount] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  useEffect(() => {
    if (showAnimation === true) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  const { pledges, setPledges } = useContext(PledgesContext);
  async function handleUsePledge() {
    async function updatePledges() {
      try {
        const pledges = await getPledges({ userId: user?.id });
        setPledges(pledges);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }

    try {
      const nextAvailablePledge = pledges.find(t => !t.pledgeDriveId);
      if (!user) return;
      if (!nextAvailablePledge) throw new Error('No pledges available.');
      await placePledge({
        nextAvailablePledge: nextAvailablePledge.id,
        pledgeDriveId: pledgeDrive.id
      });
      setShowAnimation(true);
      updatePledges();
    } catch (e) {
      setShowAnimation(false);
      pushMessage.error(e.message);
    }
  }

  useEffect(() => {
    async function fetchPledgeDrive() {
      try {
        const res = await getPledgeDrive({ pledgeDriveId: id });
        setPledgeDrive(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    if (user) fetchPledgeDrive();
  }, [id, pushMessage, pledges, user]);

  useEffect(() => {
    async function async() {
      const pledgeCount =
        pledges?.length > 0 &&
        pledges?.filter(t => t.pledgeDriveId === pledgeDrive?.id);
      setUserPledgeCount(pledgeCount.length);
    }
    if (user) async();
  }, [pledgeDrive, user, userPledgeCount, pledges]);

  return (
    <Layout
      nav={false}
      backTitle={`${
        Boolean(pledges && pledges?.length > 0)
          ? pledges.filter(t => !t.pledgeDriveId).length
          : '0 '
      } pledges remaining`}
      className={css`
        overflow: hidden;
        display: flex;
        justify-content: space-between;
      `}
    >
      {pledgeDrive ? (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            justify-content: space-around;
            width: 100%;
          `}
        >
          {/* Header */}
          <div
            className={css`
              margin: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 88%;
              max-width: calc(600px - 80px); // calc(width - button size * 2)
              @media (min-width: ${media.screen.lg}) {
                max-width: 75%;
              }
            `}
          >
            <InfoSquareComponent
              title={'Pledge Total'}
              stat={pledgeDrive?.pledgeCount}
            />
            <InfoSquareComponent
              title={'Your Pledges'}
              stat={userPledgeCount}
            />
            <InfoSquareComponent
              onClick={() => setShareModal(true)}
              className={css`
                background-color: ${colors.yellow};
                color: ${colors.black};
                &:hover {
                  cursor: pointer;
                  opacity: 0.8;
                }
              `}
              title={'Share'}
              stat={<FaShareSquare />}
            />
          </div>

          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              @media (min-width: ${media.screen.lg}) {
                flex-direction: row;
                margin: 32px;
                width: 75%;
              }
            `}
          >
            {/* Images */}
            {!!pledgeDrive.imageUrls && (
              <div
                className={css`
                  display: flex;
                  justify-content: center;
                  max-width: 600px;

                  @media (min-width: ${media.screen.lg}) {
                    max-width: 900px;
                  }
                `}
              >
                <ImageShuffle
                  images={pledgeDrive.imageUrls}
                  raised={`${string.formatMoney(
                    pledgeDrive?.pledgeCount * 0.67
                  )} / ${
                    string
                      .formatMoney(pledgeDrive?.pledgeValue * 0.67)
                      .split('.')[0]
                  } donated to charity!`}
                />
              </div>
            )}
            {/* Info  */}

            <div
              className={css`
                margin: 10px 8px 8px 8px;
                padding: 8px;
                min-height: 172px;
                text-align: center;
                width: 88%;
                border: solid 1px ${colors.tBlack};
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                box-shadow: 0px 1px 1px ${colors.tBlack};
                max-width: calc(600px - 80px); // calc(width - button size * 2)

                @media (min-width: ${media.screen.lg}) {
                  min-width: 33%;
                  align-self: flex-end;
                  margin: 0;
                }
              `}
            >
              <H
                level="1"
                size="md"
                weight="black"
                fontFamily="secondary"
                color="black"
                className={css`
                  ${effect.boxShadowXsm}
                `}
              >
                {pledgeDrive.name}
              </H>
              <P size="sm">{pledgeDrive.description}</P>
            </div>
          </div>
          <OnPledgeAnimation showAnimation={showAnimation} file={Dancing} />
          {/* Buttons  */}
          <div
            className={css`
              display: flex;
              width: 88%;
              height: 70px;
              margin-bottom: 30px;
              max-width: calc(600px - 80px); // calc(width - button size * 2)
              @media (min-width: ${media.screen.lg}) {
                max-width: 75%;

                margin-top: 10px;
              }
            `}
          >
            <ClickableAreaComponent>
              <Link
                to={`/charities/${pledgeDrive.charityId}`}
                className={css`
                  text-decoration: none;

                  padding: 12px;
                  &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                  }
                `}
              >
                <P size="xxs">Supporting</P>
                <div
                  className={css`
                    margin-top: 2px;
                    margin-bottom: 12px;

                    @media (max-width: ${media.screen.md}) {
                      max-width: 150px;
                    }
                  `}
                >
                  <P
                    size="sm"
                    weight="bold"
                    className={css`
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      @media (max-width: ${media.screen.sm}) {
                        font-size: ${fonts.size.xs};
                      }
                    `}
                  >
                    {pledgeDrive.charityName}
                  </P>
                </div>

                <P
                  size="xxs"
                  className={css`
                    margin-bottom: 2px;
                  `}
                >
                  Learn More
                </P>
              </Link>
            </ClickableAreaComponent>
            <ClickableAreaComponent
              handleClick={handleUsePledge}
              text="Make a Pledge"
            />
          </div>
          {/* Fine print */}
          <P
            size="xxs"
            color="white"
            className={css`
              position: fixed;
              bottom: 0;
              display: flex;
              background-color: ${colors.purple};
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 20px;
              text-align: right;
            `}
          >
            no purchase necessary to enter
          </P>
          {/* </div> */}
          <ModalShareComponent
            link={window.location.href}
            isOpen={shareModal}
            close={() => setShareModal(false)}
          />
        </div>
      ) : null}
      {!user && <LoginModal isOpen={true} />}
    </Layout>
  );
};
