import React from 'react';
import { css } from 'emotion';
import { colors, fonts, media } from 'helpers';
import { Link } from 'react-router-dom';

export default ({ link, className, ...props }) => {
  const Button = () => (
    <button
      className={css`
        display: flex;
        justify-content: center;
        align-content: center;
        color: ${colors.white};
        background-color: transparent;
        border: 1px solid ${colors.white};
        border-radius: 6px;
        padding: 5px;
        font-size: ${fonts.size.sm};
        font-family: ${fonts.fontFamily.primary};

        &:hover {
          opacity: 0.8;
          cursor: pointer;
        }

        ${className}

        @media (max-width : ${media.screen.sm}) {
          font-size: ${fonts.size.xs};
          padding: 3px;
        } 
      `}
      {...props}
    >
      <em>{props.children}</em>
    </button>
  );

  return link ? (
    <Link
      to={link}
      className={css`
        text-decoration: none;
      `}
    >
      <Button />
    </Link>
  ) : (
    <Button />
  );
};
