import React, { useContext } from 'react';
import { Button, Avatar } from 'components';

import { css } from 'emotion';
import ProfileInfoComponent from './components/ProfileInfo.component';
import { string, media } from 'helpers';
import { BsGear } from 'react-icons/bs';
import { UserContext } from 'context/User.context';

export default props => {
  const { user } = useContext(UserContext);

  return (
    <div
      className={css`
        display: flex;
        box-shadow: 2px 2px 2px rgba(100, 100, 100, 0.6),
          -2px -2px 2px rgba(100, 100, 100, 0.6);
      `}
    >
      <div
        className={css`
          flex: 1 1 0;
          @media (max-width: ${media.screen.sm}) {
            flex: 1 1 0;
          }
        `}
      >
        <Avatar src={user.profilePictureUrl} />
      </div>
      <div
        className={css`
          flex: 2 2 0;
          @media (max-width: ${media.screen.sm}) {
            flex: 1 1 0;
          }
          margin: 18px;
          display: flex;
          flex-direction: column;
        `}
      >
        <ul
          className={css`
            list-style: none;
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 0;
          `}
        >
          <li>
            <ProfileInfoComponent>{user.name}</ProfileInfoComponent>
          </li>
          <li>
            <ProfileInfoComponent>{user.email}</ProfileInfoComponent>
          </li>
          <li>
            <ProfileInfoComponent>
              {string.formatPhone(user.phone)}
            </ProfileInfoComponent>
          </li>
        </ul>
        <Button
          size="md"
          link={`/edit-account`}
          className={css`
            width: 75%;
            height: auto;
            align-self: flex-end;
          `}
        >
          Manage Profile
          <BsGear
            size={18}
            className={css`
              margin-left: 14px;
            `}
          />
        </Button>
      </div>
    </div>
  );
};
