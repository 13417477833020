import React from 'react';
import { css } from 'emotion';

import { colors, media } from 'helpers';

import { H, P, Button } from 'components';

export default ({ charity }) => {
  return (
    // charity wrapper
    <div
      className={css`
        display: flex;
        border: 1px solid ${colors.black};
        width: 100%;
        height: 190px;
        margin-bottom: 5px;
        &:last-of-type {
          margin-bottom: 0;
        }
      `}
    >
      {/* Header Info */}
      <div
        className={css`
          flex: 1.5 1 0;
          background-color: ${colors.black};
          padding: 5px 16px;
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
          `}
        >
          <H
            level="3"
            size="md"
            color="white"
            weight="bold"
            fontFamily="secondary"
            className={css`
              @media (max-width: ${media.screen.sm}) {
                font-size: 16px;
              }
            `}
          >
            {charity.name}
          </H>
          <P
            size="sm"
            color="white"
            fontFamily="secondary"
            className={css`
              @media (max-width: ${media.screen.sm}) {
                font-size: 14px;
              }
            `}
          >
            A non-profit that hopes to aid people with unique medical struggles.
          </P>

          <Button
            boxShadow={true}
            link={`charities/${charity.id}`}
            className={css`
              @media (max-width: ${media.screen.sm}) {
                padding: 5px;
              }
            `}
          >
            <span
              className={css`
                margin-right: 10px;
                @media (max-width: ${media.screen.sm}) {
                  font-size: 10px;
                }
              `}
            >
              View More
            </span>
          </Button>
        </div>
      </div>
      {/* Header Image */}
      <div
        className={css`
          flex: 1 1 0;
          background-color: ${colors.white};
          border: 1px solid ${colors.black};
        `}
      >
        <img
          src={charity.logoUrl}
          alt={charity.name}
          className={css`
            width: 100%;
            height: 100%;
            object-fit: contain;
            padding-left: 5px;
            padding-right: 5px;
          `}
        />
      </div>
    </div>
  );
};
