import React, { useContext, useRef, useState } from 'react';
import { ToastContext } from 'context/Toast.context';
import { css } from 'emotion';
import { colors } from 'helpers';
import { Button } from 'components';
import {
  updatePledgeDrivePhotos,
  uploadPledgeDrivePhoto
} from 'api/pledgeDrives.api';

export default props => {
  const { pledgeDrive, setPledgeDrive } = props;
  const { pushMessage } = useContext(ToastContext);
  const fileInputRef = useRef();
  const [loading, setLoading] = useState(false);

  const upload = async e => {
    try {
      if (!e.target.files) return;
      setLoading(true);

      for (let file of e.target.files) {
        const data = new FormData();
        data.append('image', file);
        const res = await uploadPledgeDrivePhoto({ pledgeDrive, data });
        setPledgeDrive(res);
      }
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const updatePhotoList = async updated => {
    setLoading(true);

    try {
      const res = await updatePledgeDrivePhotos({ pledgeDrive, updated });
      setPledgeDrive(res);
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const moveRight = idx => () => {
    if (idx >= pledgeDrive.imageUrls.length - 1) return;
    const copy = [...pledgeDrive.imageUrls];
    copy.splice(idx + 1, 0, copy.splice(idx, 1)[0]);
    updatePhotoList(copy);
  };

  const moveLeft = idx => () => {
    if (idx <= 0) return;
    const copy = [...pledgeDrive.imageUrls];
    copy.splice(idx - 1, 0, copy.splice(idx, 1)[0]);
    updatePhotoList(copy);
  };

  if (!pledgeDrive) return null;

  return (
    <div>
      <h2>Photos:</h2>
      <code>
        WARNING: Recommended image aspect ratio is 5w x 3h. (For example, 500 x
        300, 1500 x 900, etc.) Images of a different size than this may become
        unreadable at some screen sizes.
      </code>
      <div style={{ display: 'flex' }}>
        {pledgeDrive.imageUrls.map((imageUrl, idx) => (
          <div key={imageUrl} style={{ margin: 8 }}>
            <div
              className={css`
                width: 250px;
                height: 250px;
                background-color: ${colors.grey[40]};
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
              `}
            >
              <img
                src={imageUrl}
                alt={pledgeDrive.name}
                className={css`
                  max-width: 100%;
                  max-height: 100%;
                `}
              />
            </div>
            <div
              className={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <Button onClick={moveLeft(idx)} disabled={loading}>
                &lt;
              </Button>
              <Button
                onClick={() =>
                  updatePhotoList(
                    pledgeDrive.imageUrls.filter(el => el !== imageUrl)
                  )
                }
                disabled={loading}
              >
                Delete
              </Button>
              <Button onClick={moveRight(idx)} disabled={loading}>
                &gt;
              </Button>
            </div>
          </div>
        ))}
        <div
          onClick={() => fileInputRef.current.click()}
          className={css`
            width: 250px;
            height: 250px;
            background-color: ${colors.grey[90]};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 8px;
            cursor: pointer;
          `}
        >
          <div>Add Image(s)</div>
          <input
            disabled={loading}
            ref={fileInputRef}
            type="file"
            accept="image/png, image/gif, image/jpeg, image/webp"
            onChange={upload}
            multiple
            style={{ display: 'none' }}
          />
        </div>
      </div>
    </div>
  );
};
