import React from 'react';
import { css } from 'emotion';
import { fonts } from 'helpers';
export default ({ children, className }) => {
  return (
    <div
      className={css`
        display: flex;
        align-self: end;
        justify-content: center;
        align-content: center;
        background-color: #fff;
        border-radius: 6px;
        padding: 4px;
        font-size: ${fonts.size.xs};
        font-family: ${fonts.fontFamily.secondary};
        ${className}
      `}
    >
      {children}
    </div>
  );
};
