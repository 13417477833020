export default {
  // primary
  blue: '#387CA6',
  lightBlue: '#96D2D9',
  darkBlue: '#1F4C73',
  // error
  redError: '#ca4544',
  redErrorLight: '#e9afaf',
  // success
  greenSuccess: '#44ca44',
  greenSuccessLight: '#d7f4d7',
  // pledgeLlamma Design V1
  white: '#FFFFFF',
  black: '#1A1818',
  tBlack: 'rgba(0,0,0, .3)',
  yellow: '#FFD912',
  purple: '#9F4090',
  green: '#3BB090',
  //greyScale
  grey: {
    100: '#ffffff',
    95: '#f2f2f2',
    90: '#e6e6e6',
    85: '#d9d9d9',
    80: '#cccccc',
    75: '#bfbfbf',
    70: '#b3b3b3',
    65: '#a6a6a6',
    60: '#999999',
    55: '#8c8c8c',
    50: '#808080',
    45: '#737373',
    40: '#666666',
    35: '#595959',
    30: '#4d4d4d',
    25: '#404040',
    20: '#333333',
    15: '#262626',
    10: '#1a1a1a',
    5: '#0d0d0d',
    0: '#000000'
  }
};
