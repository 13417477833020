import React from 'react';
import { css } from 'emotion';
import { fonts, colors } from 'helpers';

export default ({
  children,
  level = '1',
  size = 'lg',
  weight = 'normal',
  fontFamily = 'primary',
  color = 'black',
  textShadow = false,
  className
}) => {
  const Tag = 'h' + level;

  return (
    <Tag
      className={css`
        font-family: ${fonts.fontFamily[fontFamily]};
        font-size: ${fonts.size[size]};
        font-weight: ${fonts.fontWeight[weight]};
        ${!!textShadow && 'text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.2);'};
        color: ${colors[color]};
        margin-left: 10px;
        margin-right: 10px;
        ${className}
      `}
    >
      {children}
    </Tag>
  );
};
