function writeParams(filters) {
  return filters !== ''
    ? Object.entries(filters)
        .map(entry => `&${entry[0]}=${entry[1]}`)
        .join('')
    : '';
}

function parseParams(search) {
  const params = {};
  search
    .split('&')
    .slice(1)
    .map(i => (params[i.split('=')[0]] = decodeURI(i.split('=')[1])));
  return params;
}

function parseQuery(search) {
  const query = search
    .split('&')[0]
    .split('?')
    .reverse()[0];
  return query === '' ? query : decodeURI(query);
}

export default {
  writeParams,
  parseParams,
  parseQuery
};
