import React, { useState, useContext } from 'react';
import { css } from 'emotion';
import {
  Button,
  FormBox,
  Message,
  Form,
  TextInput,
  H,
  Animation
} from 'components';
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';
import qs from 'query-string';
import LeftPoint from 'assets/Lottie/LeftPoint.json';
import { media } from 'helpers';
import { resetPassword } from 'api/users.api';

export default props => {
  const { setUser } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (password !== password2) {
      setError('Passwords do not match!');
      setLoading(false);
      return;
    }

    try {
      const res = await resetPassword({
        resetToken: qs.parse(window.location.search).token,
        password: password
      });
      setUser(res);
      pushMessage.success('Password updated.');
      window.localStorage.setItem('token', res.token);
      props.history.push('/');
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  return (
    <div
      className={css`
        position: relative;
        margin-left: 56px;
        margin-right: 56px;
        height: 100vh;
      `}
    >
      <div
        className={css`
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        `}
      >
        {error && <Message error>{error}</Message>}
        <H
          color="black"
          fontFamily="title"
          textShadow={true}
          size="xl"
          className={css`
            text-align: center;
            margin: auto;
            margin-bottom: 50px;
          `}
        >
          Reset Your Password
        </H>
        <Form handleSubmit={e => submit(e)}>
          <FormBox>
            <TextInput
              name="password"
              type="password"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              placeholder="New Password"
              required={true}
            />
            <TextInput
              name="password2"
              type="password"
              value={password2}
              onChange={e => {
                setPassword2(e.target.value);
              }}
              placeholder="Verify New Password"
              required={true}
            />
          </FormBox>
          <Button
            boxShadow={true}
            submit={true}
            disabled={loading}
            buttonType="modal"
          >
            Reset Password
          </Button>
        </Form>
        <div
          className={css`
            position: relative;
            width: calc(100% - 272px);
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 20px;

            @media (max-width: ${media.screen.lg}) {
              margin: 20px 0 0 0;
              width: 100%;
            }
          `}
        >
          <Animation
            lottieFile={LeftPoint}
            className={css`
              height: 50%;
              width: 50%;
              margin: auto;
              max-height: 180px;
            `}
          />
        </div>
      </div>
    </div>
  );
};
