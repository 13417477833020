import React from 'react';
import { css } from 'emotion';
import { colors } from 'helpers';

export default ({ ...props }) => {
  return (
    <p
      {...props}
      className={css`
        ${props.className};
        font-size: 12px;
        margin: 2px;
        color: ${colors.grey[40]};
      `}
    >
      {props.children}
    </p>
  );
};
