import React, { useState, useEffect, useContext } from 'react';
import { css } from 'emotion';
import { Button, P } from 'components';

import { getCharity } from 'api/charities.api';
import { ToastContext } from 'context/Toast.context';
import { fonts, media } from 'helpers';
import Logo from 'assets/global/logo.png';

export default function CharitySection({ id, charityName }) {
  const [charity, setCharity] = useState([]);
  const { pushMessage } = useContext(ToastContext);

  useEffect(() => {
    async function fetchCharity() {
      try {
        const res = await getCharity({ charityId: id });
        setCharity(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    fetchCharity();
  }, [id, pushMessage]);

  return (
    <div
      className={css`
        display: flex;
        margin: 10px;
        justify-content: space-between;
        @media (max-width: ${media.screen.sm}) {
          margin: 5px;
        }
      `}
    >
      <img
        src={charity ? charity.logoUrl : Logo}
        alt={charityName}
        className={css`
          width: 70%;
          height: 60px;
          object-fit: contain;
          align-self: center;
        `}
      />
      <Button
        link={`charities/${id}`}
        className={css`
          align-self: flex-end;
          justify-content: flex-start;
          @media (max-width: ${media.screen.sm}) {
            padding: 5px;
          }
        `}
      >
        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
          `}
        >
          <P size="xxs"> supporting </P>
          <P
            weight="heavy"
            className={css`
              padding-top: 10px;
              padding-bottom: 10px;
              @media (max-width: ${media.screen.sm}) {
                font-size: ${fonts.size.sm};
              }
            `}
          >
            {charityName}
          </P>
          <P size="xxs"> learn more </P>
        </div>
      </Button>
    </div>
  );
}
