import React from 'react';
import { css } from 'emotion';
import { colors, effect, fonts, media } from 'helpers';
import { Link } from 'react-router-dom';

export default ({
  buttonType = 'standard', // standard / modal ...
  color = 'standard', //admin / standard / charity
  size = 'md', // xxl - xs
  boxShadow,
  submit,
  link,
  className,
  ...props
}) => {
  const Button = () => (
    <button
      type={props.type ? props.type : submit ? 'submit' : 'button'}
      className={css`
        background-color: ${props.disabled
          ? colors.grey[85]
          : color === 'admin'
          ? colors.purple
          : colors.yellow};
        border: none;
        border-radius: 12px;
        ${boxShadow && effect.boxShadowXsm};
        padding: ${size === 'xxl' || size === 'xl' || size === 'lg'
          ? '20px'
          : '12px'};
        font-family: ${fonts.fontFamily.primary};
        font-size: ${size === 'xxl' || size === 'xl'
          ? fonts.size.lg
          : size === 'lg'
          ? fonts.size.md
          : size === 'md' || size === 'sm' || size === 'xs'
          ? fonts.size.xs
          : fonts.size.sm};
        color: ${color === 'admin' ? colors.white : colors.black};
        font-weight: bold;
        outline: none;
        height: ${size === 'xxl'
          ? '116px'
          : size === 'xl'
          ? '80px'
          : size === 'lg'
          ? '63px'
          : size === 'sm'
          ? '35px'
          : buttonType === 'modal' || size === 'default'
          ? '42px'
          : size === 'xs'
          ? '30px'
          : '100%'};
        width: ${buttonType === 'modal' && 'calc(100% - 162px)'};
        display: flex;
        align-items: center;
        justify-content: center;
        margin: ${buttonType === 'modal' && '50px 81px 37px 81px'};

        :first-of-type {
          margin-bottom: ${buttonType === 'modal' && '0'};
        }

        @media (max-width: ${media.screen.md}) {
          width: ${buttonType === 'modal' && '100%'};
          margin: ${buttonType === 'modal' && '30px 0 30px 0'};
        }
        cursor: pointer;
        :hover {
          filter: brightness(85%);
        }
        ${className}
      `}
      {...props}
    >
      {props.children}
    </button>
  );

  return link ? (
    <Link
      to={link}
      className={css`
        text-decoration: none;
      `}
    >
      <Button {...props} />
    </Link>
  ) : (
    <Button />
  );
};
