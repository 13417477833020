import React from 'react';
import { css } from 'emotion';
import { colors, zIndex } from 'helpers';

export default ({ navState, clickFunction, className }) => {
  return (
    <div
      onClick={clickFunction}
      className={css`
        position: fixed;
        right: 8px;
        bottom: 12px;
        z-index: ${zIndex.layer14.navButton};
        border-radius: ${navState ? '10px' : '10px 0 0 10px'};
        transition: 0.2s;
        background-color: ${colors.black};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 55px;
        height: 65px;
        transform: translate(${navState ? '0px, 0px' : '12px, -25px'});
        z-index: ${zIndex.layer13.nav};
        ${className}
      `}
    >
      {/* bar1 */}
      <div
        className={css`
          height: 5px;
          width: 70%;
          background-color: ${colors.yellow};
          border-radius: 12px;
          transition: 0.2s;
          transform: rotate(${navState ? '0deg' : '-50deg'})
            translateY(${navState ? '0px' : '4.5px'})
            translateX(${navState ? '0px' : '-5.5px'});
        `}
      />
      {/* bar2 */}
      <div
        className={css`
          margin-top: 6px;
          height: 5px;
          width: 70%;
          background-color: ${colors.yellow};
          border-radius: 12px;
          transition: 0.2s;
          transform: rotate(${navState ? '0deg' : '50deg'})
            translateY(${navState ? '0px' : '-3.5px'})
            translateX(${navState ? '0px' : '-2.5px'});
        `}
      />
      {/* bar 3 */}
    </div>
  );
};
