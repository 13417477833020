import React, { useState } from 'react';
import { css } from 'emotion';

import { Layout, H, P, Link } from 'components';
import { SignUpModal } from 'modals';

export default props => {
  const [signUpModal, setSignUpModal] = useState(false);

  return (
    <Layout backTitle="Contest Rules">
      <SignUpModal
        isOpen={signUpModal}
        close={() => setSignUpModal(false)}
        isUnsubscribed={true}
      />
      <div
        className={css`
          padding: 48px 24px;
        `}
      >
        <H
          level="2"
          size="sm"
          weight="bold"
          fontFamily="secondary"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          OFFICIAL RULES <br />
          <br />
          NOTICE REGARDING DISPUTE RESOLUTION: THESE OFFICIAL RULES CONTAIN
          TERMS THAT GOVERN HOW CLAIMS BETWEEN YOU AND SPONSOR WILL BE RESOLVED.
        </H>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          1 - NO PURCHASE NECESSARY TO ENTER OR WIN.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          2 - DEFINITIONS:
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          (A)“Charity” shall mean the organization supported by a particular
          Sweepstakes. <br />
          <br />
          (B)“Entry Period” shall mean the time in which a Sweepstakes is open
          to Participants to enter. The Entry Period shall be clearly identified
          in the Sweepstake Rules.
          <br />
          <br />
          (C)“Participant” shall mean you, or any individual, that takes part in
          a Sweepstakes. <br />
          <br />
          (D)“Official Rules” shall mean the rules found on this “Official
          Rules” page.
          <br />
          <br />
          (E)“Sponsor” shall mean PledgeLlama, LLC a(n) [Idaho] limited
          liability company.
          <br />
          <br />
          (F)“Sweepstakes” shall mean an individual giveaway hosted by Sponsor
          for a particular Charity.
          <br />
          <br />
          (G)“Sweepstake Rules” shall mean the additional rules associated with
          a particular Sweepstakes.
          <br />
          <br />
          (H)“Website” shall mean www.pledgellama.com.
          <br />
          <br />
        </P>

        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          3 - APPLICABILITY: APPLICABILITY: These Official Rules govern the
          terms of every Sweepstakes hosted by Sponsor after December 27th,
          2022. Sweepstakes may be subject to additional federal, state, and
          local laws and regulation and are void where prohibited. Each
          Sweepstakes is also subject to additional Sweepstake Rules. In the
          event there is conflict between these Official Rules and the
          Sweepstake Rules, the Sweepstakes Rules shall govern.
        </P>

        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          4 - TERMS OF USE: The Official Rules and Sweepstake Rules shall form a
          binding contract between Sponsor and Participant. If a Participant
          enters into a Sweepstakes, the Participant agrees to be bound by the
          Official Rules and Sweepstake Rules in their entirety.
          <strong>
            {' '}
            If you do not agree to the Official Rules and Sweepstakes Rules, you
            are not permitted to enter into any Sweepstakes.
          </strong>{' '}
          Sponsor is free to update these Official Rules at any time, and
          Participants should review these Official Rules often to be aware of
          any changes that they will be bound to.upon entering any given
          Sweepstakes.
        </P>

        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          5 - HOW TO ENTER: Each Sweepstakes will have a beginning and end time.
          Sweepstakes end once the pledge goal has been met. Entries into a
          particular Sweepstakes will only be considered valid if they are
          received by Sponsor during the Entry Period and provide all required
          information. A Participant will have (3) pledges a month that can be
          used at any time during the month period. Use of any robotic,
          automatic, programmed, or similar entry method or entering more than
          the number of times permitted will void all entries and result in
          disqualification. You may not enter more times than indicated by using
          multiple email addresses, identities, or devices in an attempt to
          circumvent the rules. A Sweepstakes can be entered into in two
          different ways:
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          (A) A Participant may sign up for a subscription to the pledgellama
          app for $5.99 a month which grants them access to the merchandise
          store, the consolidated list of Pledgellama vetted charities with
          donation tunnels, and the graphical user interface with sort and
          filter functions to assign a portion of their subscription to 3
          different Charities a month (“Pledge”).This allows easy one tap
          pledging to pledge drives and is the simplest way to make a Pledge.
          This method also allows contributions to the assigned charities and
          allows PledgeLlama to operate and continue contributing to make the
          world a better place! Without the subscription fee there are no funds
          to donate to the Charities.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          (B) A Participant may sign up through the no cost sign up portal by
          clicking the link below
          <br />
          <br />
          <Link onClick={() => setSignUpModal(true)}>
            Sign up as an unsubscribed user.
          </Link>
          <br />
          <br /> to gain access to the list of pledge drives. Each month prior
          to their sign up date they must mail a letter to PledgeLlama at PO Box
          190102 Boise, ID 83719 requesting their (3) pledges to be added to
          their account for that month.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          6 - ENTRY LIMITATIONS AND RESTRICTIONS: Only accurate and complete
          entries submitted during the Entry Period and received by Sponsor are
          eligible. Any incomplete, illegible, corrupted, or untimely entries
          are also void and ineligible to win. Proof of submission will not be
          deemed to be proof of receipt by Sponsor. Sponsor is not responsible
          for lost, late, illegible, incomplete, invalid, unintelligible,
          misdirected, technically corrupted or garbled entries, or for other
          submission problems of any kind, whether caused mechanical, human or
          electronic error, all of which are void and ineligible to win.
          Additionally, Sponsor is not responsible for any telephone,
          electronic, hardware, software, network, Internet, or computer
          malfunctions, failures, or difficulties. If a Participant’s Pledge is
          refunded, any entries associated with such donation will be withdrawn
          and not eligible to win. Participants must provide all required
          information specified to be eligible to enter and win.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          7 - ENTRY CONDITIONS AND RELEASE: As a condition of entering any
          Experience, Participant expressly:
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          (A) Consents to receive information or promotional materials from
          Sponsor and Charities, via email and/or mail;
          <br />
          <br />
          (B) Represents and warrants that (s)he agrees to be bound by these
          Official Rules, Sweepstake Rules, and decisions of the Sponsor, which
          will be binding and final in all matters relating to each Sweepstakes;
          <br />
          <br />
          (C) Knowingly and expressly waives all rights to claim, punitive,
          exemplary, indirect incidental, consequential, or any other damages,
          other than for actual out-of-pocket expenses and/or any rights to have
          damages multiplied or otherwise increased;
          <br />
          <br />
          (D) Agrees that all claims, disputes, or causes of action arising out
          of or connected with any Sweepstakes, shall be individually resolved
          in binding arbitration, without resort to any form of class,
          collective or representative action;
          <br />
          <br />
          (E) Agrees that any and all claims, judgments, and awards, if
          permitted to proceed, will be limited to Participant’s actual
          out-of-pocket costs incurred (if any), excluding attorneys’ fees and
          court costs.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          8 - ELIGIBILITY: Open to residents of the 50 United States and D.C.,
          18 years of age and older as of first date of the Entry Period, except
          for employees of the Sponsor or Charity, their affiliates,
          subsidiaries, and agencies (collectively "Promotion Parties"), and
          members of their immediate family or persons living in the same
          household. Void where prohibited.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          9 - DRAWING: A random drawing will be conducted on or about the end of
          Entry Period by Sponsor to select the winner[s] from among all
          eligible entries received. Odds of winning depend on the number of
          entries received. Potential winner[s] will be notified by email and
          must sign and return any required Affidavit of Eligibility and Release
          within 24 hours of the date notice is sent or such other date set by
          Sponsor. Failure to respond within the applicable time period will
          result in forfeiture of prize and Sponsor shall have the option to
          award the prize to an alternate winner. The return of any prize
          notification or prize as undeliverable may result in disqualification
          and an alternate winner may be selected. All income taxes resulting
          from acceptance of prize are the responsibility of winner. By entering
          this sweepstakes, entrant accepts and agrees to these Official Rules
          and the decisions of the Administrator, which shall be final in all
          matters. Acceptance of a prize also constitutes permission to the
          Promotion Parties to use winner's name, likeness, and biographical
          information for marketing purposes without further compensation or
          right of approval, unless prohibited by law. All federal and state
          laws apply.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          10 - BACKGROUND CHECKS: Participants entry into a Sweepstakes shall
          constitute consent to give Sponsor the right to conduct background
          checks on Participants that have been selected as a winner of a
          Sweepstakes, except where legally prohibited. Sponsor reserves the
          right, in its sole discretion, to disqualify the Participant from any
          winnings, if the Sponsor determines, in its sole discretion, if the
          Participants involvement might reflect negatively on Sponsor or the
          Charity.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          11 - LIMITATION OF LIABILITY: By entering this sweepstakes, entrants
          waive all right to, and hold the Promotion Parties harmless from, any
          claim, liability, loss, damage (including punitive, incidental, and
          consequential damages), or expense (including attorneys' fees) arising
          out of or in connection with participation in this sweepstakes or the
          acceptance, use, or misuse of any prize. SOME JURISDICTIONS DO NOT
          ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
          CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY NOT APPLY TO YOU. Promotion
          Parties will not be responsible for: late, incomplete, or incorrect
          entries; an entrant's failure to receive prize notices due to
          entrant's spam, junk e-mail, or other security settings or for
          entrants' provision of incorrect or otherwise non-functioning contact
          information; technical, hardware, or software malfunctions, lost or
          unavailable network connections, or failed, incorrect, inaccurate,
          incomplete, garbled, or delayed electronic communications whether
          caused by the sender or by any of the equipment or programming
          associated with or used in this sweepstakes; by any human error which
          may occur in the processing of the entries in this sweepstakes; or any
          typographical, technological, or other error in the publishing of the
          offer, administration of the sweepstakes, or announcement of the
          prize[s]. If, in the Sponsor's opinion, there is such an error, or
          there is any suspected evidence of tampering with any portion of the
          sweepstakes, or if technical difficulties (including viruses and bugs)
          compromise the integrity of the sweepstakes, Sponsor reserves the
          right to, in its sole discretion, cancel or modify this sweepstakes in
          a manner deemed appropriate. In the event of termination, winners will
          be selected from among all eligible entries received as of date of
          termination. In the event a dispute arises as to the identity of a
          potentially winning entrant, entries will be declared made by the name
          on the online entry form.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          12 - PRIZES: Prize consists of only those items specifically listed as
          part of the Sweepstake Rules. Limit one prize per family or household.
          No substitution or transfer of prize permitted by winner. Sponsor
          reserves the right to substitute a prize of equal or greater value.
          All prizes will be awarded.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          13 - CHOICE OF LAW AND FORUM: Entrant agrees that all matters arising
          out of or relating to this sweepstakes and these Official Rules are
          governed by, and construed in accordance with, the laws of Idaho,
          without giving effect to any of its conflict of laws provisions
          thereof. Entrant further agrees that any legal suit, action, or
          proceeding arising out of or relating to this sweepstakes and these
          Official Rules shall be brought exclusively in the applicable federal
          or state courts located in Boise, Idaho.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          14 - Information submitted with an entry is subject to the Privacy
          Policy stated on our Website. To read the Privacy Policy,
          <Link to="/privacy-policy"> click here.</Link>
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          15 - SPONSOR: Ice Boxx LLC, PO Box 190102 Boise, ID 83719
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          16 - SEVERABILITY: Each of the terms of these Official Rules operates
          separately. If any court of relevant authority decides that any of
          them are unlawful or unenforceable, the remaining terms will remain in
          full force and effect.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          17 - NO WAIVER: If Sponsor fails to insist that Participant perform
          any of Participant’s obligations under these Official Rules or
          Sweepstake Rules, or if Sponsor does not enforce its rights against
          Participant, or if Sponsor delays in doing so, that will not mean that
          Sponsor has waived its rights against Participant and will not mean
          that Participant does not have to comply with these obligations. If
          Sponsor does waive a default by Participant, Sponsor will only do so
          in writing, and that will not mean that Sponsor will automatically
          waive any later default by Participant.
        </P>
        <P
          size="sm"
          color="purple"
          className={css`
            margin-bottom: 20px;
          `}
        >
          18 - ENTIRE AGREEMENT: These Official Rules, together with the
          applicable Sweepstakes Rules, constitute the entire agreement between
          Sponsor and Participant with respect to any given Sweepstakes and
          supersede all prior or contemporaneous communications, whether
          electronic, oral or written, between Sponsor and Participant with
          respect to any Sweepstakes.
        </P>
      </div>
    </Layout>
  );
};
