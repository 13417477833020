import React, { useState, useContext } from 'react';
import axios from 'axios';
import { css } from 'emotion';

import { H, P, Button, Link } from 'components';
import { fonts, effect } from 'helpers';
import { ToastContext } from 'context/Toast.context';
import { UserContext } from 'context/User.context';
import { useHistory } from 'react-router-dom';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState('');

  const submit = e => {
    e.preventDefault();
    setLoading(true);

    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };

    const formData = JSON.stringify({
      Statement: feedback,
      User_Email: user.email
    });

    axios
      .post(
        'https://public.herotofu.com/v1/abdc2e60-7f48-11ed-b38f-a1ed22f366b1',
        formData,
        config
      )
      .then(res => {
        pushMessage.success('We appreciate your feedback');
        history.push('/');
      })
      .catch(e => {
        pushMessage.error(e.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      className={css`
        height: 100vh;
        margin-left: 10%;
        margin-right: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
    >
      <div
        className={css`
          max-width: 600px;
        `}
      >
        <H
          size="xl"
          fontFamily="title"
          className={css`
            align-self: flex-start;
            text-align: left;
            margin-left: 12px;
            margin-top: 10px;
          `}
        >
          Do you have any feedback for us? <br />
        </H>
        <form onSubmit={submit}>
          <div
            className={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <P
              size="sm"
              weight="heavy"
              className={css`
                margin-left: 12px;
                margin-bottom: 10px;
                margin-top: 10px;
              `}
            >
              Please let us know if there is anything we can do to improve.
            </P>
            <textarea
              name="Statement"
              id="Statement"
              type="text"
              value={feedback}
              onChange={e => setFeedback(e.target.value)}
              className={css`
                padding: 14px;
                height: 300px;
                margin-left: 12px;
                margin-right: 12px;
                font-size: ${fonts.size.xs};
                font-family: ${fonts.fontFamily.primary};
                font-weight: ${fonts.fontWeight.normal};
                border: solid black;
                border-radius: 12px;
                margin-top: 10px;
                ${effect.boxShadowXsm}
              `}
              required
            />
          </div>
          <div
            className={css`
              display: flex;
              justify-content: flex-end;
            `}
          >
            <Button
              submit
              disabled={loading || feedback.length === 0}
              className={css`
                margin-right: 12px;
                margin-top: 10px;
                ${effect.boxShadowXsm}
              `}
            >
              Submit
            </Button>
          </div>
          <Link
            className={css`
              margin-left: 12px;
            `}
            to="/"
          >
            Home
          </Link>
        </form>
      </div>
    </div>
  );
};
