import React, { useState } from 'react';

const Swiper = ({ onSwipeX, onSwipeY, threshold, children }) => {
  const [touchStartX, setTouchStartX] = useState();
  const [touchStartY, setTouchStartY] = useState();

  const handleEndSwipe = changedTouches => {
    if (Math.abs(changedTouches.clientX - touchStartX) > threshold) {
      if (typeof onSwipeX === 'function') {
        return onSwipeX(
          changedTouches.clientX < touchStartX ? 'right' : 'left'
        );
      }
    } else if (Math.abs(changedTouches.clientY - touchStartY) > threshold) {
      if (typeof onSwipeY === 'function') {
        return onSwipeY(changedTouches.clientY < touchStartY ? 'up' : 'down');
      }
    } else {
      return null;
    }
  };

  return (
    <div
      onTouchStart={e => {
        setTouchStartX(e.changedTouches[0].clientX);
        setTouchStartY(e.changedTouches[0].clientY);
      }}
      onTouchEnd={e => handleEndSwipe(e.changedTouches[0])}
    >
      {children}
    </div>
  );
};

export { Swiper };
