import React, { useState } from 'react';
import { css } from 'emotion';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Link, Button } from 'components';
import { LogoutModal } from 'modals';

import AdminPledgeDrivesRoute from './routes/AdminPledgeDrives.route';
import AdminCompletedPledgeDrivesRoute from './routes/AdminCompletedPledgeDrives.route';
import CreatePledgeDriveRoute from './routes/CreatePledgeDrive.route';
import PledgeDriveRoute from './routes/PledgeDrive.route';
import UsersRoute from './routes/Users.route';
import UserRoute from './routes/User.route';
import CharitiesRoute from './routes/Charities.route';
import CreateCharityRoute from './routes/CreateCharity.route';
import CharityRoute from './routes/Charity.route';
import CharityCategoriesRoute from './routes/CharityCategories.route';
import PledgeDriveCategoriesRoute from './routes/PledgeDriveCategories.route';
import StatsRoute from './routes/Stats.route';

export default () => {
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  return (
    <div style={{ padding: 10 }}>
      <p>
        <Link to="/admin/pledge-drives">Pledge Drives</Link>
        {' | '}
        <Link to="/admin/completed-pledge-drives">Completed Pledge Drives</Link>
        {' | '}
        <Link to="/admin/users">Users</Link>
        {' | '}
        <Link to="/admin/charities">Charities</Link>
        {' | '}
        <Link to="/admin/charity-categories">Charity Categories</Link>
        {' | '}
        <Link to="/admin/pledge-drive-categories">Pledge Drive Categories</Link>
        {' | '}
        <Link to="/admin/stats">Stats</Link>
      </p>

      <Button
        onClick={() => {
          setLogoutModalOpen(true);
        }}
        boxShadow={true}
        className={css`
          margin: 12px 0;
        `}
      >
        {' '}
        Logout{' '}
      </Button>

      <Switch>
        <Redirect exact from="/admin" to="/admin/pledge-drives" />
        <Route
          path="/admin/pledge-drives"
          exact
          component={AdminPledgeDrivesRoute}
        />
        <Route
          path="/admin/completed-pledge-drives"
          exact
          component={AdminCompletedPledgeDrivesRoute}
        />
        <Route
          path="/admin/pledge-drives/new"
          exact
          component={CreatePledgeDriveRoute}
        />
        <Route
          path="/admin/pledge-drives/:id"
          exact
          component={PledgeDriveRoute}
        />
        <Route path="/admin/users" exact component={UsersRoute} />
        <Route path="/admin/users/:id" exact component={UserRoute} />
        <Route path="/admin/charities" exact component={CharitiesRoute} />
        <Route
          path="/admin/charities/new"
          exact
          component={CreateCharityRoute}
        />
        <Route path="/admin/charities/:id" exact component={CharityRoute} />
        <Route
          path="/admin/charity-categories"
          exact
          component={CharityCategoriesRoute}
        />
        <Route
          path="/admin/pledge-drive-categories"
          exact
          component={PledgeDriveCategoriesRoute}
        />
        <Route path="/admin/stats" exact component={StatsRoute} />
        <Redirect to="/404" />
      </Switch>
      <LogoutModal
        isOpen={logoutModalOpen}
        close={() => setLogoutModalOpen(false)}
      />
    </div>
  );
};
