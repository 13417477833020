import React, { useContext } from 'react';
import { css } from 'emotion';
import { colors } from 'helpers';
import { P } from 'components';
import { UserContext } from 'context/User.context';

export default ({ detail, completion, className }) => {
  const { user } = useContext(UserContext);

  const displayCompletion = Math.round(completion);
  return user?.subscriptionActive ? (
    <div
      className={css`
        background-color: ${colors.white};
        height: 4px;
        border-radius: 12px;
        width: 100%;
        ${className}
      `}
    >
      <P
        className={css`
          padding-top: 10px;
          padding-left: 10px;
          position: absolute;
          white-space: nowrap;
        `}
      >
        {detail ? displayCompletion + '%' : null}
      </P>
      {/* completed indicator green */}
      <div
        className={css`
          width: ${displayCompletion > 5 ? displayCompletion + '%' : '5%'};
          border-radius: 12px;
          height: 100%;
          background-color: ${colors.green};
        `}
      />
    </div>
  ) : null;
};
