import React from 'react';
import { css } from 'emotion';

import { colors, fonts } from 'helpers';

/* This component can also be used for inputs such as password, email, and phone using the 'type' prop */
export default ({ type = 'text', ...props }) => {
  return (
    <input
      className={css`
        font-family: ${fonts.fontFamily.primary};
        font-size: ${fonts.size.xs};
        border: none;
        border-bottom: 1px solid ${colors.grey[10]};
        margin-bottom: 43px;
        appearance: none;
        ${props.className}

        &:last-of-type {
          margin-bottom: 0;
        }
      `}
      {...props}
      type={type}
    />
  );
};
