import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import { css } from 'emotion';

export default ({
  lottieFile,
  speed = 1,
  loop = false,
  autoplay = true,
  onComplete,
  className
}) => {
  const container = useRef(null);

  useEffect(() => {
    const params = {
      container: container.current,
      renderer: 'svg',
      loop: loop,
      autoplay: autoplay,
      animationData: lottieFile
    };

    const anim = lottie.loadAnimation(params);
    anim.setSpeed(speed);
    anim.onComplete = onComplete;

    return () => anim.destroy();
  }, [autoplay, loop, lottieFile, onComplete, speed]);

  return (
    <div
      className={css`
        max-width: 100%;
        max-height: 100%;
        ${className}
      `}
      ref={container}
    ></div>
  );
};
