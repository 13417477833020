import React from 'react';
import { css } from 'emotion';
import { zIndex, media } from 'helpers';

export default ({ children, className }) => {
  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 18px 17px 18px;
        border-radius: 0 0 12px 12px;
        width: calc(100vw - 76px);
        max-width: 560px;
        background-color: white;
        z-index: ${zIndex.layer1.modalContent};
        position: relative;
        @media (max-width: ${media.screen.md}) {
          padding: 18px;
        }
        ${className}
      `}
    >
      {children}
    </div>
  );
};
