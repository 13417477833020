import React, { useState } from 'react';
import { css } from 'emotion';
import { Link, Button, Form, CheckBoxInput, InputLabel } from 'components';

export default ({ next }) => {
  const [isCheckedTOS, setIsCheckedTOS] = useState(false);
  const [isCheckedRules, setIsCheckedRules] = useState(false);

  return (
    <>
      <Form
        className={css`
          display: block;
        `}
      >
        <div
          className={css`
            text-align: center;
            margin-bottom: 18px;
          `}
        >
          <CheckBoxInput
            name="checkboxTOS"
            type="checkbox"
            checked={isCheckedTOS}
            required={true}
            onChange={e => {
              setIsCheckedTOS(e.target.checked);
            }}
          />
          <InputLabel htmlFor="checkboxTOS">
            First please read and agree to our{' '}
            <Link target="_blank" to="tos">
              Terms of Service
            </Link>
          </InputLabel>
        </div>
        <div
          className={css`
            text-align: center;
          `}
        >
          <CheckBoxInput
            name="checkboxRules"
            type="checkbox"
            checked={isCheckedRules}
            required={true}
            onChange={e => {
              setIsCheckedRules(e.target.checked);
            }}
          />
          <InputLabel htmlFor="checkboxRules">
            Do you agree to our{' '}
            <Link target="_blank" to="contest-rules">
              Contest Rules
            </Link>
          </InputLabel>
        </div>
      </Form>
      <Button
        boxShadow={true}
        buttonType="modal"
        disabled={!isCheckedTOS || !isCheckedRules}
        onClick={next}
      >
        Continue
      </Button>
    </>
  );
};
