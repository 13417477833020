export default {
  layer0: {
    introTitle: 0,
    winnersBg: 0,
    uiStill: 0
  },

  layer1: {
    search: 1,
    winnersInner: 1,
    homeAnimation: 1,
    modalContent: 1
  },

  layer2: {
    winnersSection: 2,
    winnersImg: 2,
    homeLists: 2,
    pledgeAnim: 2,
    modalHeader: 2
  },

  layer3: {
    slider: 3,
    sliderButtons: 3,
    sliderComponentsWrapper: 3
  },

  layer4: {
    sliderButton: 4,
    indexDots: 4
  },

  layer9: {
    footer: 9
  },

  layer11: {
    backbutton: 11
  },

  layer12: {
    header: 12
  },

  layer13: {
    nav: 13
  },

  layer14: {
    navButton: 14
  },

  layer15: {
    IOS: 15
  },

  layer99: {
    modalOuter: 99
  },

  layer100: {
    modalInner: 100
  },

  layer101: {
    acceptCookies: 101
  },

  layer103: {
    toastMessage: 103
  },
  layer104: {
    orientationOverlay: 104
  }
};
