import React, { useContext, useEffect, useState } from 'react';

//api
import {
  getCharityCategories,
  createCharityCategory
} from 'api/charityCategories.api';

//context
import { ToastContext } from 'context/Toast.context';

import { Button, Input, Label, P } from 'components';
import { Category } from '../components/Category.component';

import { css } from 'emotion';

export default props => {
  const { pushMessage } = useContext(ToastContext);

  const [charityCategories, setCharityCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [loading, setLoading] = useState(false);

  //get charity categories list
  useEffect(() => {
    const setCategories = async e => {
      try {
        const res = await getCharityCategories({ withArchived: true });
        setCharityCategories(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    };
    setCategories();
  }, [pushMessage]);

  //create charity category
  const create = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await createCharityCategory({
        name: newCategoryName
      });
      setCharityCategories(prev => [...prev, res]);
      pushMessage.success('Category Created');
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const update = updated => {
    setCharityCategories(
      charityCategories.map(category =>
        category.id === updated.id ? updated : category
      )
    );
  };

  return (
    <div>
      <form onSubmit={create}>
        <P
          className={css`
            margin-top: 20px;
          `}
        >
          Be careful, Charity Categories added here will show in the search
          filter.
        </P>
        <Label>New Category</Label>
        <Input
          marginBottom={8}
          onChange={e => setNewCategoryName(e.target.value)}
          value={newCategoryName}
          required
        />

        <Button
          disabled={loading}
          submit
          className={css`
            margin-bottom: 10px;
          `}
        >
          Create
        </Button>
      </form>
      <Label
        className={css`
          margin-top: 20px;
          margin-bottom: 10px;
        `}
      >
        Charity Categories
      </Label>
      <div
        className={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {charityCategories.map(category => (
          <Category
            categoryName="charity-categories"
            category={category}
            onUpdate={update}
            key={category.id}
          />
        ))}
      </div>
    </div>
  );
};
