import React, { useContext, useEffect, useState } from 'react';

//api
import { getPledgeDriveCategories } from 'api/pledgeDriveCategories.api';
import { getCharities } from 'api/charities.api';
import { createPledgeDrive } from 'api/pledgeDrives.api';

//context
import { ToastContext } from 'context/Toast.context';

import {
  Button,
  Input,
  Label,
  Message,
  Select,
  TextArea,
  CheckBoxInput
} from 'components';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [charities, setCharities] = useState([]);
  const [charityId, setCharityId] = useState('');
  const [featured, setFeatured] = useState(false);
  const [pledgeDriveCategories, setPledgeDriveCategories] = useState([]);
  const [pledgeDriveCategoryId, setPledgeDriveCategoryId] = useState('');
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [retailValue, setRetailValue] = useState('');

  const submit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await createPledgeDrive({
        pledgeDriveInfo: {
          name,
          description,
          charityId,
          retailValue,
          pledgeDriveCategoryId,
          featured
        }
      });
      history.push(`/admin/pledge-drives/${res.id}`);
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function async() {
      try {
        const res = await getCharities();
        setCharities(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }

    async();
  }, [pushMessage]);

  //get pledge drive categories list
  useEffect(() => {
    const setStat = async e => {
      try {
        const res = await getPledgeDriveCategories();
        setPledgeDriveCategories(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    };
    setStat();
  }, [pushMessage]);

  return (
    <form onSubmit={submit}>
      {error && <Message error>{error}</Message>}
      <Label>Name</Label>
      <Input
        marginBottom={8}
        onChange={e => setName(e.target.value)}
        value={name}
        required
      />
      <Label>Description</Label>
      <TextArea
        marginBottom={8}
        onChange={e => setDescription(e.target.value)}
        value={description}
      />
      <Label>Charity</Label>
      <Select
        marginBottom={8}
        value={charityId}
        onChange={e => setCharityId(e.target.value)}
        options={charities.map(charity => ({
          value: charity.id,
          label: charity.name
        }))}
        required
      />
      <Label>Pledge Drive Category</Label>
      <Select
        marginBottom={8}
        value={pledgeDriveCategoryId}
        onChange={e => setPledgeDriveCategoryId(e.target.value)}
        options={pledgeDriveCategories.map(pledgeDriveCategory => ({
          value: pledgeDriveCategory.id,
          label: pledgeDriveCategory.name
        }))}
        required
      />
      <Label>Retail Value</Label>
      <NumberFormat
        customInput={Input}
        marginBottom={8}
        onValueChange={({ value }) => setRetailValue(value)}
        value={retailValue}
        required
        thousandSeparator
        prefix="$"
        decimalScale={2}
      />
      <Label>Pledge Value</Label>
      <Message error={false}>
        <strong>{Math.ceil(retailValue / 0.67) || 0}</strong>
      </Message>
      <Label>Do you want to feature this on the homepage?</Label>
      <CheckBoxInput
        name="isFeatured"
        type="checkbox"
        checked={featured}
        onChange={e => {
          setFeatured(e.target.checked);
        }}
      />

      <Button submit={true} disabled={loading}>
        Create Pledge Drive
      </Button>
    </form>
  );
};
