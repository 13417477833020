import React from 'react';
import { css } from 'emotion';
import { colors, media } from 'helpers';

import { Modal } from 'components';

export default ({ align, ...props }) => {
  return (
    <Modal
      className={css`
        background-color: white;
      `}
      {...props}
    >
      <div
        className={css`
          background-color: ${colors.purple};
          display: flex;
          flex-direction: column;
          align-items: ${align === 'center' ? 'center' : 'flex-start'};
          justify-content: ${align === 'center' ? 'center' : 'flex-start'};
          border-radius: 12px;
          margin-top: 18px;
          margin-bottom: 17px;
          width: calc(100vw - 76px);
          max-width: 560px;
          padding: 10px;

          @media (max-width: ${media.screen.sm}) {
            width: calc(100vw - 32px);
          }

          ${props.className}
        `}
      >
        {props.children}
      </div>
    </Modal>
  );
};
