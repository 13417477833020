import React, { useContext, useEffect, useState } from 'react';
import { ToastContext } from 'context/Toast.context';
import moment from 'moment';
import { Link } from 'components';
import { getUsers } from 'api/users.api';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function async() {
      try {
        const res = await getUsers();
        setUsers(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage]);

  return (
    <div>
      {users.map(user => (
        <div key={user.id}>
          <Link to={`/admin/users/${user.id}`}>
            <h2>{user.name}</h2>
          </Link>
          <p>
            Disabled: {user.disabled.toString()}
            <br />
            Email: {user.email}
            <br />
            Admin?: {user.isAdmin.toString()}
            <br />
            Last Payment: {moment(user.lastPaymentAt).format('LLL')}
            <br />
            Subscription Active: {user.subscriptionActive.toString()}
          </p>
        </div>
      ))}
    </div>
  );
};
