import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import './index.css';
import Routes from 'routes';
import UserContextProvider from 'context/User.context';
import ToastContextProvider from 'context/Toast.context';
import ModalContextProvider from 'context/Modal.context';
import PledgesContextProvider from 'context/Pledges.context';

function App() {
  ReactGA.initialize({ trackingId: process.env.GA_TRACKING_ID });
  //allows ga to track the most viewed pages
  useEffect(() => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ToastContextProvider>
      <UserContextProvider>
        <PledgesContextProvider>
          <ModalContextProvider>
            <Routes />
          </ModalContextProvider>
        </PledgesContextProvider>
      </UserContextProvider>
    </ToastContextProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
