import React, { useState, useContext, useEffect } from 'react';
import { Button, Layout, Slider, PledgeDrive, ProfileCard } from 'components';
import { getPledgeDrives } from 'api/pledgeDrives.api';
import { UserContext } from 'context/User.context';
import { css } from 'emotion';
import { ToastContext } from 'context/Toast.context';
import { LogoutModal } from 'modals';
import { media, string } from 'helpers';
import moment from 'moment';
import ProfileStat from './components/ProfileStat.component';
import ProfileInfo from './components/ProfileInfo.component';

export default props => {
  const [userPledgeDrives, setUserPledgeDrives] = useState([]);
  const [userPledgeDrivesHistory, setUserPledgeDrivesHistory] = useState([]);
  const { user } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const [logoutModal, setLogoutModal] = useState(false);

  function calculateDonated(curPDs, pastPDs) {
    return string.formatMoney(
      curPDs
        .map(pledgeDrive => Math.ceil(pledgeDrive.pledgeCount * 100) / 100)
        .reduce((total, current) => total + current, 0) *
        0.67 +
        pastPDs
          ?.filter(pd => pd.winnerId)
          .map(pledgeDrive => Math.ceil(pledgeDrive.pledgeValue * 100) / 100)
          .reduce((total, current) => total + current, 0) *
          0.67
    );
  }

  useEffect(() => {
    async function fetchUserPledgeDrives() {
      try {
        const res = await getPledgeDrives({ userId: user.id, active: true });
        setUserPledgeDrives(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }

    fetchUserPledgeDrives();
  }, [pushMessage, user.id]);

  useEffect(() => {
    async function fetchUserPledgeDrivesHistory() {
      try {
        const res = await getPledgeDrives({
          userId: user.id,
          withArchived: true,
          active: false
        });
        setUserPledgeDrivesHistory(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }

    fetchUserPledgeDrivesHistory();
  }, [pushMessage, user.id]);

  return (
    <Layout back={false}>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 10px;
        `}
      >
        <Button
          className={css`
            @media (max-width: ${media.screen.md}) {
              font-size: 12px;
              min-width: 80px;
            }
          `}
          onClick={() => {
            setLogoutModal(true);
          }}
        >
          Log Out
        </Button>
        <div
          className={css`
            display: flex;
            margin-left: 30px;
            @media (max-width: ${media.screen.md}) {
              margin-left: 10px;
            }
          `}
        >
          <ProfileInfo label={'User ID:'} value={user.id} />
          <ProfileInfo
            label={'Subscription Renews:'}
            value={moment(user.lastPaymentAt)
              .add(1, 'month')
              .format('DD-MM-YYYY')}
          />
        </div>
      </div>
      <ProfileCard />
      {/* Pledge Info */}
      <div
        className={css`
          margin: 43px 23px 8px 23px;
          display: flex;
          @media (max-width: ${media.screen.sm}) {
            margin: 10px;
          }
        `}
      >
        <div
          className={css`
            width: 50%;
            margin-right: 10px;
          `}
        >
          <ProfileStat
            before="You have"
            stat={userPledgeDrives?.length}
            after="active Pledge Drives"
          />
          <ProfileStat before={<strong>To date you have:</strong>} />
          <ProfileStat
            before="Helped raise:"
            stat={calculateDonated(userPledgeDrives, userPledgeDrivesHistory)}
            after="!"
            br={false}
          />
          <ProfileStat
            before="Total pledged:"
            stat={userPledgeDrivesHistory?.length}
            after="Pledge Drives!"
          />
        </div>
        {Boolean(userPledgeDrives?.length > 0) && (
          <div
            className={css`
              width: 50%;
              align-self: flex-end;
            `}
          >
            <Button
              link={`/pledge-drives?&userId=${user?.id}`}
              className={css`
                height: 41px;
                width: 100%;
              `}
            >
              Your Pledge Drives
            </Button>
          </div>
        )}
      </div>

      {Boolean(userPledgeDrives?.length > 0) && (
        <Slider>
          {userPledgeDrives.map(pledgeDrive => (
            <PledgeDrive key={pledgeDrive.id} pledgeDrive={pledgeDrive} />
          ))}
        </Slider>
      )}

      <LogoutModal isOpen={logoutModal} close={() => setLogoutModal(false)} />
    </Layout>
  );
};
