import React from 'react';
import { css } from 'emotion';
import { fonts } from 'helpers';
export default ({ children, ...props }) => {
  return (
    <label
      className={css`
        font-size: ${fonts.size.sm};
        font-family: ${fonts.fontFamily.secondary};
        ${props.className}
      `}
    >
      {children}
    </label>
  );
};
