import React from 'react';
import { css } from 'emotion';

import { Title, H } from 'components';
import { useHistory } from 'react-router-dom';
import { colors, fonts, media } from 'helpers';
import { GoArrowLeft } from 'react-icons/go';

export default ({ backTitle, ...props }) => {
  const history = useHistory();

  return (
    <Title backButton={true}>
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <button
          onClick={() => history.goBack()}
          className={css`
            width: 56px;
            height: 32px;
            border: 1px solid ${colors.white};
            border-radius: 10px;
            color: ${colors.white};
            background: transparent;
            font-family: ${fonts.fontFamily.primary};
            font-weight: ${fonts.fontWeight.black};
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: ${media.screen.sm}) {
              width: 30px;
              height: 20px;
              border-radius: 8px;
            }
          `}
        >
          <GoArrowLeft
            size={24}
            className={css`
              color: ${colors.white};
              vertical-align: bottom;
              @media (max-width: ${media.screen.sm}) {
                width: 16px;
                height: 16px;
              }
            `}
          />
        </button>
        {!!backTitle && (
          <H
            level="2"
            color="yellow"
            fontFamily="title"
            textShadow={true}
            className={css`
              max-width: 80%;
              font-size: 28px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;

              @media (max-width: ${media.screen.sm}) {
                font-size: 20px;
                max-width: 90%;
              }
            `}
          >
            {backTitle}
          </H>
        )}
      </div>
    </Title>
  );
};
