import React from 'react';
import { css } from 'emotion';
import { Modal } from 'components';

import { Button } from 'components';
import FilterListCharities from '../components/FilterLists/FilterListCharities';
import FilterListPledgedrives from '../components/FilterLists/FilterListPledgedrives';

import { colors, media } from 'helpers';

export default ({
  isOpen,
  close,
  resetFilters,
  type,
  filters,
  handleFilter
}) => {
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      className={css`
        overflow-y: scroll;
      `}
    >
      <Modal.Header black>Filter By</Modal.Header>
      <Modal.Content>
        <form
          className={css`
            color: ${colors.grey[45]};
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 14px;
            @media (max-width: ${media.screen.sm}) {
              padding: 0;
            }
          `}
        >
          {type === 'pledgeDrives' ? (
            <FilterListPledgedrives
              filters={filters}
              handleFilter={handleFilter}
            />
          ) : type === 'charities' ? (
            <FilterListCharities
              filters={filters}
              handleFilter={handleFilter}
            />
          ) : null}
        </form>
        <Button
          boxShadow={true}
          buttonType="modal"
          onClick={resetFilters}
          className={css`
            margin-bottom: 10px;

            @media (max-width: ${media.screen.md}) {
              margin-bottom: 10px;
              margin-top: 10px;
            }
          `}
        >
          Reset
        </Button>
        <Button
          boxShadow={true}
          buttonType="modal"
          onClick={() => {
            close();
          }}
          className={css`
            margin-top: 10px;
            margin-bottom: 10px;
            @media (max-width: ${media.screen.md}) {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          `}
        >
          OK
        </Button>
      </Modal.Content>
    </Modal>
  );
};
