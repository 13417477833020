import React from 'react';
import { css } from 'emotion';
import { ImageShuffle } from 'components';

export default function MediaSection({ images, completion = 100 }) {
  return (
    <div
      className={css`
        margin: 10px auto;
        max-width: 600px;
        height: 400px;
        padding: 0 10px;
      `}
    >
      <ImageShuffle images={images} completion={completion} />
    </div>
  );
}
