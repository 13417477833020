import React, { useContext, useEffect, useState } from 'react';

//api
import { getCharityCategories } from 'api/charityCategories.api';

//context
import { ToastContext } from 'context/Toast.context';

//dom
import { useRouteMatch } from 'react-router-dom';
import { Button, Input, Label, TextArea, Select } from 'components';
import { css } from 'emotion';
import {
  getCharity,
  updateCharity,
  updateCover,
  updateLogo
} from 'api/charities.api';
export default props => {
  const { pushMessage } = useContext(ToastContext);
  const match = useRouteMatch();
  const charityId = match.params.id;

  const [charity, setCharity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newLogoDataUrl, setNewLogoDataUrl] = useState(null);
  const [newLogo, setNewLogo] = useState(null);
  const [newCoverDataUrl, setCoverDataUrl] = useState(null);
  const [newCover, setCover] = useState(null);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tagline, setTagline] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [donationUrl, setDonationUrl] = useState('');
  const [charityCategories, setCharityCategories] = useState([]);
  const [charityCategoryId, setCharityCategoryId] = useState('');
  const [archived, setArchived] = useState(false);

  useEffect(() => {
    if (!charity) return;
    setName(charity.name);
    setDescription(charity.description || '');
    setTagline(charity.tagline || '');
    setWebsiteUrl(charity.websiteUrl || '');
    setDonationUrl(charity.donationUrl || '');
    setCharityCategoryId(charity.charityCategoryId || '');
    setArchived(charity.archived);
  }, [charity]);

  useEffect(() => {
    async function async() {
      try {
        const res = await getCharity({ charityId });
        setCharity(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage, charityId]);

  //get charity categories list
  useEffect(() => {
    const setStat = async e => {
      try {
        const res = await getCharityCategories();
        setCharityCategories(res);
      } catch (e) {}
    };
    setStat();
  }, [pushMessage]);

  //jpdate
  const update = async e => {
    e.preventDefault();
    try {
      const res = await updateCharity({
        charityId,
        charityInfo: {
          name,
          description,
          tagline,
          websiteUrl,
          donationUrl,
          charityCategoryId,
          archived
        }
      });
      setCharity(res);
      pushMessage.success('Charity updated.');
    } catch (e) {
      pushMessage.error(e.message);
    }
  };

  const onFileSelectLogo = e => {
    if (!e.target.files || !e.target.files[0]) return;
    const reader = new FileReader();
    reader.onload = () => setNewLogoDataUrl(reader.result);
    reader.readAsDataURL(e.target.files[0]);
    setNewLogo(e.target.files[0]);
  };

  const onFileSelectCover = e => {
    if (!e.target.files || !e.target.files[0]) return;
    const reader = new FileReader();
    reader.onload = () => setCoverDataUrl(reader.result);
    reader.readAsDataURL(e.target.files[0]);
    setCover(e.target.files[0]);
  };

  async function handleUpdateLogo() {
    const data = new FormData();
    data.append('logo', newLogo);
    setLoading(true);

    try {
      const res = await updateLogo({ charityId, data });
      setCharity(res);
      setNewLogo(null);
      setNewLogoDataUrl(null);
      pushMessage.success('Logo uploaded.');
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateCover() {
    const data = new FormData();
    data.append('cover', newCover);
    setLoading(true);

    try {
      const res = await updateCover({ charityId, data });
      setCharity(res);
      setCover(null);
      setCoverDataUrl(null);
      pushMessage.success('Cover uploaded.');
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  }

  if (!charity) return <div>Charity Not Found.</div>;

  return (
    <div>
      <form onSubmit={update}>
        <Label>Name</Label>
        <Input
          marginBottom={8}
          onChange={e => setName(e.target.value)}
          value={name}
          required
        />
        <Label>Description</Label>
        <TextArea
          marginBottom={8}
          onChange={e => setDescription(e.target.value)}
          value={description}
        />
        <Label>Tagline</Label>
        <TextArea
          marginBottom={8}
          onChange={e => setTagline(e.target.value)}
          value={tagline}
        />
        <Label>Website URL</Label>
        <Input
          marginBottom={8}
          onChange={e => setWebsiteUrl(e.target.value)}
          value={websiteUrl}
        />
        <Label>Donation URL</Label>
        <Input
          marginBottom={8}
          onChange={e => setDonationUrl(e.target.value)}
          value={donationUrl}
        />
        <Label>Charity Category</Label>
        <Select
          marginBottom={8}
          value={charityCategoryId}
          onChange={e => setCharityCategoryId(e.target.value)}
          options={charityCategories.map(charityCategory => ({
            value: charityCategory.id,
            label: charityCategory.name
          }))}
          required
        />
        <Label>Archived</Label>
        <Select
          marginBottom={8}
          value={archived}
          onChange={e => setArchived(e.target.value)}
          options={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]}
        />

        <Button
          submit={true}
          className={css`
            margin-bottom: 10px;
          `}
        >
          Save Changes
        </Button>
      </form>

      <h2>Charity Logo</h2>
      {Boolean(charity.logoUrl || newLogoDataUrl) && (
        <img
          style={{ width: 200, display: 'block' }}
          src={newLogoDataUrl || charity.logoUrl}
          alt={charity.name}
        />
      )}
      {Boolean(newLogo) && (
        <Button disabled={loading} onClick={handleUpdateLogo}>
          Save Logo
        </Button>
      )}
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg, image/webp"
        onChange={onFileSelectLogo}
      />

      <h2
        className={css`
          margin-top: 12px;
          padding-top: 12px;
          border-top: solid black 1px;
        `}
      >
        Charity Cover
      </h2>
      {Boolean(charity.coverUrl || newCoverDataUrl) && (
        <img
          className={css`
            margin-top: 12px;
          `}
          style={{ width: 200, display: 'block' }}
          src={newCoverDataUrl || charity.coverUrl}
          alt={charity.name}
        />
      )}
      {Boolean(newCover) && (
        <Button disabled={loading} onClick={handleUpdateCover}>
          Save Cover
        </Button>
      )}
      <input
        className={css`
          margin-top: 12px;
        `}
        type="file"
        accept="image/png, image/gif, image/jpeg, image/webp"
        onChange={onFileSelectCover}
      />
    </div>
  );
};
