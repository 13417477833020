import React from 'react';
import { css } from 'emotion';
import { colors, zIndex } from 'helpers';

export default ({ error = true, ...props }) => {
  return (
    <p
      {...props}
      className={css`
        ${props.className};
        font-size: 13px;
        border: solid 1px ${error ? colors.redError : colors.grey[75]};
        background-color: ${error ? colors.redErrorLight : colors.grey[90]};
        padding: 8px;
        margin: 10px 0;
        position: relative;
        z-index: ${zIndex.layer103.toastMessage};
      `}
    >
      {props.children}
    </p>
  );
};
