import React, { forwardRef } from 'react';
import { css } from 'emotion';
import { colors, media } from 'helpers';

export default forwardRef(
  ({ itemLength, children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: ${colors.white};
        `}
      >
        <div
          className={css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100vw;
          flex-wrap: wrap;
          ${className}
          @media (max-width: ${media.screen.lg}) {
            flex-direction: column;
          }
        `}
        >
          {children}
        </div>
      </div>
    );
  }
);
