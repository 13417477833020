import React, { useState, useEffect } from 'react';
import { css } from 'emotion';

import { colors, zIndex, effect } from 'helpers';
import { Button, P, Link } from 'components';

export default () => {
  const [showInstall, setShowInstall] = useState(false);

  function close() {
    localStorage.setItem('IOSInstall', 'shown');
    setShowInstall(false);
  }

  useEffect(() => {
    if (localStorage.getItem('IOSInstall') === 'shown') {
      setShowInstall(false);
      return;
    }
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      'standalone' in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      if (!localStorage.getItem('IOSInstall')) {
        setShowInstall(true);
      }
    }
  }, [showInstall]);

  return (
    <>
      {showInstall ? (
        <div
          className={css`
            position: fixed;
            width: 95%;
            margin-left: 2.5%;
            padding: 5px;
            bottom: 10px;
            border-radius: 12px;
            background-color: ${colors.white};
            z-index: ${zIndex.layer15.IOS};
            display: flex;
            align-items: center;
            justify-content: center;
            ${effect.boxShadowSm}
          `}
        >
          <div>
            <P color="black" size="xxs">
              For a better experience save this app to your home screen. <br />
              <br />
              Press the "Share" button and select "Add to Home Screen" from the
              popup.
              <br />
              For more information:
            </P>
            <Link
              to="PWA"
              onClick={() => {
                close();
              }}
              className={css`
                margin-left: 10px;
              `}
            >
              Click here
            </Link>
          </div>
          <Button
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </div>
      ) : (
        <div />
      )}
    </>
  );
};
