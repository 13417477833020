import React from 'react';
import { css } from 'emotion';
import { colors, fonts, media } from 'helpers';
export default ({ title, stat, className, ...props }) => {
  return (
    <div
      {...props}
      className={css`
        flex: 1 1 0;
        color: ${colors.white};
        padding: 21px 6px;
        background-color: ${colors.purple};
        text-align: center;
        font-family: ${fonts.fontFamily.primary};
        font-size: ${fonts.size.xs};
        border-radius: 12px;
        max-width: 163px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 65px;
        ${className}

        @media (min-width: ${media.screen.lg}) {
          font-size: ${fonts.size.md};
          margin-right: 26px;
          max-width: none;
        }

        @media (max-width: ${media.screen.sm}) {
          font-size: ${fonts.size.xxs};
          padding: 8px;
          margin-right: 2px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      `}
    >
      <span>{title}</span>
      <br />
      <strong>{stat}</strong>
    </div>
  );
};
