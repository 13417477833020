import React from 'react';
import { css } from 'emotion';
import { Modal, Button } from 'components';

import SortByButtonComponent from '../components/SortByButton.component';
import { colors, media } from 'helpers';

export default ({
  isOpen,
  close,
  resetSortByFilters,
  sortByFilters,
  handleSortByFilter
}) => {
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      className={css`
        overflow-y: scroll;
      `}
    >
      <Modal.Header black>Sort By</Modal.Header>
      <Modal.Content>
        <form
          className={css`
            color: ${colors.grey[45]};
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 14px;
            @media (max-width: ${media.screen.sm}) {
              padding: 0;
            }
          `}
        >
          <SortByButtonComponent
            text="Completion"
            field="progress"
            sortByFilters={sortByFilters}
            handleClick={handleSortByFilter}
          />
          <SortByButtonComponent
            text="Item Value"
            field="retailValue"
            sortByFilters={sortByFilters}
            handleClick={handleSortByFilter}
          />
        </form>
        <Button
          boxShadow={true}
          buttonType="modal"
          onClick={resetSortByFilters}
          className={css`
            margin-bottom: 10px;

            @media (max-width: ${media.screen.md}) {
              margin-bottom: 10px;
              margin-top: 10px;
            }
          `}
        >
          Reset
        </Button>
        <Button
          boxShadow={true}
          buttonType="modal"
          onClick={close}
          className={css`
            margin-top: 10px;
            margin-bottom: 10px;
            @media (max-width: ${media.screen.md}) {
              margin-top: 10px;
              margin-bottom: 10px;
            }
          `}
        >
          OK
        </Button>
      </Modal.Content>
    </Modal>
  );
};
