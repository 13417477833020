import React from 'react';
import { Button, P, H } from 'components';

export default ({ userInfo, handleSendCode, loading }) => {
  return (
    <>
      <H level="2" fontFamily="secondary">
        Your phone is unverified!
      </H>
      <br />
      <br />
      <P>
        We can’t process your subscription until we verify your phone number.
      </P>
      <br />
      <br />
      <P weight="bold">
        Please verify your phone number. We will send a verification code to
        your phone number ending in {userInfo?.phone?.slice(-4)}
      </P>
      <Button
        boxShadow={true}
        buttonType="modal"
        onClick={handleSendCode}
        disabled={loading}
      >
        Get Code
      </Button>
    </>
  );
};
