import React from 'react';
import { css } from 'emotion';
import { colors } from 'helpers';

export default ({
  children,
  gradient,
  color,
  backgroundImage,
  height,
  border,
  alignment,
  justify,
  className
}) => {
  return (
    <>
      <div
        className={css`
          overflow: hidden;
          height: ${height ? height : '80vh'};
          display: flex;
          flex-direction: column;
          align-items: ${alignment ? alignment : 'center'};
          justify-content: ${justify ? justify : 'space-around'};
          background: linear-gradient(${gradient}),
            url(${backgroundImage}) no-repeat center, center;
          background-color: ${colors[color]};
          background-size: cover;
          border-radius: ${border ? border : null};
          ${className}
        `}
      >
        {children}
      </div>
    </>
  );
};
