import React from 'react';
import { css } from 'emotion';
import { H } from 'components';

export default ({ name, image, className }) => {
  return (
    <div
      className={css`
        height: 260px;
        width: 360px;
        max-width: 80vw;
        border-radius: 12px;
        margin: 20px 20px;
        background-image: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.5) 5%,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0) 60%,
            rgba(0, 0, 0, 0.5) 95%,
            rgba(0, 0, 0, 0.8) 100%
          ),
          url(${image});
        background-size: cover;
        background-position: center;
        ${className}
      `}
    >
      <H
        size="sm"
        color="white"
        className={css`
          padding: 12px;
        `}
      >
        {name}
      </H>
    </div>
  );
};
