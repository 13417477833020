import React from 'react';

// style
import { css } from 'emotion';
import { colors, zIndex } from 'helpers';

// components
import { Animation, P } from 'components';

export default function OnPledgeAnimation({ showAnimation, file }) {
  return (
    <div
      className={css`
        z-index: ${zIndex.layer2.pledgeAnim};
        opacity: ${showAnimation ? '1' : '0'};
        display: flex;
        justify-content: center;
        align-items: center;
        transform: ${showAnimation
          ? 'translate(-40px, 70px)'
          : 'translate(150px, 70px)'};
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) 700ms;
      `}
    >
      <Animation
        lottieFile={file}
        speed={2}
        loop={true}
        className={css`
          max-width: 120px;
          max-height: 120px;
        `}
      />
      <div
        className={css`
          background-color: ${colors.yellow};
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          transform: translateX(-10px);
          :before {
            content: '';
            width: 0px;
            height: 0px;
            border-bottom-left-radius: 12px;
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 16px solid ${colors.yellow};
            border-bottom: 12px solid ${colors.yellow};
            right: 100px;
            top: 0;
          }
        `}
      >
        <P
          color="black"
          fontFamily="secondary"
          size="sm"
          className={css`
            display: flex;
            margin-top: 5px;
            line-height: normal;
            font-weight: 600;
          `}
        >
          Pledge Made!
        </P>
      </div>
    </div>
  );
}
