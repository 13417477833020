import React, { useState, useContext } from 'react';
import { css } from 'emotion';
import { Button, Form, TextInput, FormBox } from 'components';
import { ToastContext } from 'context/Toast.context';
import NumberFormat from 'react-number-format';

export default ({ handleSignUp, loading, isUnsubscribed }) => {
  const { pushMessage } = useContext(ToastContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  function submitForm(e) {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid)
      handleSignUp({
        firstName,
        lastName,
        phone,
        email,
        password,
        isUnsubscribed
      });
  }
  function validateForm() {
    try {
      if (password !== password2) throw Error('Passwords do not match');
      else return true;
    } catch (e) {
      pushMessage.error(e.message);
      return false;
    }
  }

  return (
    <Form
      handleSubmit={e => {
        submitForm(e);
      }}
      className={css`
        height: 100%;
      `}
    >
      <FormBox>
        <TextInput
          name="firstName"
          value={firstName}
          onChange={e => {
            setFirstName(e.target.value);
          }}
          placeholder="First Name"
          required={true}
        />

        <TextInput
          name="lastName"
          value={lastName}
          onChange={e => {
            setLastName(e.target.value);
          }}
          placeholder="Last Name"
          required={true}
        />

        <TextInput
          name="email"
          type="email"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          placeholder="E-mail"
          required={true}
        />

        <TextInput
          name="password"
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          placeholder="Password"
          required={true}
        />

        <TextInput
          name="password2"
          type="password"
          value={password2}
          onChange={e => {
            setPassword2(e.target.value);
          }}
          placeholder="Re-Type Password"
          required={true}
        />

        <NumberFormat
          name="phone"
          customInput={TextInput}
          placeholder="Phone Number"
          type="tel"
          marginBottom={8}
          onChange={e => setPhone(e.target.value)}
          value={phone}
          required
          format="(###) ###-####"
          mask="_"
        />
      </FormBox>
      <Button
        boxShadow={true}
        buttonType="modal"
        submit={true}
        disabled={loading}
      >
        Continue
      </Button>
    </Form>
  );
};
