import React, { useState, useContext, useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation,
  Redirect
} from 'react-router-dom';
import { ToastContext } from 'context/Toast.context';
import { UserContext } from 'context/User.context';
import { PledgesContext } from 'context/Pledges.context';
import { getPledges } from 'api/pledges.api';

import HomeRoute from 'routes/Home/Home.route';
import AboutRoute from 'routes/About/About.route';
import TOSRoute from './TOS/TOS.route';
import ContestRules from './ContestRules/ContestRules.route';
import PrivacyPolicyRoute from './PrivacyPolicy/PrivacyPolicy.route';
import PhoneVerificationRoute from 'routes/PhoneVerification/PhoneVerification.route';
import PaymentInfoRoute from 'routes/PaymentInfo/PaymentInfo.route';
import SubscribeRoute from './Subscribe/Subscribe.route';
import UnsubscribeRoute from './Unsubscribe/Unsubscribe.route';
import PledgeDrivesRoute from 'routes/PledgeDrives/PledgeDrives.route';
import PledgeDriveRoute from 'routes/PledgeDrive/PledgeDrive.route';
import ForgotPasswordRoute from 'routes/ForgotPassword/ForgotPassword.route';
import ResetPasswordRoute from 'routes/ResetPassword/ResetPassword.route';
import MyProfileRoute from './MyProfile/MyProfile.route';
import EditProfileRoute from './EditProfile/EditProfile.route';
import AdminPageRoute from './Admin';
import NotFoundRoute from './NotFound/NotFound.route';
import CharitiesRoute from './Charities/Charities.route';
import CharityRoute from './Charities/Charity.route';
import CompletedDrivesRoute from './CompletedDrives/CompletedDrives.route';
import FeedbackFormRoute from './FeedbackForm/FeedbackForm.route';

import { AdminRoute, ProtectedRoute, AcceptCookies } from 'components';
import { getUserInfo } from 'api/users.api';
import PWARoute from './PWA/PWA.route';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => window.scrollTo(0, 0), [pathname]);
  return null;
};

export default () => {
  const { pushMessage } = useContext(ToastContext);
  const { user, setUser } = useContext(UserContext);
  const { setPledges } = useContext(PledgesContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function async() {
      if (!window.localStorage.getItem('token')) {
        return setLoading(false);
      }

      try {
        const res = await getUserInfo();
        setUser(res);
      } catch (e) {
        pushMessage.error(e.message);
        window.localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    }
    async();
  }, [pushMessage, setUser]);

  useEffect(() => {
    if (!user) return;
    async function asyncFunc() {
      const pledges = await getPledges({ userId: user.id });
      setPledges(pledges);
    }
    asyncFunc();
  }, [user, setPledges]);

  if (loading) return null;

  return (
    <AcceptCookies>
      <BrowserRouter>
        <ScrollToTop />

        <Switch>
          <Route path="/" exact component={HomeRoute} />
          <Route path="/about" exact component={AboutRoute} />
          <Route path="/tos" exact component={TOSRoute} />
          <Route path="/contest-rules" exact component={ContestRules} />
          <Route path="/privacy-policy" exact component={PrivacyPolicyRoute} />
          <AdminRoute path="/admin" component={AdminPageRoute} />
          <Route
            path="/forgot-password"
            exact
            component={ForgotPasswordRoute}
          />
          <Route path="/reset-password" exact component={ResetPasswordRoute} />
          <ProtectedRoute
            path="/phone-verification"
            exact
            component={PhoneVerificationRoute}
          />
          <ProtectedRoute
            path="/payment-info"
            exact
            component={PaymentInfoRoute}
          />
          <ProtectedRoute path="/subscribe" exact component={SubscribeRoute} />
          <ProtectedRoute
            path="/unsubscribe"
            exact
            component={UnsubscribeRoute}
          />
          <ProtectedRoute path="/my-account" exact component={MyProfileRoute} />
          <ProtectedRoute
            path="/edit-account"
            exact
            component={EditProfileRoute}
          />
          <Route
            path="/shop"
            exact
            component={() => {
              window.location.href = 'https://pledgellama.myshopify.com/';
              return null;
            }}
          />
          <Route
            path="/completed-drives"
            exact
            component={CompletedDrivesRoute}
          />
          <ProtectedRoute path="/charities" exact component={CharitiesRoute} />
          <ProtectedRoute
            path="/charities/:id"
            exact
            component={CharityRoute}
          />
          <ProtectedRoute
            path="/pledge-drives"
            exact
            component={PledgeDrivesRoute}
          />
          <Route path="/pledge-drives/:id" exact component={PledgeDriveRoute} />
          <Route path="/feedback" exact component={FeedbackFormRoute} />
          <Route path="/PWA" exact component={PWARoute} />
          <Route path="/404" exact component={NotFoundRoute} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    </AcceptCookies>
  );
};
