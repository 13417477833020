import React from 'react';
import { css } from 'emotion';
import { fonts, colors } from 'helpers';

export default ({
  children,
  size = 'md',
  color = 'black',
  weight = 'normal',
  fontFamily = 'secondary',
  lineHeight = 'md',
  className
}) => {
  return (
    <p
      className={css`
        font-family: ${fonts.fontFamily[fontFamily]};
        font-size: ${fonts.size[size]};
        font-weight: ${fonts.fontWeight[weight]};
        line-height: ${fonts.lineHeight[lineHeight]};
        color: ${colors[color]};
        margin-left: 10px;
        margin-right: 10px;
        ${className};
      `}
    >
      {children}
    </p>
  );
};
