import http from 'helpers/http.helper';

export const getCharityCategories = ({ withArchived = false } = {}) => {
  return http().get('/charity-categories', {
    params: {
      withArchived: withArchived
    }
  });
};

export const createCharityCategory = ({ name } = {}) => {
  return http().post('/charity-categories', {
    name
  });
};
