import React from 'react';
import { css } from 'emotion';
import { colors, fonts, media } from 'helpers';
export default ({ children, className }) => {
  return (
    <div
      className={css`
        font-family: ${fonts.fontFamily.primary};
        font-size: ${fonts.size.xs};
        font-weight: ${fonts.fontWeight.bold};
        color: ${colors.purple};
        border-bottom: 1px solid ${colors.purple};
        width: 100%;
        margin-bottom: 28px;
        ${className}

        @media (max-width: ${media.screen.sm}) {
            font-size: 11px;
          }
      `}
    >
      {children}
    </div>
  );
};
