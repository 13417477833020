import React, { useContext } from 'react';
import { css } from 'emotion';
import { H } from 'components';
import { UserContext } from 'context/User.context';
import { colors, media, string } from 'helpers';

import Logo from 'assets/global/logo.png';

export default function WinnerSection({
  pledgeDriveName,
  winnerImage,
  winnerName,
  winnerId
}) {
  const { user } = useContext(UserContext);

  return (
    <div
      className={css`
        margin-top: 10px;
        display: flex;
        height: 180px;
        background-color: ${colors.green};
        padding: 10px;
        @media (max-width: ${media.screen.sm}) {
          padding: 5px;
          height: 100%;
        }
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 70%;
        `}
      >
        {winnerId === user.id
          ? Boolean(pledgeDriveName) && (
              <H weight="heavy" color="white">
                You won a{' '}
                {pledgeDriveName
                  ? string.toTitleCase(pledgeDriveName) + '!'
                  : ''}
              </H>
            )
          : Boolean(winnerName && pledgeDriveName) && (
              <H weight="heavy" color="white">
                {winnerName ? string.toTitleCase(winnerName) : ' '} won a{' '}
                {pledgeDriveName
                  ? string.toTitleCase(pledgeDriveName) + '!'
                  : ''}
              </H>
            )}
      </div>

      <img
        src={winnerImage ? winnerImage : Logo}
        alt={`winner of ` + pledgeDriveName}
        width="30%"
        className={css`
          object-fit: ${winnerImage ? 'cover' : 'contain'};
          padding: ${winnerImage ? '0' : '20px'};
        `}
      />
    </div>
  );
}
