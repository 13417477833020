import React, { useState, useContext } from 'react';
import { css } from 'emotion';

import { colors, effect, fonts } from 'helpers';

import { Button, CompletionBar } from 'components';
import { GoChevronRight, GoChevronLeft } from 'react-icons/go';
import { UserContext } from 'context/User.context';

export default ({ images, completion = 0, raised }, ...props) => {
  const { user } = useContext(UserContext);

  const [animationIndex, setAnimationIndex] = useState(0);

  const slideRight = () => {
    if (animationIndex < images.length - 1) {
      setAnimationIndex(prevIndex => prevIndex + 1);
    } else {
      setAnimationIndex(0);
    }
  };

  const slideLeft = () => {
    if (animationIndex > 0) {
      setAnimationIndex(prevIndex => prevIndex - 1);
    } else {
      setAnimationIndex(images.length - 1);
    }
  };

  return (
    <div
      className={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
      `}
    >
      {/* image container  */}
      <div
        className={css`
          overflow: hidden; // hide image overflow, but not the completion bar 👀
          border-radius: 12px;
          width: 88%;

          ${effect.boxShadowSm}
        `}
      >
        {/* image  */}
        <div
          className={css`
            transition: 400ms;
            transform: translateX(${-100 * animationIndex + '%'});
            display: flex;
          `}
        >
          {Boolean(images.length > 0) &&
            images.map((image, index) => (
              <img
                key={image}
                src={image}
                alt={`item preview ` + index}
                className={css`
                  object-fit: cover;
                  width: 100%;
                  border-radius: 12px;
                `}
              />
            ))}
        </div>
      </div>
      {/* buttons */}
      {Boolean(images.length > 1) && (
        <Button
          onClick={slideLeft}
          boxShadow={true}
          className={css`
            position: absolute;

            top: calc(50% - 40px);
            left: 10px;
            height: 60px;
            width: 40px;
            user-select: none;
          `}
        >
          <GoChevronLeft size={40} />
        </Button>
      )}
      {Boolean(images.length > 1) && (
        <Button
          onClick={slideRight}
          boxShadow={true}
          className={css`
            position: absolute;
            top: calc(50% - 40px);
            right: 10px;
            height: 60px;
            width: 40px;
            user-select: none;
          `}
        >
          <GoChevronRight size={40} />
        </Button>
      )}
      {/* progress bar */}
      {user?.subscriptionActive ? (
        <div
          className={css`
            width: 88%;
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: ${colors.white};
            border-radius: 12px;
            ${effect.boxShadowSm}
          `}
        >
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-content: center;
              background-color: #fff;
              border-radius: 5px;
              padding: 4px;
              margin-bottom: 12px;
              font-size: ${fonts.size.xs};
              font-family: ${fonts.fontFamily.secondary};
              font-weight: 200;
              ${effect.boxShadowSm}
            `}
          >
            {raised
              ? raised
              : completion === 100
              ? 'Complete!'
              : completion + `% Complete`}
          </div>
          <CompletionBar
            completion={raised ? raised : completion}
            className={css`
              width: 88%;
              margin-bottom: 8px;
              ${effect.boxShadowXsm}
            `}
          />
        </div>
      ) : null}
    </div>
  );
};
