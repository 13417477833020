import React, { useEffect, useState, useContext } from 'react';

import { Layout } from 'components';

import { useParams } from 'react-router-dom';

import { getCharity } from 'api/charities.api';
import { getPledgeDrives } from 'api/pledgeDrives.api';

import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';

import { Slider, PledgeDrive } from 'components';
import CoverAndStats from './components/CoverAndStats.component';
import CopySection from './components/CopySection.component';

export default () => {
  const { user } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const { id } = useParams();
  const [charity, setCharity] = useState();
  const [pledgeDrives, setPledgeDrives] = useState([]);
  const [completedDrives, setCompletedDrives] = useState(0);

  //charity
  useEffect(() => {
    async function fetchCharity() {
      try {
        const res = await getCharity({ charityId: id });
        setCharity(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    fetchCharity();
  }, [id, pushMessage]);

  //charity pledge drives
  useEffect(() => {
    async function fetchCharityPledgeDrive() {
      try {
        const res = await getPledgeDrives({
          charityId: id,
          withArchived: true,
          active: false
        });

        setCompletedDrives(
          res.filter(pledgeDrive => (!pledgeDrive.archived ? null : true))
        );

        setPledgeDrives(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    fetchCharityPledgeDrive();
  }, [id, pushMessage]);

  return (
    <Layout backTitle={charity?.name} showPledgesAvailable={false}>
      {Boolean(charity) && (
        <>
          <CoverAndStats
            coverUrl={charity.coverUrl}
            logoUrl={charity.logoUrl}
            name={charity.name}
            completedDrivesTotal={completedDrives?.length}
          />
          {/* donation and site button section */}
          <CopySection
            description={charity.description}
            tagline={charity.tagline}
            websiteUrl={charity.websiteUrl}
            donationUrl={charity.donationUrl}
          />

          {/* Charity Pledge Drives */}
          {Boolean(user && pledgeDrives) && (
            <Slider>
              {pledgeDrives?.map(pledgeDrive => (
                <PledgeDrive key={pledgeDrive.id} pledgeDrive={pledgeDrive} />
              ))}
            </Slider>
          )}
        </>
      )}
    </Layout>
  );
};
