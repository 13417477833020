import React, { useState, useContext, useCallback } from 'react';
import { css } from 'emotion';
import { Modal, Message } from 'components';
import { UserContext } from 'context/User.context';
import { ToastContext } from 'context/Toast.context';
import { signUp, sendCode, verifyPhone } from 'api/users.api.js';

import SignUpTOS from './components/SignUpTOS.component';
import SignUpInfo from './components/SignUpInfo.component';
import SignUpSendPhoneCode from './components/SignUpSendPhoneCode.component';
import SignUpVerifyPhone from './components/SignUpVerifyPhone.component';
import SignUpPaymentInfo from './components/SignUpPaymentInfo.component';
import SignUpSubscribe from './components/SignUpSubscribe.component';
import { useHistory } from 'react-router-dom';

export default ({ isOpen, close, startAt = 0, isUnsubscribed = false }) => {
  const FINAL_SLIDE = 5;
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const { pushMessage } = useContext(ToastContext);
  const [userInfo, setUserInfo] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [slideNumber, setSlideNumber] = useState(startAt);
  const [codeSent, setCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  function next() {
    setLoading(false);
    setError('');
    if (slideNumber + 1 > FINAL_SLIDE) close();
    else setSlideNumber(slideNumber + 1);
  }

  const handleClose = useCallback(() => {
    if (slideNumber < 2) {
      setSlideNumber(startAt);
      setLoading(false);
      setError('');
      close();
    }
  }, [close, slideNumber, startAt]);

  async function handleSignUp(userData) {
    setLoading(true);
    try {
      const res = await signUp({ userData });
      setUserInfo(res);
      window.localStorage.setItem('token', res.token);
      next();
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  const handleGetCode = async (e, goToNext = true) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await sendCode();
      pushMessage.success(res.message);
      setCodeSent(true);
      if (goToNext) next();
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyPhone = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await verifyPhone(verificationCode);
      if (isUnsubscribed) {
        setUser(res);
        history.push('/');
        close();
      } else {
        setUserInfo(res);
        next();
      }
    } catch (e) {
      pushMessage.error(e.message);
    } finally {
      setCodeSent(false);
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      close={() => {
        handleClose();
      }}
      className={css`
        @media screen and (max-height: 680px) {
          overflow-y: scroll;
        }
      `}
    >
      <Modal.Header>New Llama</Modal.Header>
      <Modal.Content>
        {error && <Message error>{error}</Message>}
        {slideNumber === 0 && <SignUpTOS next={next} />}
        {slideNumber === 1 && (
          <SignUpInfo
            handleSignUp={e => {
              handleSignUp(e);
            }}
            isUnsubscribed={isUnsubscribed}
            loading={loading}
          />
        )}
        {slideNumber === 2 && (
          <SignUpSendPhoneCode
            handleSendCode={handleGetCode}
            codeSent={codeSent}
            userInfo={user || userInfo}
            loading={loading}
          />
        )}
        {slideNumber === 3 && (
          <SignUpVerifyPhone
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            handleResendCode={e => handleGetCode(e, false)}
            codeSent={codeSent}
            handleVerify={e => {
              handleVerifyPhone(e);
            }}
            loading={loading}
          />
        )}
        {Boolean(slideNumber === 4) && (
          <SignUpPaymentInfo
            setUserInfo={setUserInfo}
            setError={setError}
            next={next}
          />
        )}
        {Boolean(slideNumber === 5) && (
          <SignUpSubscribe
            next={() => {
              setUser(userInfo);
              next();
            }}
            loading={loading}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};
