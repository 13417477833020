import React, { useContext, useEffect, useState } from 'react';
import { numberFormatter } from 'helpers';
import { ToastContext } from 'context/Toast.context';
import { getPledgeDrives } from 'api/pledgeDrives.api';

export default props => {
  const { pushMessage } = useContext(ToastContext);
  const [pledgeDrives, setPledgeDrives] = useState([]);

  useEffect(() => {
    async function async() {
      try {
        const res = await getPledgeDrives({
          sortBy: 'name',
          withArchived: true,
          active: false
        });
        setPledgeDrives(res);
      } catch (e) {
        pushMessage.error(e.message);
      }
    }
    async();
  }, [pushMessage]);

  return (
    <div>
      {pledgeDrives
        .filter(pd => pd.winnerId)
        .map(pledgedrive => (
          <div key={pledgedrive.id}>
            <p>
              <h3>Name: {pledgedrive.name}</h3>
              <br />
              Description: {pledgedrive.description}
              <br />
              Retail:{' '}
              {numberFormatter.currencyFormatter.format(
                pledgedrive.retailValue
              )}
              <br />
              Charity: {pledgedrive.charityName}
              <br />
              Winner: {pledgedrive.winnerName}
              <br />
              Progress: {Math.floor(pledgedrive.progress * 100)}%<br />
            </p>
            <hr />
          </div>
        ))}
    </div>
  );
};
