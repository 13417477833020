import React, { useContext, useState } from 'react';

import { css } from 'emotion';
import { effect } from 'helpers';
import { ModalInfo } from 'components';
import { Button, H, P, Animation } from 'components';

import { acceptPrize } from 'api/pledgeDrives.api';
import { ToastContext } from 'context/Toast.context';
import KazooLlama from 'assets/Lottie/Kazoo.json';

export default ({ isOpen, close, pledgeDrive }) => {
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { pushMessage } = useContext(ToastContext);
  const id = pledgeDrive?.id;

  async function handleAcceptPrize() {
    setLoading(true);
    try {
      await acceptPrize({ id });
      setAccepted(true);
    } catch (e) {
      pushMessage.error(e.message);
    }
    setLoading(false);
  }
  return (
    <ModalInfo align="center" isOpen={isOpen} close={close}>
      <H
        fontFamily="title"
        weight="heavy"
        color="white"
        className={css`
          padding-top: 30px;
          text-align: center;
          ${effect.boxShadowXsm}
        `}
      >
        You Won!
      </H>
      <div
        className={css`
          display: flex;
          align-content: center;
          justify-content: center;
        `}
      >
        <Animation
          lottieFile={KazooLlama}
          className={css`
            width: 50%;
          `}
        />
      </div>
      <P
        weight="heavy"
        color="white"
        className={css`
          padding: 15px 0;
          text-align: center;
          ${effect.boxShadowXsm}
        `}
      >
        {['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u'].some(i =>
          pledgeDrive?.name?.startsWith(i)
        )
          ? 'An'
          : 'A'}{' '}
        {pledgeDrive?.name}
      </P>
      {accepted ? (
        <P
          size="sm"
          color="white"
          weight="black"
          lineHeight="lg"
          className={css`
            width: 80%;
            text-align: center;
          `}
        >
          Our Team Will Be In Touch with you shortly To get info like your
          address To deliver your prize!
        </P>
      ) : (
        <div
          className={css`
            height: 60px;
          `}
        >
          <Button disabled={loading} onClick={() => handleAcceptPrize()}>
            Accept Your Prize
          </Button>
        </div>
      )}
    </ModalInfo>
  );
};
