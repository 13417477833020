import React from 'react';
import { css } from 'emotion';

import { colors } from 'helpers';

export default ({ children, ...props }) => {
  return (
    <div
      className={css`
        color: ${colors.grey[45]};
        display: flex;
        flex-direction: column;
        border: 2px solid black;
        border-radius: 12px;
        width: 100%;
        padding: 47px 14px;
        ${props.className}
      `}
    >
      {children}
    </div>
  );
};
