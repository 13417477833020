import React from 'react';
import { css } from 'emotion';

import { H, P } from 'components';

export default ({ stepNo, imgSrc, imgAlt, text }) => {
  return (
    <div
      className={css`
        margin-top: 10px;
        margin-bottom: 10px;
      `}
    >
      {Boolean(imgSrc) && (
        <img
          src={imgSrc}
          alt={imgAlt}
          className={css`
            border-radius: 20px;
            max-width: 100%;
          `}
        />
      )}
      <br />
      <br />
      <H level="3" fontFamily="title" color="purple">
        {stepNo}
      </H>
      <br />
      <P>{text}</P>
      <br />
    </div>
  );
};
