import React from 'react';
import { css } from 'emotion';

import { colors, fonts } from 'helpers';

import dancingLlama from 'assets/UI/Stills-dancing.svg';

import { Layout, H, P, ColorBar } from 'components';

export default props => {
  return (
    <Layout backTitle="About">
      <div
        className={css`
          padding-top: 50px;
          margin-bottom: -10px;
          background-color: ${colors.white};
          align-self: flex-start;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          display: flex;
        `}
      >
        <div
          className={css`
            padding-top: 40px;
            padding-bottom: 20px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            display: flex;
          `}
        >
          <div
            className={css`
              background-color: ${colors.yellow};
              padding: 70px 13px;
              border-radius: 12px;
              max-width: 344px;
              margin-left: 42px;
              margin-right: 42px;
            `}
          >
            <H
              level="3"
              color="black"
              weight="bold"
              size="md"
              className={css`
                text-align: center;
                font-family: ${fonts.fontFamily.secondary};
                margin-left: 0;
                margin-right: 0;
                line-height: 1.5em;
              `}
            >
              PledgeLlama is the first flagship product coming from the IceBoxx
              software development group.
            </H>
          </div>
          <P
            size="sm"
            color="black"
            weight="bold"
            className={css`
              text-align: center;
              margin-top: 40px;
              max-width: 308px;
              line-height: 1.5em;
            `}
          >
            A team of entrepreneurs, software developers, creatives, and
            investors with a simple mission. <br />
            <br />
            Make products that bring people together for common causes.
          </P>
        </div>
        <img
          src={dancingLlama}
          alt="Dancing Llama"
          className={css`
            margin-top: 116px;
            margin-bottom: -10px;
            height: 150px;
            width: 150px;
            position: relative;
          `}
        />
        <ColorBar h="40px" color={colors.purple} w="100%" />
      </div>
    </Layout>
  );
};
